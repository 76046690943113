import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface OTPVerificationState {
  verificationId: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: OTPVerificationState = {
  verificationId: null,
  loading: false,
  error: null,
};

const otpVerificationSlice = createSlice({
  name: "otpVerification",
  initialState,
  reducers: {
    setVerificationId: (
      state: OTPVerificationState,
      action: PayloadAction<string | null>
    ) => {
      state.verificationId = action.payload;
      state.error = null;
    },
    setOtpLoading: (
      state: OTPVerificationState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
    setError: (state: OTPVerificationState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    resetVerificationId: () => initialState,
  },
});

// Export the actions and reducer
export const {
  setVerificationId,
  setOtpLoading,
  setError,
  resetVerificationId,
} = otpVerificationSlice.actions;
export default otpVerificationSlice.reducer;
