import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "wowjs/css/libs/animate.css";
import GetInTouch from "../../components/HomeComponents/GetInTouch";
import HomeHeader from "../../components/HomeComponents/HomeHeader";
import "../Home/home.css";
import "../Home/home_responsive.css";
import "../Home/New_Home.css";
// import AIEnhance from "./secdtions/AIEnhance";
// import Banner from "./secdtions/Banner";
import FAQ from "./secdtions/FAQ";
// import TrustedPartner from "./secdtions/TrustedPartner";
import axios from "axios";
import { Helmet } from "react-helmet";
import HomeFooterNew from "../../components/HomeComponents/Footer";
import AIEnhance from "./NewSections/AIEnhance";
import Banner from "./NewSections/Banner";
import ClientTestimonials from "./NewSections/ClientTestimonials";
import Datadriven from "./NewSections/DataDriven";
import HaireRecruiters from "./NewSections/HaireRecruiters";
import HaireVideo from "./NewSections/HaireVideo";
import KeyFeatures from "./NewSections/KeyFeatures";
import RecruitersWork from "./NewSections/RecruitersWork";
import TrustedPartner from "./NewSections/TrustedPartner";

import 'animate.css/animate.min.css';
import { useDispatch } from "react-redux";
import { WOW } from 'wowjs';
import { loadSubscriptions } from "../../redux/actions/subscriptionActions";


const Home = () => {
  const dispatch = useDispatch();
  const [faqQuestion, setFaqQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [audioPath, setAudioPath] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFaqVisible, setIsFaqVisible] = useState(false);

  const url = `${process.env.REACT_APP_BACKEND_APP_URL}/ai/ask_question/`;

  const handleAIGeneration = async () => {
    if (faqQuestion.trim() === "") {
      setAnswer("Please give input.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(url, {
        question: faqQuestion,
      });
      setAnswer(response.data.message);
      setAudioPath(
        `${process.env.REACT_APP_BACKEND_APP_URL}${response.data.audio_path}`
      );
      setIsFaqVisible(true);
    } catch (error) {
      console.error("Error fetching the answer:", error);
      setAnswer("There was an error fetching the answer.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    new WOW().init();
    dispatch(loadSubscriptions());
  }, []);
  

  return (
    <>
      <Helmet>
        <title>
          AI Video Interview Platform | AI Based Recruitment Platform - Haire
        </title>
        <meta
          name="description"
          content="Haire:Unleash the power of AI with the best AI Recruitment Platform. Hire employees with less time & cost with the AI interview software. Know More"
        />
        <link rel="canonical" href="https://haire.ai/" />
      </Helmet>
      {/* Navigation Section */}
      <HomeHeader />


      {/* Banner Section */}
      {/* <Banner /> */}
      <Banner/>
      <HaireVideo/>
      {/* Trusted Partner Section */}
      {/* <TrustedPartner /> */}
      <TrustedPartner />
      {/* Ai enhance Section */}
      {/* <AIEnhance /> */}
      <AIEnhance/>
      {/* Hiring Process Section */}
      <Datadriven/>
      
      {/* <HiringProcess /> */}

      {/* Benefits Section */}
      {/* <Benefits /> */}

      {/* quick step section */}
      {/* <QuickSetup /> */}

      {/* Haire Key Features for Recruiters */}

      <HaireRecruiters />


      {/* How Haire Works for Recruiters  */}
      <RecruitersWork />

      {/* Haire Key Features for a Job Seeker */}
      <KeyFeatures />

      {/* How Haire Achieves Efficiency for Clients */}
      <ClientTestimonials />





      {/* Future Hiring */}
      {/* <FutureHiring /> */}

      {/* Pricing Section */}
      {/* <Pricing /> */}

      {/* Testimonials Section */}
      {/* <Testimonials /> */}

      

      {/* FAQ section */}
      <FAQ
        answer={answer}
        audioPath={audioPath}
        socketLoading={loading}
        handleAIGeneration={handleAIGeneration}
        setFaqQuestion={setFaqQuestion}
        isFaqVisible={isFaqVisible}
      />
      <GetInTouch />

      {/* Footer Section */}
      {/* <HomeFooter /> */}
      <HomeFooterNew/>
    </>
  );
};

export default Home;
