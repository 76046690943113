import React from "react";
import HomeFooter from "../../components/HomeComponents/HomeFooter";
import HomeHeader from "../../components/HomeComponents/HomeHeader";
import "../Features/Features.css";
import "../TermsCondition/TermsAndConditon.css";
import { Helmet } from "react-helmet";
import HomeFooterNew from "../../components/HomeComponents/Footer";

const TermsAndConditon = () => {
  return (
    <>
      <Helmet>
        <title>Terms & Conditions | AI Interview Platform | Haire</title>
        <meta
          name="description"
          content="Understand the terms and conditions of using Haire.ai's AI interview and recruitment platform."
        />
        <link rel="canonical" href="https://haire.ai/Term&Condition" />
      </Helmet>
      <HomeHeader />

      <section className="feature_sec_page_new">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="feature_sec_page_new_head">
                <h2 className="Term_head"> Haire.ai Terms and Conditions</h2>
                <p className="term_policy">
                  Welcome to Haire.ai By using our services, you agree to these
                  Terms and Conditions. Please read them carefully.
                </p>
                <ol className="sub-list">
                  <li>
                    <h5> Description of Services</h5>
                    <ol type="a" className="type_a">
                      <li>
                        Haire.ai, owned by Arcot Media Private Limited, provides
                        an advanced job marketplace and SaaS platform utilizing
                        AI technology to automate and enhance the hiring
                        process. Our services include AI-driven recruitment
                        processes featuring avatar-led interviews and dynamic
                        question generation, comprehensive candidate filtering
                        based on predefined criteria, and tools designed to
                        empower job seekers by improving their marketability and
                        connection to job opportunities. These services
                        streamline the recruitment process, making it more
                        efficient and effective for both employers and job
                        seekers.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h5>Acceptable Use</h5>
                    <ol type="a" className="type_a">
                      <li>
                        You agree to use our services only for lawful purposes
                        and in accordance with these Terms. The services
                        provided by Haire.ai shall not be used in any way that
                        could damage, disable, overburden, or impair Haire.ai's
                        servers or networks or interfere with any other party's
                        use of our services.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h5>User Accounts</h5>
                    <ol type="a" className="type_a">
                      <li>
                        To access certain features of our services, you may be
                        required to create an account. You are responsible for
                        maintaining the confidentiality of your account
                        credentials and for all activities that occur under your
                        account. You agree to notify us immediately of any
                        unauthorized use of your account or any other breach of
                        security.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h5>Privacy</h5>
                    <ol type="a" className="type_a">
                      <li>
                        Your privacy is important to us. Please review our
                        Privacy Policy to understand how we collect, use, and
                        disclose information about you when you use our
                        services.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h5>Intellectual Property</h5>
                    <ol type="a" className="type_a">
                      <li>
                        All content, software, and technology provided by
                        Haire.ai are the property of Haire.ai or its licensors
                        and are protected by intellectual property laws. You may
                        not use, copy, modify, distribute, or reproduce any
                        content or technology provided by Haire.ai without our
                        prior written consent.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h5>Limitation of Liability</h5>
                    <ol type="a" className="type_a">
                      <li>
                        In no event shall Haire.ai be liable for any indirect,
                        incidental, special, consequential, or punitive damages,
                        including but not limited to loss of profits, data, or
                        goodwill, arising out of or in connection with your use
                        of our services.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h5> Indemnification</h5>
                    <ol type="a" className="type_a">
                      <li>
                        You agree to indemnify, defend, and hold harmless
                        Haire.ai, its officers, directors, employees, agents,
                        and affiliates from and against any and all claims,
                        liabilities, damages, losses, costs, or expenses
                        (including reasonable attorneys' fees) arising out of or
                        in connection with your use of our services, your
                        violation of these Terms, or your interaction with the
                        Haire.ai chatbot. The assessment provided by the
                        Haire.ai chatbot is not 100% accurate, and you agree to
                        indemnify Haire.ai against all related claims and
                        damages.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h5>Modification of Terms</h5>
                    <ol type="a" className="type_a">
                      <li>
                        Haire.ai reserves the right to modify or update these
                        Terms at any time with or without prior notice. The
                        continued use of our services after any such
                        modifications or updates constitutes your acceptance of
                        the revised Terms. It is the sole responsibility of the
                        User to keep themselves updated with the Terms and
                        Conditions.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h5>Termination</h5>
                    <ol type="a" className="type_a">
                      <li>
                        Haire.ai shall have the sole right to terminate services
                        in cases of unauthorized use, violation of these Terms,
                        or inappropriate behavior. This includes, but is not
                        limited to, the use of explicit language on the
                        platform. Termination may be indefinite or for a
                        specified duration, depending on the severity and nature
                        of the violation. The user will be given a reasonable
                        opportunity to present their case. No refunds will be
                        provided for terminated services due to breaches of
                        these Terms.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h5>Governing Law</h5>
                    <ol type="a" className="type_a">
                      <li>
                        The laws of India, without regard to its conflict of law
                        principles, shall govern and construe these Terms. Any
                        disputes arising from or related to these Terms will be
                        resolved in the courts of Telangana, India.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h5> Dispute Resolution</h5>
                    <ol type="a" className="type_a">
                      <li>
                        In the event of a dispute, the parties will first
                        attempt to resolve it through negotiation. If
                        negotiation fails, disputes will be referred to
                        arbitration in Telangana or another mutually agreeable
                        location. Arbitration will consist of a sole arbitrator
                        mutually agreed upon by both parties. If an arbitrator
                        is not appointed within 14 days, one will be appointed
                        per the Arbitral Tribunal of the competent jurisdiction.
                        The arbitral award will be final and binding.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h5>Severability</h5>
                    <ol type="a" className="type_a">
                      <li>
                        If any provision of these Terms is found to be invalid
                        or unenforceable, such provision will be severed, and
                        the remaining provisions will remain in full force and
                        effect.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h5>Entire Agreement</h5>
                    <ol type="a" className="type_a">
                      <li>
                        These Terms constitute the entire agreement between you
                        and Haire.ai regarding your use of our services and
                        supersede all prior or contemporaneous agreements,
                        understandings, and communications.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h5>Children's Privacy</h5>
                    <ol type="a" className="type_a">
                      <li>
                        Our services are not intended for users under the age of
                        18. We do not permit individuals under 18 to use our
                        services. If we become aware that a user under 18 has
                        registered or provided personal information, we will
                        take steps to terminate their account and delete their
                        information.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h5>International Data Transfers</h5>
                    <ol type="a" className="type_a">
                      <li>
                        Your information may be transferred to and processed in
                        countries other than your own, where data protection
                        laws may be different from those in your jurisdiction.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h5>Haire.ai Chatbot Interactions</h5>
                    <ol type="a" className="type_a">
                      <li>
                        The assessment provided by the Haire.ai chatbot is not
                        100% accurate. Interactions between candidates and the
                        chatbot are monitored to enhance communication and
                        support and are not used for other purposes.
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <HomeFooter /> */}
      <HomeFooterNew/>
    </>
  );
};

export default TermsAndConditon;
