import { useState, useEffect, useCallback, useRef } from "react";

const useWebSocket = (url) => {
  const [socket, setSocket] = useState(null);
  const [socketLoading, setSocketLoading] = useState(false);
  const [combinedMessage, setCombinedMessage] = useState("");
  const reconnectAttempts = useRef(0);
  const maxReconnectAttempts = 5;

  useEffect(() => {
    let ws;
    const connectWebSocket = () => {
      ws = new WebSocket(url);
      ws.onopen = () => {
        console.log("WebSocket connected");
        reconnectAttempts.current = 0;
        setSocketLoading(false);
      };

      ws.onmessage = (event) => {
        setSocketLoading(true);
        try {
          const data = JSON.parse(event.data);
          if (data && data.message) {
            setCombinedMessage((prev) => {
              const newCombinedMessage = prev + data.message;
              if (newCombinedMessage.includes("<ENDOFTURN>")) {
                const cleanMessage = newCombinedMessage
                  .replace(/<STARTOFTURN>/g, "")
                  .replace(/<ENDOFTURN>/g, "");
                setTimeout(() => setSocketLoading(false), 0);
                return cleanMessage;
              }
              return newCombinedMessage;
            });
          }
        } catch (error) {
          console.error("Error parsing message data:", error);
          setSocketLoading(false);
        }
      };

      ws.onclose = () => {
        console.log("WebSocket disconnected");
        setSocketLoading(false);
        if (reconnectAttempts.current < maxReconnectAttempts) {
          setTimeout(() => {
            reconnectAttempts.current++;
            console.log(`Reconnect attempt #${reconnectAttempts.current}`);
            connectWebSocket();
          }, Math.min(10000, 1000 * 2 ** reconnectAttempts.current)); // Exponential backoff with a cap
        }
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
        ws.close();
      };

      setSocket(ws);
    };

    connectWebSocket();

    return () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  }, [url]);

  const sendData = useCallback(
    (data) => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        setCombinedMessage("");
        setSocketLoading(true);
        socket.send(JSON.stringify(data));
      } else {
        console.error("WebSocket is not connected.");
        setSocketLoading(false);
      }
    },
    [socket]
  );

  return { socket, sendData, socketLoading, setSocketLoading, combinedMessage };
};

export default useWebSocket;
