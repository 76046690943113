import { useState, useEffect } from "react";

const useAudioRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null); // Store audio Blob directly
  const [mediaRecorder, setMediaRecorder] = useState(null);

  const getMedia = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const recorder = new MediaRecorder(stream);

      let audioChunks = [];
      recorder.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };

      recorder.onstart = () => {
        setIsSpeaking(true); // User is speaking as soon as recording starts
      };

      recorder.onstop = () => {
        setIsSpeaking(false); // User stops speaking when recording stops
        const blob = new Blob(audioChunks);
        setAudioBlob(blob); // Save the audio Blob directly
        audioChunks = []; // Clear the chunks after creating the Blob
      };

      setMediaRecorder(recorder);
    } catch (error) {
      console.error("Error accessing the microphone", error);
    }
  };

  useEffect(() => {
    // Request microphone access and set up MediaRecorder
    getMedia();

    // Clean up the media stream
    return () => {
      if (mediaRecorder) {
        mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  const startRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "inactive") {
      try {
        mediaRecorder.start();
        setIsRecording(true);
        console.log("Recording started");
      } catch (error) {
        console.error("Failed to start recording. adding for idle screen button getting disabled.", error);
      }
    } else {
      // Request microphone access and set up MediaRecorder incase if media record is not working.
      getMedia();
      console.warn("MediaRecorder is not ready or already recording.");
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      try {
        mediaRecorder.stop();
        setIsRecording(false);
        console.log("Recording stopped");
      } catch (error) {
        console.error("Failed to stop recording. adding for idle screen button getting disabled.", error);
      }
    } else {
      console.warn("MediaRecorder is not recording.");
    }
  };

  return { startRecording, stopRecording, isRecording, isSpeaking, audioBlob, mediaRecorder };
};

export default useAudioRecorder;
