import { Modal as AntModal, Modal as PopUp } from "antd";
import { marked } from "marked";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Oval, ThreeCircles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { baseURL, websocketUrl } from "../../../../api/instance";
import { sum_img } from "../../../../assets/images/images";
import DashboardHeader from "../../../../components/CommonComponents/DashboardHeader";
import { Navbar } from "../../../../components/DashboardComponents/Navbar/Navbar";
import useEnhanceResumeSocket from "../../../../hooks/useEnhanceResumeSocket";
import { fetchCurrencies } from "../../../../redux/actions/jobActions";
import { updateJobSeeker } from "../../../../redux/actions/jobSeekerActions";
import "../ConfirmInformation/ConfirmInformation.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TurndownService from 'turndown';

const UpdateApplicantResume = () => {
  const turndownService = new TurndownService();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const resumeJson = useSelector((state) => state?.auth?.temp_resume?.resume_json);
  const resume = useSelector((state) => state?.auth?.temp_resume?.resume);
  const [enhancedData, setEnhancedData] = useState(resumeJson ?? {});
  const [isEnhanced, setIsEnhanced] = useState(false);
  const editableRef = useRef(null);
  const [currentSection, setCurrentSection] = useState("");
  const userId = useSelector((state) => state.auth?.user?.id);
  const userDetail = useSelector((state) => state.auth?.user);
  const url = `${websocketUrl}/jobseeker_profile_enhance/${userId}/`;
  const { messages, sendMessage, clearMessages, socketLoading } =
    useEnhanceResumeSocket(url);
  const [show, setShow] = useState(false);
  const [currentKey, setCurrentKey] = useState(null);
  const [imageUrl, setImageUrl] = useState();

  const [editedContent, setEditedContent] = useState("");
  const [textareaError, setTextareaError] = useState("");

  const updatedPayload = {
    ...enhancedData,
    candidate_location: userDetail?.address ?? resumeJson?.candidate_location,
    candidate_name: userDetail?.full_name ?? resumeJson.candidate_name,
    contact_details:
      userDetail?.country_code && userDetail?.phone
        ? userDetail?.phone
        : resumeJson?.contact_details,
    mail_ld: userDetail.email ?? resumeJson?.mail_ld,
  };

  const handleSaveAndProceed = () => {
    dispatch(updateJobSeeker(token, userId, resume, updatedPayload, imageUrl));
    navigate("/applicantprofile");
  };

  const handleEnhanceClick = (key, data) => {
    setCurrentKey(key);
    sendMessage(data);
    setIsEnhanced(true);
    setCurrentSection("");

    setTextareaError("");

    if (editableRef.current) {
      const range = document.createRange();
      const sel = window.getSelection();
      setTimeout(() => {
        editableRef.current.focus();
        range.selectNodeContents(editableRef.current);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
      }, 0);
    }
  };

  const handleShow = (key, content) => {
    setCurrentKey(key);
    setCurrentSection(content);
    const htmlContent = marked(content);
    setEditedContent(htmlContent);
    setShow(true);
    setTextareaError("");
  };

  const handleClose = () => {
    setCurrentSection("");
    setShow(false);
    setIsEnhanced(false);
  };

  const handleSave = () => {
    if (!editedContent.trim()) {
      setTextareaError("This field cannot be empty.");
      return;
    }

    const markdownContent = turndownService.turndown(editedContent);
    if (currentKey && markdownContent !== currentSection) {
      const updatedSections = enhancedData.sections.map((section) => {
        if (section.heading === currentKey) {
          return { ...section, content: markdownContent };
        }
        return section;
      });

      setEnhancedData({ ...enhancedData, sections: updatedSections });
      setCurrentSection(markdownContent);
      setTextareaError(""); // Clear any previous error messages
    } else {
    }

    setShow(false);
  };

  const handleEditableBlur = () => {
    if (editableRef.current) {
      const content = editableRef.current.innerText;
      if (content !== editedContent) {
        setEditedContent(content);
      }
    }
  };

  useEffect(() => {
    if (show && editableRef.current) {
      editableRef.current.innerHTML = currentSection;
    }
  }, [show, currentSection]);

  useEffect(() => {
    if (currentSection) {
      const htmlContent = marked(currentSection); // Convert markdown to HTML
      setEditedContent(htmlContent);
    }
  }, [currentSection]);

  useEffect(() => {
    if (messages.length > 0 && messages.includes("<ENDOFTURN>")) {
      const enhancedMarkdown = messages.join("").replace(/<ENDOFTURN>|<STARTOFTURN>/g, "");
      const enhancedHtml = marked(enhancedMarkdown); // Convert markdown to HTML
      setEditedContent(enhancedHtml); // Update React Quill content
      setEnhancedData((prevData) => {
        const updatedSections = prevData.sections.map((section) => {
          if (section.heading === currentKey) {
            return { ...section, content: enhancedHtml }; // Update the content of the matched section
          }
          return section; // Return other sections as they are
        });
        return { ...prevData, sections: updatedSections }; // Update the sections array in the resume data
      });
      clearMessages(); // Reset message queue
    }
  }, [messages]);

  const handleBack = () => {
    navigate("/applicantprofile", { replace: true });
  };

  useEffect(() => {
    if (token) {
      dispatch(fetchCurrencies(token));
    }
  }, []);

  return (
    <>
      <Navbar />
      <section className="confim_info_sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="confim_back_btn"></div>
              <div className="confim_info_sec_inner">
                <DashboardHeader title="" backButtonLink={handleBack} />
                <div className="confim_info_sec_inner_head">
                  <h6>Confirm your information</h6>
                </div>
                <div className="confim_info_sec_inner_body">
                  <div className="userprofiledata">
                    <div>
                      <h2>
                        {userDetail?.full_name ?? resumeJson.candidate_name}
                      </h2>
                      <h3>
                        {userDetail?.address ?? resumeJson?.candidate_location}
                      </h3>
                      <h3>{userDetail.email ?? resumeJson?.mail_ld}</h3>
                      <h3>
                        {" "}
                        {userDetail?.country_code && userDetail?.phone
                          ? userDetail?.phone
                          : resumeJson?.contact_details}{" "}
                      </h3>
                    </div>
                  </div>

                  {enhancedData?.sections?.map((info, index) => {
                    const sectionTitle = info?.heading;
                    const sectionContent = info?.content ?? "";
                    return (
                      <div className="confim_info_single" key={index}>
                        <>
                          <div className="confim_info_single_head">
                            <h5>
                              {sectionTitle.charAt(0).toUpperCase() +
                                sectionTitle.slice(1)}
                            </h5>
                            <button
                              type="button"
                              onClick={() => {
                                handleShow(sectionTitle, sectionContent);
                              }}
                            >
                              <img src={sum_img} alt="" />
                            </button>
                          </div>
                          <div className="confim_info_single_body">
                            {sectionTitle !== "projects" ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: marked(sectionContent),
                                }}
                              ></div>
                            ) : (
                              <ul>
                                {sectionContent.map((project, projectIndex) => (
                                  <li key={projectIndex}>{project.name}</li>
                                ))}
                              </ul>
                            )}
                          </div>{" "}
                        </>
                      </div>
                    );
                  })}
                  <div className="login_btn_blue_new">
                    <button
                      type="button"
                      onClick={handleSaveAndProceed}
                      // disabled={!imageUrl && !profilePic}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose} className="summary_sec_new">
        <button onClick={handleClose} className="close_icn_new">
          <i className="fas fa-times"></i>
        </button>
        <div className="summary_sec_new_inner">
          {enhancedData?.sections?.map((section, index) => {
            const sectionTitle = section?.heading;
            const sectionContent = section?.content;
            const order_id = section?.order_id;

            if (sectionTitle === currentKey) {
              const customSection = {
                heading: sectionTitle,
                content:
                  currentSection === "" || currentSection === null
                    ? sectionContent
                    : currentSection,
                order_id: order_id,
              };
              return (
                <div className="summary_sec_new_inner_cont" key={index}>
                  <ReactQuill
                    theme="snow"
                    value={editedContent}
                    onChange={setEditedContent}
                    modules={UpdateApplicantResume.modules}
                    formats={UpdateApplicantResume.formats}
                    bounds={".app"}
                    placeholder="Enter text here..."
                  />
                  {/* <div
                    ref={editableRef}
                    contentEditable
                    dangerouslySetInnerHTML={{
                      __html: marked(
                        currentSection === "" || currentSection === null
                          ? sectionContent
                          : currentSection
                      ),
                    }}
                    onBlur={handleEditableBlur}
                    style={{
                      border: "1px solid #ccc",
                      cursor: "text",
                      padding: "12px",
                      borderRadius: "10px",
                    }}
                  ></div> */}
                  {textareaError && (
                    <div style={{ color: "#d30f0f", paddingTop: "10px" }}>
                      {textareaError}
                    </div>
                  )}
                  {socketLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <ThreeCircles
                        visible={true}
                        height="20"
                        width="20"
                        color="#1865c1"
                        ariaLabel="loading-indicator"
                      />
                    </div>
                  ) : (
                    <div className="save_rege_sec_new" id="save_reg">
                      <button className="save_btn_new" onClick={handleSave}>
                        Save
                      </button>
                      <button
                        className="sync_btn_new"
                        onClick={() =>
                          handleEnhanceClick(sectionTitle, customSection)
                        }
                      >
                        <i className="fas fa-sync-alt"></i>Regenerate
                      </button>
                    </div>
                  )}
                </div>
              );
            }
            return null;
          })}
        </div>
      </Modal>
    </>
  );
};

export default UpdateApplicantResume;

export const CustomPopup = ({
  isModalVisible,
  handleCancel,
  handleConfirm,
  question,
}) => {
  return (
    <PopUp
      centered
      open={isModalVisible}
      closable={false}
      footer={[
        <div className="inter_panel_ready_sec_inner_btns">
          <button type="button" className="no_btn_int" onClick={handleCancel}>
            No
          </button>
          <button type="button" className="yes_btn_int" onClick={handleConfirm}>
            Yes
          </button>
        </div>,
      ]}
      width={800}
    >
      <div className="inter_panel_ready_sec_inner">
        {/* <img src={close_job} alt="" /> */}
        <h6>{question}</h6>
      </div>
    </PopUp>
  );
};

UpdateApplicantResume.modules = {
  toolbar: [
    ["bold", "italic"],
    [{ list: "bullet" }],
  ],
};

// Quill formats to allow
UpdateApplicantResume.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];