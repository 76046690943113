import {
  BillingState,
  setBillingError,
  setBillingInfo,
  setBillingLoading,
} from "../reducers/billingReducer";
// @ts-ignore
import instance from "../../api/instance";
import { Dispatch } from "redux";

export const updateBillingInfo =
  (token: string, billingData: BillingState) => async (dispatch: Dispatch) => {
    dispatch(setBillingLoading(true));
    try {
      const response = await instance.post(
        "/subscription/add-billing-address/",
        billingData,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(setBillingInfo(response?.data));
      }
    } catch (error: any) {
      dispatch(
        setBillingError("Failed to fetch job details: " + error.message)
      );
      dispatch(setBillingLoading(false));
    } finally {
      dispatch(setBillingLoading(false));
    }
  };

export const fetchBillingInfo =
  (token: string) => async (dispatch: Dispatch) => {
    dispatch(setBillingLoading(true));
    try {
      const response = await instance.get("/subscription/get-billing-info/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      if (response.status === 200) {
        dispatch(setBillingInfo(response?.data));
      }
    } catch (error: any) {
      dispatch(
        setBillingError("Failed to fetch job details: " + error.message)
      );
      dispatch(setBillingLoading(false));
    } finally {
      dispatch(setBillingLoading(false));
    }
  };
