import { Table } from "antd";
import {
  Search_icon,
  download_icon,
  download_img,
} from "../../../../../assets/images/images";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { loadPaymentHistory } from "../../../../../redux/actions/paymentsActions";
import { formatDate } from "../../../../../utils/utils";
import { width } from "@mui/system";
import { ThreeCircles } from "react-loader-spinner";
import { generatePdf } from "../../../../../utils/pdfFiles/transcriptPdf";
import { generateInvoicePdf } from "../../../../../utils/pdfFiles/invoicePdf";
import { setJobPageFilters } from "../../../../../redux/reducers/jobReducer";
import { toast } from "react-toastify";

const PaymentHistory = () => {
  const dispatch = useDispatch();
  const initialRender = useRef(true);
  const initialRenderCount = useRef(0);
  const token = useSelector((state) => state?.auth?.token);
  const jobsCount = useSelector((state) => state?.jobs?.jobsCount);
  const { history, loading, error } = useSelector((state) => state.payments);
  const jobPageFilters = useSelector((state) => state.job.jobPageFilters);
  const user =useSelector((state)=>state.auth.user);
  const company =useSelector((state)=>state.company.details)

  const [sortCriteria, setSortCriteria] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingPdf, setLoadingPdf] = useState(false);

  useEffect(() => {
    //on load check currentPage
    if (initialRenderCount.current < 2) {
      initialRenderCount.current = initialRenderCount.current + 1;
      setCurrentPage(jobPageFilters.current || 1);
      setSearchInput(jobPageFilters.searchInput);
      setSortCriteria(jobPageFilters.sortBy);
      fetchPayments(
        jobPageFilters.current,
        jobPageFilters.searchInput,
        jobPageFilters.sortBy
      );
    } else {
      setCurrentPage(jobPageFilters.current || 1);
      setSearchInput(jobPageFilters.searchInput);
      setSortCriteria(jobPageFilters.sortBy);
    }
  }, [jobPageFilters]);

  const handleDownloadPdf = async (record) => {
    if (record) {
      setLoadingPdf(true); // Set loading state to true before generating PDF
      toast.success("Downloading Invoice!", { autoClose: 1000 }); 
      await generateInvoicePdf(record,user,company); // Wait for the PDF generation to complete
      setLoadingPdf(false); // Set loading state to false after generating PDF
    } else {
      console.error("Selected transcript or applicant is undefined");
    }
  };

  const columns = [
    {
      title: "Invoice Id",
      dataIndex: "key",
      key: "key",
      render: (text) => <div data-th="Invoice Id">{text}</div>,
    },
    {
      title: "Purchased Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div data-th="Purchased Date">{text}</div>,
    },
    {
      title: "Item",
      dataIndex: "type",
      key: "type",
      render: (text) => <div data-th="Item">{text}</div>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => <div data-th="Price">{text}</div>,
    },
    {
      title: "Expiration Date",
      dataIndex: "expiry_date",
      key: "expiry_date",
      render: (text) => <div data-th="Expiration Date">{text}</div>,
    },
    {
      title: "Invoice",
      key: "invoice",
      render: (_, record) => (
        <div data-th="Invoice">
        <button
          className="transcript_download_button"
          onClick={() => handleDownloadPdf(record)}
        >
          {record.loadingPdf ? ( 
            <ThreeCircles color="#1865c1" height={20} width={20} />
          ) : (
            <img src={download_icon} alt="Download" />
          )}
        </button>
      </div>
      ),
    },
  ];

  // Map the API response to the format expected by Ant Design's Table
  const tableData = history.map((item) => ({
    key: item.id,
    created_at: formatDate(item?.created_at),
    type: item.type,
    price: `${item?.currency ?? "$"}${item?.price}`,
    expiry_date: item?.expiry_date ? formatDate(item?.expiry_date) : "N/A",
    invoice: item?.invoice,
  }));

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchPayments(page);
    dispatch(
      setJobPageFilters({
        current: page,
        sortBy: sortCriteria,
        searchInput,
      })
    );
  };

  const handleSearchSubmit = (searchValue) => {
    setSearchInput(searchValue);

    setCurrentPage((prevPage) => {
      if (prevPage !== 1) {
        return 1;
      }

      fetchPayments(1);
      return prevPage;
    });
  };

  const handleSortChange = (criteria) => {
    setSortCriteria(criteria);
    setCurrentPage(1); // Reset page to 1 whenever the sort criteria changes
    fetchPayments(1, searchInput, criteria); // Immediately fetch with new criteria
    dispatch(
      setJobPageFilters({
        current: 1,
        sortBy: criteria,
        searchInput,
      })
    );
  };

  const handleReset = () => {
    setSearchInput("");
    setSortCriteria("All");
    setCurrentPage(1);
    fetchPayments(1, "", "All");
  };

  const fetchPayments = (
    page,
    searchValue = searchInput,
    sortValue = sortCriteria
  ) => {
    dispatch(loadPaymentHistory(token, sortValue, searchValue, page));
  };

  useEffect(() => {
    if (initialRender.current) {
      // Skip the initial render
      initialRender.current = false;
    } else {
      // This will handle changes to searchInput only after the initial render
      setCurrentPage(1);
      fetchPayments(1, searchInput, sortCriteria);
      dispatch(
        setJobPageFilters({
          current: 1,
          sortBy: sortCriteria,
          searchInput,
        })
      );
    }
  }, [searchInput]);

  return (
    <>
      <div>
        <div className="row">
          <div className="col-lg-7 col-md-8 col-sm-3">
            <div className="Search_jobs credit-search">
              <Searchbar
                inputValue={searchInput}
                setInputValue={setSearchInput}
                onSearchSubmit={handleSearchSubmit}
              />
            </div>
            
          </div>
          <div class="col-4 dropdown-inline-container cutoff-res">
            <SortFilter
              onSortChange={handleSortChange}
              selectedValue={sortCriteria}
              className="bg-white"
            />
          </div>
          <div class="col-1 dropdown-inline-container cutoff-res">
            <Reset onReset={handleReset} />
          </div>
          <div className="responsive_filter mt-3">
            <div className="row  d-flex g-3 mb-1 align-items-baseline">
              <div className="col-12 ">
                <div className="row">
                  <div className="col-6 d-flex flex-column align-items-baseline">
                    <SortFilter
                            onSortChange={handleSortChange}
                            selectedValue={sortCriteria}
                            className="bg-white"
                          />
                  </div>
                  <div className="col-6 d-flex flex-column  justify-content-end">
                  <Reset onReset={handleReset} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={tableData}
          pagination={{
            pageSize: 12,
            total: jobsCount,
            current: currentPage,
            onChange: handlePageChange,
            showSizeChanger: false,
          }}
          loading={loading}
        />
      </div>
    </>
  );
};

export default PaymentHistory;

export const SortFilter = ({ onSortChange, selectedValue }) => {
  return (
    <>
      <div className="me-3 ">Sort by:</div>
      <div className="">
        <select
          className="Sortby"
          aria-label="Default select example"
          onChange={(e) => onSortChange(e.target.value)}
          value={selectedValue}
          style={{ width: "100%" }}
        >
          <option value="">All</option>
          <option value="-created_at">Newest Purchase</option>
          <option value="created_at">Oldest Purchase</option>
        </select>
      </div>
    </>
  );
};

export const Reset = ({ onReset }) => {
  return (
    <div className="reset_container">
      <button className="reset_btn" onClick={onReset}>
        Reset
      </button>
    </div>
  );
};

export const Searchbar = ({ onSearchSubmit, inputValue, setInputValue }) => {
  const inputRef = useRef(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    onSearchSubmit(inputValue);
    if (inputRef.current) {
      inputRef.current.focus(); // Maintain focus after submit
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Focus when the component mounts or updates
    }
  }, []);

  return (
    <div className="Search_job me-3">
      <img
        src={Search_icon}
        alt="Search_icon"
        className="Search_icon"
        onClick={handleSubmit}
        style={{ cursor: "pointer" }}
      />
      <input
        ref={inputRef}
        type="text"
        placeholder="Search Job title "
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={handleKeyPress}
        style={{ width: "100%" }}
      />
       {/* <div className="responsive_filter mt-3">
            <div className="row  d-flex g-3 mb-1 align-items-baseline">
              <div className="col-12 ">
                <div className="row">
                  <div className="col-6 d-flex flex-column align-items-baseline">
                    <SortFilter
                            onSortChange={handleSortChange}
                            selectedValue={sortCriteria}
                            className="bg-white"
                          />
                  </div>
                  <div className="col-6 d-flex flex-column  justify-content-end">
                  <Reset onReset={handleReset} />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
    </div>
  );
};
