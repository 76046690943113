import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompanyDetails } from "../types/companyTypes";

export interface CompanyState {
  details: CompanyDetails | null;
  loading: boolean;
  error: string | null;
}

const initialState: CompanyState = {
  details: null,
  loading: false,
  error: null,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompanyDetails: (
      state: CompanyState,
      action: PayloadAction<CompanyDetails>
    ) => {
      state.details = action.payload as CompanyDetails;
    },
    updateCompanyDetails: (
      state: CompanyState,
      action: PayloadAction<Partial<CompanyDetails>>
    ) => {
      if (state.details === null) {
        state.details = action.payload as CompanyDetails;
      } else {
        state.details = { ...state.details, ...action.payload };
      }
    },
    updateCompanyGallery: (
      state: CompanyState,
      action: PayloadAction<string[]> // Payload is an array of image URLs
    ) => {
      if (state.details) {
        state.details.file = action.payload; // Update the gallery field
      }
    },
    setCompanyLoading: (
      state: CompanyState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
    setCompanyError: (state: CompanyState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    resetCompany: (state: CompanyState) => {
      state.details = initialState.details;
    },
  },
});

export const {
  setCompanyDetails,
  updateCompanyDetails,
  setCompanyLoading,
  updateCompanyGallery,
  setCompanyError,
  resetCompany,
} = companySlice.actions;
export default companySlice.reducer;
