import { AnyAction, Dispatch } from "redux";
import {
  PhoneAuthProvider,
  RecaptchaVerifier,
  auth,
  signInWithPhoneNumber,
  updatePhoneNumber,
} from "../../config/firebaseConfig";
import {
  setAuthToken,
  setJobseekerId,
  setNewUser,
  setUserId,
  setUserName,
  setUserRole,
  updateUserPhoneNumber,
} from "../reducers/authReducer";
import {
  setError,
  setOtpLoading,
  setVerificationId,
} from "../reducers/otpVerificationsReducer";
// @ts-ignore
import { ThunkDispatch } from "@reduxjs/toolkit";
import { UserCredential } from "firebase/auth";
import Cookies from "js-cookie";
// @ts-ignore
import instance from "../../api/instance";
import getFirebaseErrorMessage from "../../utils/firebaseErrorFunction";
import { RootState } from "../reducers/rootReducer";
import { updateUser } from "../thunks/userThunks";
import { AuthUser } from "../types/authTypes";
// @ts-ignore
import { formatPhoneNumber } from "../../utils/utils";

// Action to send OTP
// Action to send OTP
export const sendOTP = (phone: string) => async (dispatch: Dispatch) => {
  dispatch(setOtpLoading(true));
  let recaptchaVerifier;
  try {
    // Clear the existing container if it exists
    const existingContainer = document.getElementById("recaptcha-container");
    if (existingContainer) {
      existingContainer.innerHTML = "";
    }

    // Create a new instance of the reCAPTCHA verifier
    recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
    });

    const confirmationResult = await signInWithPhoneNumber(
      auth,
      phone,
      recaptchaVerifier
    );

    dispatch(setVerificationId(confirmationResult.verificationId));
    dispatch(setOtpLoading(false));
  } catch (error: any) {
    // Handle the error more gracefully and allow for retry
    const errorMessage = getFirebaseErrorMessage(error.code);
    dispatch(setError(errorMessage));
  } finally {
    dispatch(setOtpLoading(false));
    // Reset the reCAPTCHA verifier after each attempt, successful or not
    if (recaptchaVerifier) {
      recaptchaVerifier.clear();
    }
  }
};

// Action to verify OTP
export const verifyOTP =
  (
    verificationId: string,
    otpCode: string,
    countryCode: number,
    phone: string,
    name: string
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setOtpLoading(true));
    try {
      const token = getState().auth.token;
      if (!token) {
        throw new Error("Authorization token is missing");
      }
      const credential = PhoneAuthProvider.credential(verificationId, otpCode);
      const user = auth.currentUser;

      // const result = await signInWithCredential(auth, credential);
      if (user) {
        await updatePhoneNumber(user, credential);
        // Proceed with your post-verification logic here
        const token = getState().auth.token;
        if (!token) {
          throw new Error("Authorization token is missing");
        }
        await instance.post(
          "/accounts/mobile-verify/",
          {
            verification_id: verificationId,
            country_code: countryCode,
            phone: phone,
            full_name: name,
          },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );

        dispatch(updateUserPhoneNumber({ countryCode, phone }));

        dispatch(setOtpLoading(false));
      } else {
        throw new Error("OTP verification failed");
      }
    } catch (error: any) {
      dispatch(
        setError(error.message || "An error occurred during OTP verification")
      );
      dispatch(setOtpLoading(false));
      return Promise.reject(error);
    } finally {
      dispatch(setOtpLoading(false));
    }
  };

//Login with otp
export const sendLoginOTP = (phone: string) => async (dispatch: Dispatch) => {
  dispatch(setOtpLoading(true));

  try {
    // Fetch to check if the user exists
    const existsResponse = await fetch(
      `${
        process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS
      }/checkUserExists?phone=${formatPhoneNumber(phone)}`
    );

    if (!existsResponse.ok) {
      throw new Error(`Server responded with status: ${existsResponse.status}`);
    }

    const responseText = await existsResponse.text(); // Retrieve raw response as text

    // Try to parse as JSON
    let existsData;
    try {
      existsData = JSON.parse(responseText);
    } catch (error) {
      console.error("Failed to parse JSON:", responseText);
      throw new Error("Unexpected response format: not valid JSON");
    }

    if (!existsData.exists) {
      throw new Error("No account found for this phone number.");
    }

    // Proceed with OTP flow
    const recaptchaContainerId = `recaptcha-container-${Date.now()}`;
    const recaptchaContainer = document.createElement("div");
    recaptchaContainer.id = recaptchaContainerId;
    document.body.appendChild(recaptchaContainer);

    const recaptchaVerifier = new RecaptchaVerifier(
      auth,
      recaptchaContainerId,
      { size: "invisible" }
    );

    let confirmationResult;
    try {
      confirmationResult = await signInWithPhoneNumber(
        auth,
        phone,
        recaptchaVerifier
      );
    } catch (error) {
      console.error("Error during Firebase signInWithPhoneNumber:", error);
      throw error; // Rethrow to handle it in the catch block below
    } finally {
      // Ensure cleanup happens in all cases
      if (recaptchaContainer.parentNode) {
        recaptchaContainer.parentNode.removeChild(recaptchaContainer);
      }
    }

    dispatch(setOtpLoading(false));
    return confirmationResult;
  } catch (error: any) {
    // Customize error messages or actions based on Firebase error codes
    const errorMessage = error.code
      ? getFirebaseErrorMessage(error.code)
      : error.message;
    dispatch(setError(errorMessage));
    dispatch(setOtpLoading(false));
    throw new Error(errorMessage);
  }
};

//verify login otp
export const verifyLoginOTP =
  (confirmedUser: UserCredential, navigate: Function, redirectUrl?: string) =>
  async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    dispatch(setOtpLoading(true));

    try {
      const userKey = confirmedUser.user.uid;
      if (userKey) {
        // Simplified condition
        const existsResponse = await instance.get(
          `/accounts/check-user-exists/${userKey}/`
        );

        if (existsResponse.status === 200 && existsResponse.data) {
          const requestBody = {
            user_id: userKey,
            // full_name: confirmedUser.user.displayName,
            email: confirmedUser.user.email,
            // type: 1,
            // phone: confirmedUser.user.phoneNumber,
            key: userKey,
            // role: existsResponse.data.role,
          };

          const loginResponse = await instance.post(
            "/accounts/social-login/",
            requestBody
          );
          if (loginResponse.status === 200) {
            Cookies.set("token", loginResponse.data.data.token);
            dispatch(setAuthToken(loginResponse.data.data.token));
            dispatch(setUserId(loginResponse.data.data.user.id));
            dispatch(setNewUser(loginResponse.data.data.is_new_user));
            dispatch(setJobseekerId(loginResponse.data.data.jobseeker_id));

            const user: AuthUser = {
              user_id: loginResponse.data.data.user.id,
              job_seeker_id: null,
              full_name: loginResponse.data.data.user.full_name,
              email: loginResponse.data.data.user.email,
              type: loginResponse.data.data.user.type,
              country_code: null,
              phone: loginResponse.data.data.user.phone,
              key: loginResponse.data.data.user.key,
              role: loginResponse.data.data.user.role,
              registered_company_name:
                loginResponse.data.data.user.registered_company_name,
              registered_industry_name: loginResponse.data.data.user.registered_industry_name,
              company_id: loginResponse.data.data.company_id,
              languages: null,
              websites: null,
              profile_pic: null,
              designation: null,
              dob: null,
              gender: null,
              address: null,
              introduction: null,
              resume: loginResponse.data.data.user.resume,
              resume_json: null,
              is_active: null,
              is_deleted: null,
              profile_completed: loginResponse.data.data.profile_completed,
            };

            await dispatch(updateUser(user));

            // Navigate based on role
            if (user.role === 1) {
              navigate("/dashboard");
            } else if (user.role === 2) {
              navigate(redirectUrl || "/applicant-dashboard");
            } else {
              navigate("/user-role");
            }
          }
        } else {
          navigate("/sign-up");
        }
      }
    } catch (error: any) {
      dispatch(
        setError(error.message || "An error occurred during Google sign-in")
      );
    } finally {
      dispatch(setOtpLoading(false));
    }
  };

//action to update username
export const updateUserName = (full_name: string) => (dispatch: Dispatch) => {
  dispatch(setOtpLoading(true));
  try {
    dispatch(setUserName(full_name));
  } catch (error: any) {
    dispatch(
      setError(error.message || "An error occurred while updating name")
    );
  } finally {
    dispatch(setOtpLoading(false));
  }
};

//action to update the user role
export const updateUserRole =
  (role: number) => async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setOtpLoading(true));
    try {
      const token = getState().auth.token;
      if (!token) {
        throw new Error("Authorization token is missing");
      }

      const response = await instance.post(
        "/accounts/update-role/",
        { role },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      const updatedUserData = response.data.user;

      if (
        response &&
        updatedUserData &&
        typeof updatedUserData.role === "number"
      ) {
        dispatch(setUserRole(updatedUserData.role));
      } else {
        throw new Error("Invalid response structure for updating role");
      }
    } catch (error: any) {
      dispatch(
        setError(error.message || "An error occurred while updating role")
      );
    } finally {
      dispatch(setOtpLoading(false));
    }
  };
