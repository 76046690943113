import React, { useState } from "react";
import "../Checkout/Checkout.css";
import {
  crd_card_new,
  upi_card_new,
  p_1,
  p_2,
  p_3,
} from "../../assets/images/images";
const Checkout = () => {
  const [methodChange, setmethodChange] = useState("card");
  const handlePaymentChange = (event) => {
    setmethodChange(event.target.value);
  };

  return (
    <>
      <section className="checkout_page_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="checkout_page_main_inner">
                <div className="checkout_page_main_inner_left">
                  <div className="checkout_page_main_inner_left_cont_sec">
                    <h6>Business</h6>
                    <p>Ideal for Small Businesses and Startups</p>
                    <h5>
                      $ 99<span>Monthly Subscription</span>
                    </h5>
                    <button type="button">Buy Now</button>
                    <ul>
                      <li>Advanced AI Job Matching</li>
                      <li>Enhanced Resume Creation Tool</li>
                      <li>Priority AI Interviews</li>
                      <li>Increased Monthly Application Quota</li>
                    </ul>
                  </div>
                </div>
                <div className="checkout_page_main_inner_right">
                  <h6>Download Resume</h6>
                  <h5>Payment Methods</h5>
                  <ul className="card_upi_radio">
                    <li>
                      <div className="card_upi_radio_single">
                        <label className="container">
                          <img src={crd_card_new} alt="" /> Card
                          <input
                            type="radio"
                            name="payment_method"
                            value="card"
                            checked={methodChange === "card"}
                            onChange={handlePaymentChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="card_upi_radio_single">
                        <label className="container">
                          <img src={upi_card_new} alt="" /> UPI
                          <input
                            type="radio"
                            name="payment_method"
                            value="upi"
                            checked={methodChange === "upi"}
                            onChange={handlePaymentChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </li>
                  </ul>
                  <div className="pay_det_sec">
                    <h4>Payment Details</h4>
                    <div
                      className={
                        methodChange === "card"
                          ? "checkout_page_main_inner_right_form_sec"
                          : "checkout_page_main_inner_right_form_sec_hidden"
                      }
                    >
                      <div className="checkout_page_main_inner_right_form_sec_single">
                        <input type="text" placeholder="Name On The Card" />
                      </div>
                      <div className="checkout_page_main_inner_right_form_sec_single">
                        <input type="text" placeholder="Card Number" />
                      </div>
                      <div className="check_grid_sec_new">
                        <div className="checkout_page_main_inner_right_form_sec_single">
                          <input type="text" placeholder="Expiration" />
                        </div>
                        <div className="checkout_page_main_inner_right_form_sec_single">
                          <input type="text" placeholder="CVV" />
                        </div>
                      </div>
                      <span>
                        By Clicking “Proceed Payment” I agree to the companies
                        term of services{" "}
                      </span>
                      <div className="proc_btn_new_chek_out">
                        <h3>$ 99</h3>
                        <button>Proceed</button>
                      </div>
                    </div>
                    <div
                      className={
                        methodChange === "upi"
                          ? "checkout_page_main_inner_right_form_sec"
                          : "checkout_page_main_inner_right_form_sec_hidden"
                      }
                    >
                      <div className="paytym_sec_new">
                        <div className="paytym_sec_new_single">
                          <img src={p_1} alt="" />
                        </div>
                        <div className="paytym_sec_new_single">
                          <img src={p_2} alt="" />
                        </div>
                        <div className="paytym_sec_new_single">
                          <img src={p_3} alt="" />
                        </div>
                      </div>
                      <div className="checkout_page_main_inner_right_form_sec_single">
                        <input type="text" placeholder="Enter UPI ID" />
                      </div>
                      <span>
                        By Clicking “Proceed Payment” I agree to the companies
                        term of services{" "}
                      </span>
                      <div className="proc_btn_new_chek_out">
                        <h3>$ 99</h3>
                        <button>Proceed</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Checkout;
