import React from "react";

import "react-toastify/dist/ReactToastify.css";
import "../Login/Login.css";

import { frgt_img, haire_gif_new, new_bg } from "../../assets/images/images";

import { ThreeCircles } from "react-loader-spinner";
import passwordStore from "../../zustand/passwordStore";
import { useNavigate } from "react-router-dom";

const FortgetPassword = () => {
  const navigate = useNavigate();
  const {
    forgetPasswordFormData,
    forgetPasswordFormErrors,
    setForgetPasswordFormData,
    validateForgetPasswordField,
    validateForgetPasswordForm,
    sendForgotPasswordEmail,
    loading,
  } = passwordStore();

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setForgetPasswordFormData(name, value); // Update the state in the store\
    validateForgetPasswordField(name, value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = await validateForgetPasswordForm();
    if (isValid) {
      await sendForgotPasswordEmail(forgetPasswordFormData.email);
      setForgetPasswordFormData("email", "");
    }
  };

  return (
    <>
      <section className="login_sec_new" style={{ height: "100vh" }}>
      <div className="singin_sec_new_left">
          <div className="post-banner">
            <h2>Welcome to Haire </h2>
            <p>Experience Smart Recruitment in AI-Driven Recruitment Excellence.</p>
          </div>
          <div className="singin_sec_new_text">
              <div className="a">
                <h4 className="signin_qoutes">
                  Step into the future of hiring with our AI tool that not only
                  matches candidates to ideal positions but also initiates smart
                  interviews.
                </h4>
              </div>
          </div>
          <img src={new_bg} alt="" />
          {/* <img src={sign_in} alt="" /> */}
        </div>

        <div className="right-wrapper">
          <div className="logo">
            <img
              className="haire-logo"
              src={haire_gif_new}
              alt=""
              onClick={() => navigate("/", { replace: true })}
            />
          </div>

          <hr className="spacer50px" />

          <form
            onSubmit={handleSubmit}
            className="frgt_pass_sec_new_main_inner"
          >
            <h6>Reset Password</h6>
            <p>
              Enter your email id and we’ll send a link on your registered email
              to reset your password
            </p>
            <hr className="spacer30px" />
            <div className="form-row">
              <label
                style={{
                  width: "100%",
                }}
              >
                <div className="label-wrapper">
                  Email ID<span className="required">*</span>
                </div>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter Email"
                  value={forgetPasswordFormData.email}
                  onChange={handleChange}
                  required
                />
                {forgetPasswordFormErrors.email && (
                  <div className="error">{forgetPasswordFormErrors.email}</div>
                )}
              </label>
            </div>
            <hr className="spacer30px" />
            <div className="button-wrapper">
              <div className="login_btn_blue">
                <button type="submit">
                  {loading ? (
                    <ThreeCircles
                      visible={true}
                      height="20"
                      width="20"
                      color="#fff"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    "Send Link"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default FortgetPassword;
