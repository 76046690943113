import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Skeleton, Card } from "antd";
import {
  web_icn,
  web_icn_1,
  web_icn_2,
  web_icn_3,
  web_icn_4,
} from "../../../../assets/images/images";
import DashboardHeader from "../../../../components/CommonComponents/DashboardHeader";
import JobCard from "../../../../components/CommonComponents/JobCard";
import ImageGallery from "../../../../components/CustomComponents/ImageGallery";
import {
  Navbar,
  PublicNavbar,
} from "../../../../components/DashboardComponents/Navbar/Navbar";
import { fetchCompanyDetails } from "../../../../redux/actions/companyActions";
import { no_of_employees1 } from "../../../../utils/constants";
import "../../RecruiterDashboard/MyCompany/MyCompany.css";
import CompanyDetails from "../../RecruiterDashboard/CompanyDetails/CompanyDetails";
import { setLoading } from "../../../../redux/reducers/authReducer";
import PageNotFound from "../../../../components/CommonComponents/PageNotFound";
import { marked } from "marked";

const toCamelCase = (str) => {
  str = str.replace(/\s*&\s*/g, " & ");

  return str
    .split(" ")
    .map((word, index) => {
      // Capitalize the first letter of each word and make the rest lower case
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};

const MyCompany = () => {
  const dispatch = useDispatch();
  const { companyId, companyName } = useParams();
  const navigate = useNavigate();
  const loading = useSelector((state) => state?.company?.loading);
  const [isShowPageNotFound, setShowIsPageNotFound] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const companyDetails = useSelector((state) => state?.company?.details);
  const [showAllJobs, setShowAllJobs] = useState(false);
  const [jobsToShow, setJobsToShow] = useState([]);
  const [jobIndex, setJobIndex] = useState(12);
  const jobsRef = useRef(null);

  useEffect(() => {
    if (token) {
      dispatch(fetchCompanyDetails(companyId, token));
    } else {
      dispatch(fetchCompanyDetails(companyId));
    }
  }, [dispatch, companyId, token]);

  useEffect(() => {
    if(companyDetails?.name !== companyName) {
      setShowIsPageNotFound(true);
      return
    }
    setShowIsPageNotFound(false);

    if (companyDetails?.jobs?.data) {
      setJobsToShow(companyDetails.jobs.data.slice(0, jobIndex));
    }
  }, [companyDetails, jobIndex]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 2
      ) {
        if (jobIndex < companyDetails?.jobs?.data.length) {
          setJobIndex((prevIndex) =>
            Math.min(prevIndex + 12, companyDetails.jobs.data.length)
          );
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [jobIndex, companyDetails?.jobs?.data.length]);

  const product_servicesArray =
    companyDetails && companyDetails.product_services
      ? typeof companyDetails.product_services === "string"
        ? companyDetails.product_services
            .split(",")
            .map((service) => toCamelCase(service))
        : Object.entries(companyDetails.product_services || {}).map(
            ([key, value]) => toCamelCase(value)
          )
      : [];

  const perksBenefitsArray =
    companyDetails && companyDetails.perks_benefits
      ? typeof companyDetails.perks_benefits === "string"
        ? companyDetails.perks_benefits
            .split(",")
            .map((benefit) => toCamelCase(benefit))
        : Object.entries(companyDetails.perks_benefits || {}).map(
            ([key, value]) => toCamelCase(value)
          )
      : [];

  const industryArray =
    companyDetails && companyDetails.industry
      ? typeof companyDetails.industry === "string"
        ? companyDetails.industry
            .split(",")
            .map((industry) => toCamelCase(industry))
        : Object.entries(companyDetails.industry || {}).map(([key, value]) =>
            toCamelCase(value)
          )
      : [];

  const cultureArray = companyDetails?.culture
    ? typeof companyDetails.culture === "string"
      ? companyDetails.culture.split(",").map((culture) => toCamelCase(culture))
      : Object.entries(companyDetails.culture || {}).map(([key, value]) =>
          toCamelCase(value)
        )
    : [];

  let numberOfEmployees = companyDetails?.no_of_employees;
  let result;

  if (
    typeof numberOfEmployees === "number" ||
    no_of_employees1[numberOfEmployees]
  ) {
    result = no_of_employees1[numberOfEmployees];
  } else {
    result =
      Object.values(companyDetails?.no_of_employees || {})[0] ||
      "Not specified";
  }

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const toggleJobView = () => {
    setShowAllJobs(!showAllJobs);
    setTimeout(() => {
      if (jobsRef.current) {
        jobsRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  const jobs = showAllJobs
    ? companyDetails?.jobs?.data || []
    : companyDetails?.jobs?.data?.slice(0, 3) || [];

  const onJobCardClick = (jobId) => {
    if (token) {
      navigate(`/applicant-dashboard/related-jobs/${jobId}`);
    } else {
      navigate(`/jobs/related-jobs/${jobId}`);
    }
  };
  if (loading) {
    return (
      <>
        {token ? <Navbar /> : <PublicNavbar />}
        <section className="related_sec_new">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="back_btn_main_sec">
                  <Skeleton.Button
                    active
                    shape="round"
                    size="small"
                    style={{ marginBottom: "20px" }}
                  />
                </div>
                <div className="related_sec_new_inner related_sec_new_inner1">
                  <div className="related_sec_new_inner_left">
                    <div className="related_sec_new_inner_left_top">
                      <Skeleton.Image
                        style={{
                          width: 100,
                          height: 100,
                          marginBottom: "20px",
                        }}
                      />
                      <Skeleton
                        active
                        title={false}
                        paragraph={{ rows: 1, width: "80%" }}
                        style={{ marginBottom: "10px" }}
                      />
                      <Skeleton
                        active
                        title={false}
                        paragraph={{ rows: 3, marginBottom: "20px" }}
                      />
                    </div>
                    <div className="related_sec_new_inner_btm">
                      <Skeleton
                        active
                        paragraph={{ rows: 4, marginBottom: "20px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
  return (
    <>
    {isShowPageNotFound ? <PageNotFound/> : (
      <>
      {token ? <Navbar /> : <PublicNavbar />}
      <section style={{ padding: "30px 0" }} className="margin-lg">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="my_comp_dashboard_sec">
                <div className="back_btn_main_sec mb-0">
                  <DashboardHeader
                    title=""
                    backButtonLink={handleBackButtonClick}
                    // style={{ marginTop: "80px" }}
                    className="margin_80"
                  />
                </div>
                {/* <div className="my_comp_dashboard_sec_banner">
                  <img src={dash_comp_ban} alt="" />
                  <div className="edit_icn_dash">
                    <Link>
                      <i className="fas fa-pencil-alt"></i>
                    </Link>
                  </div>
                </div> */}
                <div className="my_comp_dashboard_sec_body">
                  <div className="my_comp_dashboard_sec_body_head">
                    <div className="my_comp_dashboard_left_logo">
                      <div className="logo_sec_img">
                        <img src={companyDetails?.profile_pic} alt="" />
                      </div>
                      <h6>{companyDetails?.name}</h6>
                    </div>
                  </div>
                  <div className="comp_prof_cont_sec">
                    <div className="comp_prof_inner_text">
                      <h5>Company Profile</h5>
                      {/* <p>{companyDetails?.overview}</p> */}
                      <div className="comp_data"
                dangerouslySetInnerHTML={{
                  __html: marked(companyDetails?.overview || ""),
                }}
              ></div>
                    </div>
                    <div className="web_site_industy_sec">
                      <div className="web_site_industy_sec_inner">
                        <div className="web_site_industy_sec_inner_single">
                          <div className="web_site_industy_sec_inner_single_top">
                            <img src={web_icn} alt="" />
                            <h6>Website</h6>
                          </div>
                          <div className="web_site_industy_sec_inner_single_btm">
                            <h5>
                              <a
                                href={companyDetails?.website}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {companyDetails?.website}
                              </a>
                            </h5>
                          </div>
                        </div>
                        <div className="web_site_industy_sec_inner_single">
                          <div className="web_site_industy_sec_inner_single_top">
                            <img src={web_icn_1} alt="" />
                            <h6>Industry</h6>
                          </div>
                          <div className="web_site_industy_sec_inner_single_btm">
                            <h5>
                              {industryArray?.map((industry, index) => (
                                <span key={index}>
                                  {toCamelCase(industry)}
                                  {index < industryArray.length - 1 ? ", " : ""}
                                </span>
                              ))}
                            </h5>
                          </div>
                        </div>
                        <div className="web_site_industy_sec_inner_single">
                          <div className="web_site_industy_sec_inner_single_top">
                            <img src={web_icn_2} alt="" />
                            <h6>Company Size</h6>
                          </div>
                          <div className="web_site_industy_sec_inner_single_btm">
                            <h5>{result} Employees</h5>
                          </div>
                        </div>
                        <div className="web_site_industy_sec_inner_single">
                          <div className="web_site_industy_sec_inner_single_top">
                            <img src={web_icn_3} alt="" />
                            <h6>Address</h6>
                          </div>
                          <div className="web_site_industy_sec_inner_single_btm">
                            <h5>{companyDetails?.address}</h5>
                          </div>
                        </div>
                      </div>
                      <div className="web_site_industy_sec_inner_single">
                        <div className="web_site_industy_sec_inner_single_top">
                          <img src={web_icn_4} alt="" />
                          <h6>Product And Services </h6>
                        </div>
                        <div className="web_site_industy_sec_inner_single_btm">
                          <ul>
                            {product_servicesArray &&
                            product_servicesArray.length > 0 ? (
                              product_servicesArray.map((service, index) => (
                                <li key={index}>{service}</li>
                              ))
                            ) : (
                              <li>No services listed.</li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="pers_benifits_sec">
                      <h6>Perks & Benefits</h6>
                      <div className="web_site_industy_sec_inner_single_btm">
                        <ul>
                          {perksBenefitsArray?.map((benefit, index) => (
                            <li key={index}>
                              {benefit?.charAt(0).toUpperCase() +
                                benefit.slice(1)}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="pers_benifits_sec">
                      {companyDetails?.culture ? (
                        <div className="pers_benifits_sec">
                          <h6>Culture and Work Environment</h6>
                          <ul className="innovate_list">
                            {cultureArray.map((value, index) => (
                              <li key={index}>{value}</li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        <p>Culture details are not available.</p>
                      )}
                    </div>
                  </div>
                  <div className="comp_gallery_sec">
                    <ImageGallery images={companyDetails?.gallery} />
                  </div>
                  <div className="posted_jobs_main_sec">
                    {/* <div className="posted_jobs_main_sec_head">
                      <h6>Posted Jobs</h6>
                      <a
                        onClick={() => {
                          navigate("/applicant-dashboard");
                        }}
                        style={{ cursor: "pointer" ,fontSize:"16px"}}
                        className="Show_all_jobs_text"
                      >
                        Show all jobs
                        <i className="fas fa-long-arrow-alt-right"></i>
                      </a>
                    </div> */}
                    <div className="posted_jobs_main_sec_head">
                      <h6>Posted Jobs</h6>
                    </div>

                    {/* <div className="posted_jobs_main_sec_body">
                      {companyDetails?.jobs.map((jobData, index) => (
                        <JobCard key={index} jobData={jobData} />
                      ))}
                    </div> */}
                    <div className="posted_jobs_main_sec_body">
                      {jobsToShow.map((job, index) => (
                        <JobCard
                          key={job.job_details.id}
                          jobData={job}
                          onClick={() => onJobCardClick(job.job_details.id)}
                        />
                      ))}
                      {jobIndex >= companyDetails?.jobs?.data.length}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </>
      )}
    </>
  );
};
export default MyCompany;
