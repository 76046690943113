import { useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as BetterLuckIcon } from "../../../../assets/images/better-luck.svg";

export const BetterLuck = () => {
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = ""; // Chrome requires returnValue to be set
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  return (
    <>
      <section className="cong_sec_new">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="cong_sec_new_inner">
                <div className="cong_sec_new_inner_head">
                  <div className="cong_sec_new_inner_head_inner">
                    <BetterLuckIcon />
                    <br />
                    <h6>Better Luck Next Time!</h6>
                    <p>
                      We appreciate your interest. Unfortunately, you haven't
                      been shortlisted.
                      <br />
                      We encourage you to apply for future opportunities. Best
                      wishes!
                    </p>
                  </div>
                </div>
                <div className="bavk_jobs">
                  <Link to={"/applicant-dashboard"}>Back to Jobs</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
