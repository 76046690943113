import { Spin } from "antd";
import { ThreeCircles } from "react-loader-spinner";

export const UpgradePlan = ({
  credits,
  subscribePlan,
  isCurrentPlan,
  isLoading,
}) => {
  return (
    <>
      {isCurrentPlan ? (
        <div className="upgrade-wrapper" style={{flexDirection: "column"}}>
          <span className="upgrade-heading">
            Your {credits} credits / Month plan is active!
          </span>
          <span className="upgrade-subheading">Upgrade for more credits.</span>
        </div>
      ) : (
        <div className="upgrade-wrapper">
          <button className="pricing-button" onClick={subscribePlan} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {isLoading ? <ThreeCircles
              height="20"
              width="20"
              color="#ffffff"
              ariaLabel="loading-indicator"
            /> : "Upgrade"}
          </button>
        </div>
      )}
    </>
  );
};
