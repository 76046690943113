import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  haire_gif_new,
} from "../../../../../assets/images/images";
import { usePricingModal } from "../../../../../contexts/PricingModalContext";
import {
  completeRazorpayOrder,
  createRazorpayCheckoutSession,
  createStripeCheckoutSession,
  fetchRzPaymentStatus,
} from "../../../../../redux/actions/subscriptionActions";
import { useMyGeoLocation } from "react-geolocation-x";
import useGeolocationStore from "../../../../../zustand/locationStore";
import { fetchCompanyDetails } from "../../../../../redux/actions/companyActions";
import { toast } from "react-toastify";
import Plans from "./Plans";
import { resetCompany } from "../../../../../redux/reducers/companyReducer";

const PricingModal = ({ show, onHide }) => {
  const locationData = useMyGeoLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fetchLocation } = useGeolocationStore();
  const country = "US";
  const { hidePricingModal } = usePricingModal();
  const token = useSelector((state) => state.auth.token);
  const subscriptions = useSelector((state) => state.subscriptions);
  const free = subscriptions?.razorpay?.free;
  const stripeFree = subscriptions?.stripe?.free;
  const business = subscriptions?.razorpay?.business ?? [];
  const enterprise = subscriptions?.razorpay?.enterprise ?? [];
  const stripeBusiness = subscriptions?.stripe?.business ?? [];
  const stripeEnterprise = subscriptions?.stripe?.enterprise ?? [];
  const companyDetails = useSelector((state) => state.company?.details);
  const companySub = companyDetails?.company_sub;
  const subscriptionPlan = companySub?.subscription_plan;
  const userDetails = companyDetails?.user_details;

  const handleSubscribePlan = async (
    amount,
    subscriptionPlanId,
    setLoading
  ) => {
    if (!companyDetails?.id) {
      dispatch(resetCompany());
      navigate("/company-details", { state: { isEmpty: true } });
      return;
    }
    setLoading(true);
    if (country === "India") {
      const response = await dispatch(
        createRazorpayCheckoutSession(token, amount, subscriptionPlanId)
      );

      if (response.data.data !== null) {
        const amount = response?.data?.data?.amount;
        const currency = "INR";
        const orderId = response?.data?.data?.id;
        const subscriptionId = response?.data?.data?.sub_id;

        const options = {
          key: `${process.env.REACT_APP_RAZORPAY_KEY_ID}`,
          amount: amount,
          currency: currency,
          name: "Haire AI",
          description: "Test Transaction",
          image: haire_gif_new,
          order_id: orderId,
          subscription_id: subscriptionId,
          handler: async (response) => {
            const razorpay_payment_id = response?.razorpay_payment_id;
            const razorpay_subscription_id = response?.razorpay_subscription_id;
            const razorpay_signature = response?.razorpay_signature;

            const checkPaymentStatus = async () => {
              const paymentStatusResponse = await dispatch(
                fetchRzPaymentStatus(token, subscriptionId)
              );

              if (
                paymentStatusResponse.status === "active" ||
                paymentStatusResponse.status === "authenticated"
              ) {
                toast.warning(
                  "Please don't close the tab ,your payment is in progress"
                );
                await dispatch(
                  completeRazorpayOrder(
                    token,
                    razorpay_subscription_id,
                    razorpay_payment_id,
                    razorpay_signature
                  )
                );
                setLoading(false);

                await dispatch(
                  fetchCompanyDetails(companyDetails?.id, token, false)
                );
                toast.success(
                  `Payment successful. Payment ID: ${razorpay_payment_id}`
                );
                navigate("/payment-succeded", { replace: true });
                hidePricingModal();
              } else if (
                paymentStatusResponse.status === "pending" ||
                paymentStatusResponse.status === "created"
              ) {
                setTimeout(checkPaymentStatus, 3000); // Retry after 3 seconds
              }
            };

            await checkPaymentStatus(); // Initial status check
          },
          prefill: {
            name: userDetails?.full_name,
            email: userDetails?.email,
            contact: userDetails?.phone,
          },
          notes: {
            address: companyDetails?.address,
          },
          modal: {
            ondismiss: () => {
              const paymentId = orderId;
              const paymentDate = new Date().toISOString();

              navigate(
                `/payment-failed?paymentId=${paymentId}&paymentDate=${paymentDate}`,
                { replace: true }
              );
            },
          },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
      }
    } else {
      await dispatch(createStripeCheckoutSession(token, subscriptionPlanId));
      await dispatch(fetchCompanyDetails(companyDetails?.id, token, false));
    }
  };

  useEffect(() => {
    if (locationData.latitude && locationData.longitude) {
      fetchLocation(locationData.latitude, locationData.longitude);
    }
  }, [locationData.latitude, locationData.longitude]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="report_modal_sec custom-modal-size"
    >
      <div className="report_modal_sec_inner">
        <div className="report_modal_sec_inner_head">
          <button onClick={onHide}>
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div className="report_modal_sec_inner_body Add_card_modal">
          <h3 className="Pricing_head">
            Pricing{" "}
            <span className="Pricing_credit">
              Credit Plans for Smarter Hiring
            </span>
          </h3>
          <Plans
            free={country === "India" ? free : stripeFree}
            business={country === "India" ? business : stripeBusiness}
            enterprise={country === "India" ? enterprise : stripeEnterprise}
            country={country}
            subscriptionPlan={subscriptionPlan}
            handleSubscribePlan={handleSubscribePlan}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PricingModal;
