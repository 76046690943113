import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import { useNavigate } from "react-router";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Keyboard, Mousewheel, Navigation } from "swiper/modules";
import {
  Resume_1,
  Resume_2,
  Resume_3,
  Resume_4,
} from "../../../../assets/images/images";
import DashboardHeader from "../../../../components/CommonComponents/DashboardHeader";
import { Navbar } from "../../../../components/DashboardComponents/Navbar/Navbar";
import "./ResumePicker.css";

const ResumePicker = () => {
  const navigate = useNavigate();
  const handleBackButtonClick = () => {
    navigate("/resume-generator", { replace: true });
  };

  return (
    <>
      <Navbar />

      <section className="head_sec_pro_rect">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <DashboardHeader
                title="Select Resume Template"
                backButtonLink={handleBackButtonClick}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="head_sec_pro_rect">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <Swiper
                slidesPerView={3.5}
                spaceBetween={0}
                freeMode={true}
                mousewheel={true}
                pagination={{
                  clickable: true,
                }}
                keyboard={{
                  enabled: true,
                }}
                grabCursor={true}
                modules={[Keyboard, Navigation, Mousewheel]}
                navigation={true}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img src={Resume_1} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={Resume_2} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={Resume_3} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={Resume_4} alt="" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResumePicker;
