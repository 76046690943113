import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signOut,
  PhoneAuthProvider,
  signInWithPhoneNumber,
  signInWithCredential,
  RecaptchaVerifier,
  updatePhoneNumber,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firebaseFunctions = getFunctions(app);

let analytics;
if (typeof window !== "undefined") {
  analytics = getAnalytics(app);
}

export {
  auth,
  firebaseFunctions,
  httpsCallable,
  RecaptchaVerifier,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  analytics,
  signOut,
  PhoneAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  signInWithCredential,
  updatePhoneNumber,
  sendEmailVerification,
  sendPasswordResetEmail,
  confirmPasswordReset,
};
