import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { cancelSubscriptions } from "../../../../../../src/redux/actions/subscriptionActions";
import { fetchCompanyDetails } from "../../../../../redux/actions/companyActions";
import { ThreeCircles } from "react-loader-spinner";

const CancelSubModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.token);
  const companyId = useSelector((state) => state?.company?.details?.id);
  const [loading, setLoading] = useState(false);
  const subscriptionSuccess = useSelector(
    (state) => state?.subscription?.loading
  );
  const [showTextarea, setShowTextarea] = useState(false);
  const [error, setError] = useState("");
  const [textareaError, setTextareaError] = useState("");

  const handleCancel = async () => {
    setError("");
    setTextareaError("");
    let labelText = "";
    const selectedRadioButton = document.querySelector(
      'input[name="radio"]:checked'
    );
    if (selectedRadioButton) {
      const selectedValue = selectedRadioButton.value;
      if (selectedValue === "Other") {
        const textarea = document.getElementById("textMessage");
        if (textarea && textarea.value.trim()) {
          labelText = textarea.value.trim();
        } else {
          setTextareaError("Please provide additional details");
          return;
        }
      } else {
        labelText = selectedRadioButton.parentNode.textContent.trim();
      }
    } else {
      setError("Please select one of the options above");
      return;
    }
    setLoading(true); // Start loading
    setTimeout(() => {
      dispatch(cancelSubscriptions(token, labelText))
        .then((response) => {
          onHide();
          dispatch(fetchCompanyDetails(companyId, token));
        })
        .catch((error) => {
          console.error("Failed to cancel subscription:", error);
        })
        .finally(() => {
          setLoading(false); // Stop loading after all processes are complete
        });
    }, 2000);
  };

  useEffect(() => {
    dispatch(fetchCompanyDetails(companyId, token));
  }, [show]);

  const handleCloseReasonModal = () => {
    onHide();
    setError("");
    setTextareaError("");
  };

  const handleRadioChange = (event) => {
    setError("");
    setTextareaError("");
    if (event.target.value === "Other") {
      setShowTextarea(true);
    } else {
      setShowTextarea(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} className="report_modal_sec">
      <div className="report_modal_sec_inner">
        <div className="report_modal_sec_inner_head">
          <button onClick={onHide}>
            <i className="fas fa-times"></i>
          </button>
        </div>
        <div className="report_modal_sec_inner_body Add_card_modal">
          <h3>Sorry to see you go!</h3>
          <p className="plan_cancle_p">
            Before you leave, could you spare a moment to tell us why? Your
            feedback helps us get better
          </p>
          <ul className="report_mod_list_new">
            <li>
              <label className="container">
                The plan doesn't have the recruitment features we need
                <input
                  type="radio"
                  name="radio"
                  value="Spam"
                  onChange={handleRadioChange}
                />
                <span className="checkmark"></span>
              </label>
            </li>
            <li>
              <label className="container">
                We're switching to a different recruitment service
                <input
                  type="radio"
                  name="radio"
                  value="Discriminatory"
                  onChange={handleRadioChange}
                />
                <span className="checkmark"></span>
              </label>
            </li>
            <li>
              <label className="container">
                We need to reduce hiring costs due to budget constraints
                <input
                  type="radio"
                  name="radio"
                  value="Discriminatory"
                  onChange={handleRadioChange}
                />
                <span className="checkmark"></span>
              </label>
            </li>
            <li>
              <label className="container">
                The recruitment plan is too costly
                <input
                  type="radio"
                  name="radio"
                  value="Inaccurate"
                  onChange={handleRadioChange}
                />
                <span className="checkmark"></span>
              </label>
            </li>
            <li>
              <label className="container">
                Other
                <input
                  type="radio"
                  name="radio"
                  value="Other"
                  onChange={handleRadioChange}
                />
                <span className="checkmark"></span>
              </label>
            </li>
            {showTextarea && (
              <li>
                <div className="describe_issue_sec">
                  <textarea
                    id="textMessage"
                    placeholder="Please provide additional details"
                    rows={5}
                  ></textarea>
                </div>
              </li>
            )}
          </ul>
          {error && <span className="error">{error}</span>}
          {textareaError && <span className="error">{textareaError}</span>}
          <br />
          <br/>
          <div className="cancle_sub_btn">
            <button
              className="Add_card_btn wdt_btn_bg"
              onClick={handleCloseReasonModal}
              disabled={subscriptionSuccess}
            >
              I Changed my mind
            </button>

            <button
              className="Add_card_btn clear_btn wdt_btn_bg_hover"
              onClick={handleCancel}
              disabled={loading || subscriptionSuccess}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              {loading || subscriptionSuccess ? (
                <ThreeCircles
                  height="20"
                  width="20"
                  color="#ffffff"
                  ariaLabel="loading-indicator"
                />
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CancelSubModal;
