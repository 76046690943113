import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { user_image } from "../../../assets/images/images";
import { signOut } from "../../../redux/actions/authActions";
import "./navbar.css";
import { toast } from "react-toastify";
import { fetchJobSeekerDetails } from "../../../redux/actions/jobSeekerActions";

const MenuList = () => {
  const user = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [isOpenName, setIsOpenName] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenName(false);
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []); 

  const toggleDropdownNew = () => {
    setIsOpenName(!isOpenName);
  };

  const handleSignOut = () => {
    toast.dismiss();
    dispatch(signOut());
    navigate("/");
  };
  const handleViewProfile = () => {
    dispatch(fetchJobSeekerDetails(user?.token, user?.jobseeker_id));
    navigate("/applicantprofile");
  };

  return (
    <>
      <div className="dropdown user_text_new" ref={dropdownRef}>
        <div onClick={toggleDropdownNew}>
          <img src={user_image} alt="User" className="rounded-circle me-2" />
          <button className="dropdown-toggle" type="button">
            {user ? <p>{user?.user?.full_name} </p> : <p>Name</p>}
          </button>
        </div>

        <div
          className={`dropdown-menu user_text_new_inner dropdown-menu-right ${
            isOpenName ? "show" : ""
          }`}
        >
          <ul>
          {user?.user?.role !== 1 && (
              <li>
                <a href="#" onClick={handleViewProfile}>
                  <i className="fas fa-user"></i>Profile
                </a>
              </li>
            )}
            {/* <li>
                    <a href="#">
                        <i class="fas fa-cog"></i>Settings
                    </a>
                </li> */}
            <li>
              <a href="#" onClick={handleSignOut}>
                <i className="fas fa-sign-out-alt"></i>Sign Out
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default MenuList;
