import { Checkbox, Form, Input, Radio, Skeleton, Tooltip } from "antd";
import axios from "axios";
import { marked } from "marked";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  PageNotFoundImg,
  arc_img_1,
  arc_img_2,
  arc_img_3,
  arc_img_4,
  linkedin,
  mail_icn,
  report_vec,
  sh_icn,
  warning_image,
  wp_icn,
} from "../../../../assets/images/images";
import DashboardHeader from "../../../../components/CommonComponents/DashboardHeader";
import JobCard from "../../../../components/CommonComponents/JobCard";
import LoaderApply from "../../../../components/CommonComponents/Loader/LoaderApply";
import {
  Navbar,
  PublicNavbar,
} from "../../../../components/DashboardComponents/Navbar/Navbar";
import { fetchCompanyDetails } from "../../../../redux/actions/companyActions";
import {
  applyJob,
  checkJobStatus,
  fetchCurrencies,
  fetchSingleJobDetails,
  reportJob,
  requestInterview,
} from "../../../../redux/actions/jobActions";
import {
  createInterview,
  updateJobSeeker,
} from "../../../../redux/actions/jobSeekerActions";
import { setRedirectUrl } from "../../../../redux/reducers/authReducer";
import {
  employmentType,
  mode_of_work,
  yearsOfExperience,
} from "../../../../utils/constants";
import {
  Congrats,
  Sheduleint,
  StayPop,
} from "../InterviewModule/InterviewModule";
import "../InterviewModule/InterviewModule.css";
import "./RelatedJobs.css";

const RelatedJobs = () => {
  const { jobId } = useParams();
  const location = useLocation();
  const { currentPage } = location.state || { currentPage: 1 };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [skipCongrats, setSkipCongrats] = useState(false);
  const [localInterviewId, setLocalInterviewId] = useState(null);
  const [loading, setLoading] = useState(false);
  const currencies = useSelector((state) => state.job.currencies);
  const [defaultCurrency, setDefaultCurrency] = useState("USD");
  const [showTextArea, setShowTextArea] = useState(false);
  const user = useSelector((state) => state?.auth?.user);
  const userId = useSelector((state) => state?.auth?.jobseeker_id);
  const [isLoading, setIsLoading] = useState(true);
  const [jobActive, setJobActive] = useState(true);
  const [
    showPhysicallyChallengedTextArea,
    setShowPhysicallyChallengedTextArea,
  ] = useState(false);

  const token = useSelector((state) => state?.auth?.token);
  const profileCompleted = useSelector(
    (state) => state?.auth?.user?.profile_completed
  );

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [showNew, setshowNew] = useState(false);

  const [showreport, setshowreport] = useState(false);
  const reportMod = () => {
    setshowreport(true);
  };
  const [showTextarea, setShowTextarea] = useState(false);
  const [isRadioClicked, setIsRadioClicked] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const handleClosereport = () => {
    setshowreport(false); // Close the modal
    setReportNew(true); // Reset to show the report form when reopened
    setSubNewsec(false); // Reset to not show the submission confirmation when reopened
    // Reset any additional state related to the report form if necessary
  };
  const openModal = () => {
    setShowProfile(true);
  };

  const closeModal = () => {
    setShowProfile(false);
  };

  const onCheckShow = (e) => {
    setShowPhysicallyChallengedTextArea(e.target.checked);
  };

  const handleRadioChange = (event) => {
    // Check if the radio button with the value "Other" is selected
    if (event.target.value === "Other") {
      setShowTextarea(true);
    } else {
      setShowTextarea(false);
    }
    setIsRadioClicked(true);
  };

  const jobDetails = useSelector((state) => state?.job?.details);
  const recommendedJobStatus = useSelector(
    (state) => state?.job?.details?.recommendation
  );
  const applied_jobs_status =
    jobDetails?.job_application_details?.status ?? "Apply";

  const userHasApplied = applied_jobs_status !== "Apply";

  const [SubNewsec, setSubNewsec] = useState(false);
  const [ReportNew, setReportNew] = useState(true);

  const HideMod = () => {
    setshowreport(false);
    setIsRadioClicked(false);
    setShowTextarea(false);
  };

  /////////////////////////////Apply Job ///////////////////////////////////////////////////////
  const handleShowApply = async (jobId) => {
    if (!token) {
      dispatch(setRedirectUrl(`/applicant-dashboard/related-jobs/${jobId}`));
      navigate("/sign-up");
      return;
    }
    setLoading(true);

    if (!profileCompleted) {
      openModal();
    } else {
      try {
        const interviewId = await dispatch(applyJob(token, jobDetails?.id));
        if (interviewId?.id) {
          setLocalInterviewId(interviewId?.id);
          if (interviewId.status === "Not-Fit") {
            setSkipCongrats(true);
          } else {
            setSkipCongrats(false);
          }
          setshowNew(true);
        }
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
        setLoading(false);
      } finally {
        setLoading(false); // Always turn off loading indicator when done
      }
    }
  };

  /////////////////////////////////////Start Interview of Applied job////////////////////////////////////

  const handleStartInterviewClick = async () => {
    setLoading(true);
    try {
      const jobIdNumber =
        typeof jobId === "string" ? jobDetails?.id : parseInt(jobId);
      const data = await dispatch(createInterview(jobIdNumber, token, 1));
      const newInterviewId = data.data.id;

      // Encode jobname and companyName to handle special characters
      const jobname = encodeURIComponent(data.data.job_title);
      const companyName = encodeURIComponent(data.data.company_name);

      navigate(
        `/applicant-dashboard/related-jobs/${jobId}/start-interview/${newInterviewId}/${jobname}/${companyName}`,
        { state: { data: data.data } }
      );
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      dispatch(fetchCurrencies(token));
    }
  }, [jobId]);

  const onCheck = (e) => {
    setShowTextArea(e.target.checked);
  };

  ///////////////////////////Text rendering events//////////////////////////////////////////////////////
  const capitalizeFirstLetter = (string) => {
    if (typeof string !== "string" || !string) {
      return "";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleFormSubmit = (formDetails) => {
    dispatch(
      updateJobSeeker(
        token,
        userId,
        undefined,
        undefined,
        user?.profile_pic,
        formDetails
      )
    )
      .then(() => {
        // Assuming your `updateJobSeeker` action resolves after successful profile update
        toast.success("Profile completed successfully!"); // Show success toast
        closeModal(); // Close the profile completion modal
        setshowNew(true);

        const interviewId = dispatch(applyJob(token, jobDetails?.id));
        if (interviewId?.id) {
          setLocalInterviewId(interviewId?.id);
          if (interviewId.status === "Not-Fit") {
            setSkipCongrats(true);
          } else {
            setSkipCongrats(false);
          }
          setshowNew(true);
        } else {
          console.error(
            "Failed to apply for the job or to retrieve the interview ID."
          );
        }
      })
      .catch((error) => {
        // Handle any errors here
        toast.error("Failed to complete the profile.");
      });
  };
  const handleCloseApply = () => setshowNew(false);

  const [popupCompontStatus, setPopupCompontStatus] = useState(1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPopupCompontStatus((prevStatus) => {
        if (prevStatus < 3) {
          return prevStatus + 1;
        } else {
          clearInterval(intervalId);
          return 3;
        }
      });
    }, 5000);
    return () => clearInterval(intervalId);
  }, [popupCompontStatus]);

  const isButtonDisabled = () => {
    // Enable the button by default if no token is present
    if (!token) return false;

    // Additional conditions when the user is logged in
    if (userHasApplied) {
      return applied_jobs_status !== "Fit-For-Job"; // Disable if user has applied but not fit for the job
    }

    return !recommendedJobStatus; // Disable if the job is not recommended
  };
  const jobrelated = useSelector((state) => state?.job?.details?.related_jobs);

  const onJobCardClick = (jobId) => {
    if (token) {
      localStorage.setItem("lastRoute", location.pathname + location.search);
      navigate(`/applicant-dashboard/related-jobs/${jobId}`, {
        state: { currentPage },
      });
    } else {
      localStorage.setItem("lastRoute", location.pathname + location.search);
      navigate(`/jobs/related-jobs/${jobId}`);
    }
  };

  useEffect(() => {
    if (token) {
      dispatch(fetchSingleJobDetails(jobId, token));
    } else {
      dispatch(fetchSingleJobDetails(jobId));
    }
  }, [dispatch, jobId, token]);

  useEffect(() => {
    const fetchJobDetails = async () => {
      setIsLoading(true); // Start loading before the request
      try {
        const isActive = await dispatch(checkJobStatus(jobId, token));
        setJobActive(isActive);
        if (isActive) {
          if (token) {
            await dispatch(fetchSingleJobDetails(jobId, token));
          } else {
            await dispatch(fetchSingleJobDetails(jobId));
          }
        }
        setIsLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching job details:", error);
        setIsLoading(false); // Ensure loading state is turned off on error
        toast.error("Failed to load job details. Please refresh the page.");
      }
    };

    fetchJobDetails();
  }, [dispatch, jobId, token]);

  const getModeOfWork = (key) => {
    return mode_of_work[key] || "Unknown";
  };

  const renderMarkdown = (description) => {
    if (description) {
      return { __html: marked(description) };
    }
    return { __html: "" };
  };

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  if (!jobDetails) {
    return <div>Loading...</div>;
  }

  const handleReportSubmit = async () => {
    let labelText = "";
    const selectedRadioButton = document.querySelector(
      'input[name="radio"]:checked'
    );
    if (selectedRadioButton) {
      const selectedValue = selectedRadioButton.value;
      if (selectedValue === "Other") {
        const textarea = document.getElementById("textMessage");
        if (textarea && textarea.value.trim()) {
          labelText = textarea.value.trim();
        } else {
          return;
        }
      } else {
        labelText = selectedRadioButton.parentNode.textContent.trim();
      }
    } else {
      return;
    }
    try {
      const response = dispatch(reportJob(token, labelText, jobDetails?.id));
      if (response) {
        setSubNewsec(true);
        setReportNew(false);

        if (!response) {
          handleClosereport();
        }
      } else {
        throw new Error(`API responded with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Failed to report job:", error);
    }
  };

  const fetchdata = () => {
    dispatch(fetchCompanyDetails(jobDetails?.company_id, token));
  };

  const buttonTitle = () => {
    if (!token) return "Apply"; // Prompt to log in if not logged in
    if (!recommendedJobStatus) {
      return "Not Applicable";
    }
    if (userHasApplied) {
      switch (applied_jobs_status) {
        case "Fit-For-Job":
          return "Start Interview"; // Move directly to interview if applicable
        case "Shortlisted":
          return "Shortlisted";
        case "Rejected":
          return "Rejected";
        case "Under-Review":
          return "Under-Review";
        case "Requested":
          return "Requested";
        default:
          return "Application status: " + applied_jobs_status; // Generic status message
      }
    }
    return "Apply"; // Default button title if not applied
  };

  const handleButtonClick = () => {
    if (!token) {
      dispatch(setRedirectUrl(`/applicant-dashboard/related-jobs/${jobId}`));
      navigate("/sign-up"); // Navigate to sign-up if not logged in
      return;
    }

    if (userHasApplied && applied_jobs_status === "Fit-For-Job") {
      if (!profileCompleted) {
        openModal();
      } else {
        handleStartInterviewClick(); // Start interview if applicable
      }
    } else if (
      !loading &&
      !isButtonDisabled() &&
      applied_jobs_status !== "Not-Fit"
    ) {
      handleShowApply(jobId); // Show application process
    }
  };

  const handleRequestInterview = async () => {
    try {
      dispatch(requestInterview(token, jobId));
    } catch (error) {
      return error;
    }
  };
  if (isLoading) {
    return (
      <>
        {token ? <Navbar /> : <PublicNavbar />}
        <section className="related_sec_new">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="back_btn_main_sec">
                  <Skeleton.Button
                    active
                    shape="round"
                    size="small"
                    style={{ marginBottom: "20px" }}
                  />
                </div>
                <div className="related_sec_new_inner">
                  <div className="related_sec_new_inner_left">
                    <div className="related_sec_new_inner_left_top">
                      <Skeleton.Image
                        style={{
                          width: 100,
                          height: 100,
                          marginBottom: "20px",
                        }}
                      />
                      <Skeleton
                        active
                        title={false}
                        paragraph={{ rows: 1, width: "80%" }}
                        style={{ marginBottom: "10px" }}
                      />
                      <Skeleton
                        active
                        title={false}
                        paragraph={{ rows: 3, marginBottom: "20px" }}
                      />
                    </div>
                    <div className="related_sec_new_inner_btm">
                      <Skeleton
                        active
                        paragraph={{ rows: 4, marginBottom: "20px" }}
                      />
                    </div>
                  </div>
                  <div className="related_sec_new_inner_right sticky">
                    <Skeleton
                      active
                      title={true}
                      paragraph={false}
                      style={{ marginBottom: "20px" }}
                    />
                    {Array(3)
                      .fill()
                      .map((_, index) => (
                        <div
                          key={index}
                          className="related-job-skeleton"
                          style={{ marginBottom: "20px" }}
                        >
                          <Skeleton.Input
                            style={{ height: 80, marginBottom: "10px" }}
                            active
                          />
                          <Skeleton
                            active
                            paragraph={{ rows: 1, marginBottom: "20px" }}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  if (!jobActive) {
    return (
      // <div className="job-not-found">
      //   <h2>Job Not Found</h2>
      //   <p>
      //     The job you are looking for does not exist or is no longer available.
      //   </p>
      //   <button onClick={handleBackButtonClick}>Go Back</button>
      // </div>
      <section className="container">
        <div className="page_not_found_main">
          <img src={PageNotFoundImg} alt="page not found" />
          <h3>Job Not Found</h3>
          <p>
            The job you are looking for does not exist or is no longer
            available.
          </p>
          <button
            onClick={handleBackButtonClick}
            className="Pagenotfoundbackbtn"
          >
            Explore Jobs
          </button>
        </div>
      </section>
    );
  }

  return (
    <>
      {token ? <Navbar /> : <PublicNavbar />}
      <section className="related_sec_new">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="back_btn_main_sec">
                <DashboardHeader
                  title=""
                />
              </div>
              {/* {!token && (
                <div className="company_details_sec_banner_main">
                  <div className="job_banner">
                    <div style={{ display: "flex", gap: "8px" }}>
                      <img src={warning_image} alt="warning sign" />
                      <p>Important Note </p>
                    </div>
                    <hr />
                    <ul>
                      <li>
                        Skills should match at least 30% of those listed by the
                        recruiter.
                      </li>
                      <li>
                        Experience should be within 1 year less and 2 years more
                        than the recruiter's requirement (e.g., for 4 years
                        required, acceptable range is 3-6 years).
                      </li>
                      <li>
                        Job title or past experience must be relevant to the
                        recruiter's posting.
                      </li>
                    </ul>
                  </div>
                </div>
              )} */}
              <div
                className={` ${jobrelated?.length ? "related_sec_new_inner" : "expand"
                  }`}
              >
                <div className="related_sec_new_inner_left ">
                  <div className="related_sec_new_inner_left_top">
                    <div className="related_sec_new_inner_left_top_head">
                      <div className="companyDetails_name">
                        <h6>{jobDetails.title}</h6>
                        <Link
                          to={`/${jobDetails?.company_name}/${jobDetails?.company_id}`}
                          onClick={fetchdata}
                        >
                          <h5
                            style={{
                              color: "#1865c1",
                              textDecoration: "underline",
                            }}
                          >
                            By {jobDetails?.company_name}
                          </h5>
                        </Link>
                      </div>

                      <div className="related_sec_top_right">
                        <ul>
                          <li>
                            <Link
                              className="share_btn_icn"
                              onClick={handleShow}
                            >
                              <img src={sh_icn} alt="" />
                            </Link>
                          </li>

                          {token && (
                            <li>
                              <img
                                src={report_vec}
                                onClick={reportMod}
                                className="report-icon"
                                title="Report"
                              />
                            </li>
                          )}

                          {jobDetails?.request_interview && (
                            <li>
                              <button
                                className="app_btn_rel"
                                onClick={handleRequestInterview}
                                disabled={loading}
                                style={{
                                  cursor: loading ? "not-allowed" : "pointer",
                                }}
                              >
                                {loading
                                  ? "Requesting..."
                                  : "Request Interview"}
                              </button>
                            </li>
                          )}

                          {!jobDetails?.request_interview && (
                            <li>
                              <Tooltip
                                title={
                                  !recommendedJobStatus
                                    ? jobDetails?.recommendation_reason
                                    : ""
                                }
                                placement="top"
                              >
                                <span>
                                  <button
                                    className={`app_btn_rel ${isButtonDisabled() ? "disabled" : ""
                                      }`}
                                    onClick={handleButtonClick}
                                    disabled={loading || isButtonDisabled()}
                                    title={buttonTitle()}
                                    style={{
                                      cursor:
                                        isButtonDisabled() || loading
                                          ? "not-allowed"
                                          : "pointer",
                                    }}
                                  >
                                    {loading ? <LoaderApply /> : buttonTitle()}
                                  </button>
                                </span>
                              </Tooltip>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="related_sec_new_inner_left_top_body">
                      <ul>
                        <li>
                          <Link
                            to={`/${jobDetails?.company_name}/${jobDetails?.company_id}`}
                            onClick={fetchdata}
                          >
                            <img
                              src={jobDetails?.company_profile_pic}
                              className="log_ce_icn"
                              style={{ width: "100px" }}
                              alt=""
                            />
                          </Link>
                        </li>
                        <li>
                          <img src={arc_img_1} alt="" />
                          {jobDetails?.location}
                        </li>
                        <li>
                          <img src={arc_img_2} alt="" />{" "}
                          {getModeOfWork(jobDetails?.mode_of_work)}
                        </li>
                        <li>
                          <img src={arc_img_3} alt="" />{" "}
                          {capitalizeFirstLetter(
                            employmentType[jobDetails?.employment_type] ?? ""
                          )}
                        </li>
                        <li>
                          <img src={arc_img_4} alt="" />{" "}
                          {capitalizeFirstLetter(
                            yearsOfExperience[jobDetails?.experience] ?? ""
                          )}{" "}
                          Experience
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="related_sec_new_inner_btm">
                    <div className="soclia_meid_main_sec_body_single">
                      <div
                        dangerouslySetInnerHTML={renderMarkdown(
                          jobDetails?.description
                        )}
                      ></div>
                    </div>
                    <div className="soclia_meid_main_sec_body_single">
                      <h5>About Company</h5>
                      {/*
                       */}
                      <div
                        className="comp_data"
                        dangerouslySetInnerHTML={{
                          __html: marked(jobDetails?.company_overview || ""),
                        }}
                      ></div>
                      <p>{jobDetails?.company_address}</p>
                    </div>
                  </div>
                </div>
                {jobrelated?.length > 0 && (
                  <div className="related_sec_new_inner_right sticky">
                    <h2>Related Jobs</h2>
                    {jobrelated?.map((jobData, index) => (
                      <JobCard
                        key={index}
                        jobData={jobData}
                        onClick={() => onJobCardClick(jobData?.job_details?.id)}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={showProfile}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        className="report_modal_sec modal-wd"
        size="xl"
      >
        <Modal.Body className="pading_modal">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h3 className="modal_heading">Complete your profile details</h3>
              <div className="form_sec_new_inner_first_innwer">
                <Form
                  name="profileDetailsForm"
                  onFinish={handleFormSubmit}
                  // initialValues={{ currency: defaultCurrency }}
                  className="job_details_from completeProfile"
                >
                  <div className="row">
                    <label>
                      Annual Compensation:
                      <span className="custom_astrix">*</span>
                    </label>
                    <div className="col-lg-2 col-md-4 col-sm-4 text-start years_exp">
                      <Form.Item
                        name="currency"
                        initialValue={defaultCurrency}
                        rules={[
                          {
                            required: true,
                            message: "Please select a currency",
                          },
                        ]}
                      >
                        <select
                          placeholder="Select Currency"
                          className="CurrencyType custom_select"
                          defaultValue={defaultCurrency}
                        >
                          {Object.entries(currencies).map(([key, value]) => (
                            <option
                              id="currency"
                              key={key}
                              value={key}
                            >{`${value} ${key}`}</option>
                          ))}
                        </select>
                      </Form.Item>
                    </div>
                    <div className="col-lg-10 col-md-8 col-sm-8 text-start years_exp">
                      <Form.Item
                        name="current_ctc"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the starting salary",
                          },
                          {
                            type: "number",
                            min: 0.01, // Ensures the value is greater than 0
                            transform(value) {
                              return value ? Number(value) : undefined;
                            },
                            message:
                              "The salary must be a positive number greater than 0",
                          },
                        ]}
                      >
                        <Input
                          placeholder="E.g., 50000"
                          type="number"
                          min="0" // HTML5 native validation to ensure the input is greater than 0
                          step="any" // Allows entering decimal values
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <Checkbox
                        checked={showTextArea}
                        onChange={onCheck}
                        className="checkbox-flex"
                      >
                        Would you like to provide your compensation structure?
                      </Checkbox>

                      {showTextArea && (
                        <Form.Item
                          name="ctc_options"
                          rules={[
                            { required: true, message: "Details are required" },
                          ]}
                        >
                          <Input.TextArea
                            style={{
                              height: 200,
                              resize: "none",
                              marginTop: "10px",
                            }}
                            className="textarea_generator"
                            placeholder="Basic Salary
                Allowances (Housing, Transport, Meal, etc.)
                Incentives/Bonuses
                Medical Allowance
                Provident Fund
                Gratuity
                Other Benefits (Insurance, Stock Options, etc.)"
                            rows={4}
                          />
                        </Form.Item>
                      )}
                    </div>
                  </div>

                  <div className="row mt-1 mb-1">
                    <div className="col">
                      <Checkbox
                        checked={showPhysicallyChallengedTextArea}
                        onChange={onCheckShow}
                        className="checkbox-flex"
                      >
                        Assistance Benefits
                      </Checkbox>

                      {showPhysicallyChallengedTextArea && (
                        <Form.Item
                          name="physically_challenged"
                          rules={[
                            { required: true, message: "Details are required" },
                          ]}
                        >
                          <Input.TextArea
                            style={{
                              height: 200,
                              resize: "none",
                              marginTop: "10px",
                            }}
                            className="textarea_generator"
                            // placeholder=""
                            rows={4}
                          />
                        </Form.Item>
                      )}
                    </div>
                  </div>

                  <Form.Item
                    name="notice_period"
                    label="Resignation Notice"
                    rules={[
                      {
                        required: true,
                        message: "Please select!",
                      },
                    ]}
                  >
                    <Radio.Group className="radio">
                      <Radio.Button value={1} className="num0femp">
                        15 Days or less
                      </Radio.Button>
                      <Radio.Button value={2} className="num0femp">
                        1 Months
                      </Radio.Button>
                      <Radio.Button value={3} className="num0femp">
                        2 Months
                      </Radio.Button>
                      <Radio.Button value={4} className="num0femp">
                        3 Months
                      </Radio.Button>
                      <Radio.Button value={5} className="num0femp">
                        More than 3 Months
                      </Radio.Button>
                      <Radio.Button value={6} className="num0femp">
                        Serving Resignation Period
                      </Radio.Button>
                      <Radio.Button value={7} className="num0femp">
                        Immediately
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <div className="button_profile">
                    {/* <Button className="transp">skip for now</Button> */}
                    <Button type="primary" htmlType="submit">
                      Save & Proceed{" "}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showreport}
        onHide={handleClosereport}
        className="report_modal_sec"
      >
        {ReportNew && (
          <div className="report_modal_sec_inner">
            <div className="report_modal_sec_inner_head">
              <h6>Report</h6>
              <button onClick={HideMod}>
                <i className="fas fa-times"></i>
              </button>
            </div>
            <div className="report_modal_sec_inner_body">
              <h5>Why are you reporting this?</h5>
              <ul className="report_mod_list_new">
                <li>
                  <label className="container">
                    This job listing appears to be spam or a scam, including
                    options such as phishing, promotional content, or offering
                    illegal goods or services.
                    <input
                      type="radio"
                      name="radio"
                      value="Spam"
                      onChange={handleRadioChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="container">
                    This listing contains discriminatory or offensive content,
                    including support for violence or discrimination.
                    <input
                      type="radio"
                      name="radio"
                      value="Discriminatory"
                      onChange={handleRadioChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="container">
                    This job listing appears to have issues such as being
                    closed, inaccurate address, or other inaccuracies.
                    <input
                      type="radio"
                      name="radio"
                      value="Inaccurate"
                      onChange={handleRadioChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="container">
                    Other
                    <input
                      type="radio"
                      name="radio"
                      value="Other"
                      onChange={handleRadioChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </li>
                {showTextarea && (
                  <li>
                    <div className="describe_issue_sec">
                      <h4>Describe the issue you are facing :</h4>
                      <textarea
                        id="textMessage"
                        placeholder="Please Describe here.."
                        rows={5}
                      ></textarea>
                    </div>
                  </li>
                )}
              </ul>
              <div className="submit_btn_report_pop">
                <button
                  onClick={handleReportSubmit}
                  disabled={!isRadioClicked}
                  className={
                    isRadioClicked ? "enabledButton" : "disabledButton"
                  }
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}

        {SubNewsec && (
          <div className="report_modal_sec_inner">
            <div className="report_modal_sec_inner_head">
              <h6>Report Received</h6>
              <button onClick={HideMod}>
                <i className="fas fa-times"></i>
              </button>
            </div>
            <div className="report_modal_sec_inner_body">
              <h3>Thank you for reporting!</h3>
              <p>
                Thank you for bringing this to our attention. Your report has
                been received and will be addressed accordingly.
              </p>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        show={showNew}
        onHide={handleCloseApply}
        className="shedule_inertview_sec"
        backdrop="static"
      >
        {!skipCongrats && popupCompontStatus === 1 && <StayPop />}
        {!skipCongrats && popupCompontStatus === 2 && <Congrats />}
        {(skipCongrats || popupCompontStatus === 3) && (
          <Sheduleint
            jobId={jobId}
            interviewId={localInterviewId}
            onlyBackButton={skipCongrats}
          />
        )}
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        className="share_prof_sec_mod"
        size="xl"
      >
        <div className="share_prof_sec_mod_inner">
          <button className="close_icn_new_sh" onClick={handleClose}>
            <i className="fas fa-times"></i>
          </button>
          <h6>Share</h6>
          <ul>
            <li>
              <a
                href={`https://api.whatsapp.com/send?text=Check out this job! ${encodeURIComponent(
                  `${window.location.origin}/jobs/${jobDetails?.slug}`
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={wp_icn} alt="whatsapp" />
              </a>
            </li>
            <li>
              <a
                href={`mailto:?subject=Check out this job!&body=Check out this job at ${encodeURIComponent(
                  `${window.location.origin}/jobs/${jobDetails?.slug}`
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={mail_icn} alt="gmail" />
              </a>
            </li>
            <li>
              <a
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                  `${window.location.origin}/jobs/${jobDetails?.slug}`
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="linkedin" />
              </a>
            </li>
          </ul>
          <div className="copy_link_sec">
            <label>Or copy link</label>
            <div className="copy_link_sec_input">
              <input
                type="text"
                readOnly
                value={
                  `${window.location.origin}/jobs/${jobDetails?.slug}` ||
                  "example.com/share-link"
                }
              />
              <button
                onClick={() => {
                  navigator.clipboard
                    .writeText(
                      `${window.location.origin}/jobs/${jobDetails?.slug}` ||
                      "example.com/share-link"
                    )
                    .then(
                      () => {
                        toast.success("Link copied to clipboard!");
                      },
                      () => {
                        toast.error("Failed to copy the link.");
                      }
                    );
                }}
              >
                Copy
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RelatedJobs;
