import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { re_img, re_shedule } from "../../../../assets/images/images";
import { Navbar } from "../../../../components/DashboardComponents/Navbar/Navbar";
const InteviewReshedule = () => {
  const [showNew, setshowNew] = useState(false);
  const readyInt = () => setshowNew(true);
  const closeHandel = () => setshowNew(false);

  return (
    <>
      <Navbar />

      <section className="interview_ended">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="interview_ended_inner">
                <div className="interview_ended_inner_left">
                  <h6>Your interview has been ended</h6>
                  <p>Could you tell us why you ended the interview?</p>
                  <select>
                    <option>Select Reason</option>
                    <option>Technical Challenges</option>
                    <option>Network and Connectivity Issues</option>
                    <option>Personal or Emergency Situations</option>
                    <option>Privacy and Confidentiality Considerations</option>
                    <option>Time Constraints and Scheduling Issues</option>
                    <option>Other</option>
                  </select>
                  <hr className="spacer20px" />
                  <input type="text" placeholder="Enter your reason" />
                  <Link onClick={readyInt}>Submit</Link>
                </div>
                <div className="interview_ended_inner_right">
                  <img src={re_img} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {showNew && (
        <Modal show={showNew} onHide={closeHandel} className="reshedule_screen">
          <div className="reshedule_screen_inner">
            <img src={re_shedule} alt="" />
            <h6>Schedule Interview</h6>
            <p>
              Would you like to choose a new date and time for the interview?
            </p>
            <Link to="/interview-module">Reschedule</Link>
          </div>
        </Modal>
      )}
    </>
  );
};

export default InteviewReshedule;
