import { create } from "zustand";
// @ts-ignore
import instance from "../api/instance";

// Define the types for the store state
interface SpeechToTextStoreState {
  transcript: string;
  isLoading: boolean;
  error: string | null;
  transcribeAudio: (audioBase64: string, token: string) => Promise<void>;
  clearTranscript: () => void;
}

// Create the Zustand store with type annotations
const speechToTextStore = create<SpeechToTextStoreState>((set) => ({
  transcript: "",
  isLoading: false,
  error: null,

  transcribeAudio: async (audioBase64: string, token: string) => {
    set({ isLoading: true, error: null });
    try {
      const response = await instance.post(
        "/ai/transcribe_base64/",
        {
          audio_data: audioBase64,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      set({
        transcript: response.data.transcript,
        isLoading: false,
      });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

  clearTranscript: () => set({ transcript: "", error: null }),
}));

export default speechToTextStore;