import { marked } from "marked";
import React from "react";
import { useSelector } from "react-redux";

import {
  employmentType,
  mode_of_work,
  yearsOfExperience,
} from "../../../../utils/constants";

const JobDetailsForm4 = () => {
  const jobDetails = useSelector((state) => state.job.details);

  const getEmploymentType = (key) => {
    const type = employmentType[key] || "Unknown";
    return type.charAt(0).toUpperCase() + type.slice(1);
   };

  const getEmploymentYear = (key) => {
    return yearsOfExperience[key] || "Unknown";
  };
  const getModeOfWork = (key) => {
    return mode_of_work[key] || "Unknown";
  };

  const renderMarkdown = (description) => {
    if (description) {
      return { __html: marked(description) };
    }
    return { __html: "" };
  };

  return (
    <>
      <section className="form_sec_new_inner_first">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="soclia_meid_main_sec">
              <div className="soclia_meid_main_sec_head">
                <h6>{jobDetails?.title}</h6>
                {/* <button className="ai-generated-edit-btn">
                  <i class="fas fa-pencil-alt"></i>Edit
                </button> */}
              </div>
              <div className="soclia_meid_main_sec_body">
                <div className="soclia_meid_main_sec_body_single">
                  <ul className="list_top_sec_inner">
                    <li>{jobDetails?.location}</li>
                    <li>{getModeOfWork(jobDetails?.mode_of_work)}</li>
                    <li>{getEmploymentType(jobDetails?.employment_type)}</li>
                    <li>
                      {getEmploymentYear(jobDetails?.experience)} Year
                      Experience
                    </li>
                  </ul>
                </div>
                <div className="soclia_meid_main_sec_body_single">
                  <div
                    dangerouslySetInnerHTML={renderMarkdown(
                      jobDetails?.description
                    )}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JobDetailsForm4;
