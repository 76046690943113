import { create } from "zustand";
import axios from "axios";

interface GeolocationState {
  country: string | null;
  error: string | null;
  fetchLocation: (latitude: number, longitude: number) => Promise<void>;
}

const useGeolocationStore = create<GeolocationState>((set) => ({
  country: null,
  error: null,
  fetchLocation: async (latitude: number, longitude: number) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`
      );
      if (response.data.status === "OK") {
        const results = response.data.results;
        const countryComponent = results[0].address_components.find(
          (component: any) => component.types.includes("country")
        );
        if (countryComponent) {
          set({ country: countryComponent.long_name, error: null });
        } else {
          set({ error: "Country not found", country: null });
        }
      } else {
        set({ error: "Unable to retrieve location", country: null });
      }
    } catch (error: any) {
      set({ error: error.message, country: null });
    }
  },
}));

export default useGeolocationStore;
