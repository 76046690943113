import { Link, useNavigate } from "react-router-dom";
import {
  ai_description,
  ai_hiring1,
  ai_interview1,
  elvt_1,
  elvt_2,
  elvt_3,
} from "../../../assets/images/images";
import "../../Home/home.css";
import "../../Home/home_responsive.css";
import { useSelector } from "react-redux";

const AIEnhance = () => {
  const subscriptions = useSelector((state) => state.subscriptions);
  const handleDemoClick = (event) => {
    event.preventDefault();
    window.open(subscriptions?.zoho_link, '_blank'); 
  };

  return (
    <section className="ai_enhance_sec_new">
      <div className="container-fluid">
        <div className="col-lg-12 col-md-12">
          <div className="ai_enhance_sec_new_inner">
            <div className="ai_enhance_sec_new_inner_head">
              <h2
                className="ai_new wow animate__fadeInLeft"
                data-wow-duration="4s"
              >
                Why Hire with <span>Haire</span>?
              </h2>
              <h5 className="animate__fadeInRight" data-wow-duration="4.5s">
                <span>AI Enhanced Features: </span>Elevate Your Experience with
                AI
              </h5>
            </div>
            <div className="ai_enhance_sec_new_inner_body">
              <div
                className="ai_enhance_sec_new_inner_body_single wow fadeInDown"
                data-wow-duration="4.5s"
              >
                <div className="up_img_sec">
                  <img src={ai_hiring1} alt="" />
                </div>
                <h5>AI Hiring</h5>
                <p>
                  Match candidates with the most relevant positions using AI
                  intelligence.
                </p>
              </div>
              <div
                className="ai_enhance_sec_new_inner_body_single wow fadeInDown"
                data-wow-duration="4.5s"
              >
                <div className="up_img_sec">
                  <img src={ai_description} alt="" />
                </div>
                <h5>AI Description</h5>
                <p>
                Effortlessly create precise, tailored job descriptions with our AI technology.
                </p>
              </div>

              <div
                className="ai_enhance_sec_new_inner_body_single wow fadeInDown"
                data-wow-duration="4.5s"
              >
                <div className="up_img_sec">
                  <img src={ai_interview1} alt="" />
                </div>
                <h5>AI Interviews</h5>
                <p>
                  Efficiently match candidates to opportunities with AI-driven screening.
                </p>
              </div>
            </div>
            <div className="ai_enhave_btn_sec">
              <button
                className="try-button animate__zoomOutUp Ready-btn"
                data-wow-duration="5.5s"
                onClick={handleDemoClick}
              >
                Request a demo
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AIEnhance;
