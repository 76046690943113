import { useLocation, useNavigate } from "react-router-dom";
import { payment_fail } from "../../../../../../assets/images/images";
import { Navbar } from "../../../../../../components/DashboardComponents/Navbar/Navbar";
import "../../MonitizationPage.css";

const PaymentFailed = ({ addPayment }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paymentId = searchParams.get("paymentId");
  const paymentDate = searchParams.get("paymentDate");

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleClickBack = () => {
    navigate("/dashboard/subscription", { replace: true });
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <section className="payment_failed">
              <div>
                <img src={payment_fail} alt="payment failed" />
              </div>
              <div className="payment_failed_head">
                <h4>Payment Failed</h4>
                <p>
                  Unfortunately, your transaction has been failed. We recommend
                  contacting your bank or financial institution for further
                  details and assistance.
                </p>
                {paymentId && paymentDate && (
                  <p className="payment_id">
                    Payment ID: {paymentId}, {formatDate(paymentDate)}
                  </p>
                )}
                <div className="btn-container">
                  <button onClick={handleClickBack} className="go_back">
                    Go Back
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentFailed;
