import { DownOutlined, InfoCircleOutlined } from "@ant-design/icons";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Modal, Table, Tabs } from "antd";
import { marked } from "marked";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  Bag,
  Dw,
  Up,
  alert_terminated,
  clock_icon,
  doc_icon,
  download_icon,
  draft_line,
  dummy_image,
  loacation_icon,
  transcript_img
} from "../../../../assets/images/images";
import DashboardHeader from "../../../../components/CommonComponents/DashboardHeader";


import {
  calculateTimeSpan,
  formatDateTimeToLocalWithOffset,
  formatDurationTime,
  isEmpty
} from "../../../../utils/utils";
import SkeletonLoader from "../CommonRecruiterComponant/SkeletonLoader";
import "../JobDetails/JobDetails.css";

import { ThreeCircles } from "react-loader-spinner";
import TableSpan from "../../../../components/CommonComponents/TableSpan";
import { assesmentDemoPdf } from "../../../../utils/pdfFiles/demoAssessmentPdf";
import { generateDemoPdf } from "../../../../utils/pdfFiles/demoTranscriptPdf";
import "./ViewJobsData.css";

const getSuffix = (filterName, filters, maxCount) => (
  <>
    <span>
      {filters[filterName]?.length || 0} / {maxCount}
    </span>
    <DownOutlined />
  </>
);

const ViewDemoJob = () => {
  const MAX_COUNT = 1;
  const { jobId } = useParams();
  const dispatch = useDispatch();
  const transcripts = useSelector(
    (state) => state?.job?.demoJobsList?.transcripts
  );
  const assessment = useSelector(
    (state) => state?.job?.demoJobsList?.assessment?.data
  );
  const demoJobsInfoList = useSelector((state) => state?.job?.demoJobsList);
  const demoJobsInfo = useSelector(
    (state) => state?.job?.demoJobsApplicantsList
  );
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [isTranscriptModalOpen, setIsTranscriptModalOpen] = useState(false);
  const [selectedTranscript, setSelectedTranscript] = useState(null);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDownButton, setShowDownButton] = useState(true);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  /////////////////////////////Filters///////////////////////////////

  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [activeTab, setActiveTab] = useState("1");
  const companyDetails = useSelector((state) => state?.company?.details);
  ////////////////////////////////useSelectors///////////////////////////////////////////
  const jobApplicants = [
    {
      key: "1",
      user_full_name: demoJobsInfo?.full_name,
      notice_period: "-",
      latest_employment: demoJobsInfo?.company,
      current_ctc: "",
      currency: "-",
      address: "-",
      resume: "-",
      status: "DEMO",
      transcript: "-",
      id: 1,
    },
  ];
  const jobsDetails = useSelector((state) => state.job?.details);
  const jobsCount = useSelector((state) => state.job.jobsCount);
  const token = useSelector((state) => state.auth.token);
  // const applicant =jobApplicants && jobApplicants.length > 0 ? jobApplicants[1] : null;

  ////////////////////////////Navigation events////////////////////////////////////////////////////////
  const handleBackButtonClick = () => {
    navigate("/dashboard/my-jobs/myJobs", { replace: true });
  };

  ////////////////////////////Toggle functionality events/////////////////////////////////////////////////////////
  const toggleAccordion = () => {
    setOpen(!open);
  };

  const handleTranscriptModalCancel = () => {
    setIsTranscriptModalOpen(false);
  };

  const handleDownButtonClick = () => {
    toggleAccordion();
    setShowDownButton(false);
  };
  const handleUpButtonClick = () => {
    toggleAccordion();
    setShowDownButton(true);
  };

  ///////////////////////////Text rendering events//////////////////////////////////////////////////////
  const capitalizeFirstLetter = (string) => {
    if (typeof string !== "string" || !string) {
      return "";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const renderMarkdown = (description) => {
    if (description) {
      return { __html: marked(description) };
    }
    return { __html: "" };
  };

  ///////////////////////////////////////filters and pagination///////////////////////////////////////////////
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleViewTranscriptAndAssessment = () => {
    setIsTranscriptModalOpen(true);

    // setSelectedTranscript(demoJobsInfo);
    // setSelectedAssessment(demoJobsInfo);
    // setSelectedApplicant(demoJobsInfo);
  };

  const handleDownloadPdf = async () => {
    if (transcripts && demoJobsInfo && demoJobsInfoList) {
      setLoadingPdf(true); // Set loading state to true before generating PDF
      await generateDemoPdf(transcripts, demoJobsInfo, demoJobsInfoList); // Wait for the PDF generation to complete
      setLoadingPdf(false); // Set loading state to false after generating PDF
    } else {
      console.error("Selected transcript or applicant is undefined");
    }
  };

  const handleDownloadAssessmentPdf = async (
    assessment,
    demoJobsInfoList,
    demoJobsInfo
  ) => {
    if (assessment && demoJobsInfoList && demoJobsInfo) {
      setLoadingPdf(true); // Set loading state to true before generating PDF
      await assesmentDemoPdf(assessment, demoJobsInfoList, demoJobsInfo); // Wait for the PDF generation to complete
      setLoadingPdf(false); // Set loading state to false after generating PDF
    } else {
      console.error("Selected assessment or applicant is undefined");
    }
  };

  ///////////////////////////////////////Useffects////////////////////////////////////////////////

  useEffect(() => {
    const contentContainers = document.querySelectorAll(
      ".Transcript_main_action"
    );
    contentContainers.forEach((container) => {
      if (container) {
        container.scrollTop = 0;
      }
    });
  }, [activeTab]);

  const columns = [
    {
      title: "Name",
      dataIndex: "user_full_name",
      key: "user_full_name",
      render: (text) => <div data-th="Name">{text || "-"}</div>,
    },
    {
      title: " Resignation Notice",
      dataIndex: "notice_period",
      key: "notice_period",
      render: (text) => <div data-th="Resignation Notice">{text || "-"}</div>,
    },
    {
      title: "Current Company",
      dataIndex: "latest_employment",
      key: "latest_employment",
      render: (text) => <div data-th="Current Company">{text || "-"}</div>,
    },
    {
      title: "Annual Compensation",
      dataIndex: "current_ctc",
      key: "current_ctc",
      render: (text, record) => {
        const currency = record.currency || "";
        const formattedCurrency = text ? `${currency} ${text}` : "-";

        return <div data-th="Annual Compensation">{formattedCurrency}</div>;
      },
    },

    {
      title: "Location",
      dataIndex: "address",
      key: "address",
      render: (text) => <div data-th="Location">{text || "-"}</div>,
    },
    {
      title: "Resume",
      dataIndex: "resume",
      key: "resume",
      render: (text) => <div data-th="Resume">{text || "-"}</div>,
    },
    {
      title: "Result",
      dataIndex: "Transcript",
      key: "Transcript",
      render: () => (
        <div data-th="Result">
          <img
            src={transcript_img} // Replace 'transcript_icon.png' with the path to your transcript icon image
            alt="View Transcript"
            className="transcript_icon"
            onClick={handleViewTranscriptAndAssessment}
            style={{ cursor: "pointer" }} // Set cursor to pointer to indicate clickability
          />
        </div>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => (
        <div data-th="Status">
          <TableSpan status={status} />
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="view_jobs_min_sec_data">
        <DashboardHeader title="" backButtonLink={handleBackButtonClick} />

        <div className="company_details_sec_banner_main">
          <div className="job_banner_demo">
            <div style={{ display: "flex", gap: "8px" }}>
              <InfoCircleOutlined style={{ marginLeft: 5 }} />
              <p>Important Note </p>
            </div>
            <hr />
            <ul>
              <li>
                This job description is a dummy profile generated by our AI
                based on typical job profile. It is designed to give you an
                accurate preview of the demo interview experience from the
                perspective of a job seeker.
              </li>
            </ul>
          </div>
        </div>

        <div className="row new_comp_sec_data_sec">
          <section className="company_details_section ">
            <div className="accordion" id="company_main_container">
              <div className="accordion-item">
                <div className="company_detail_main">
                  <div className="company_logo">
                    <img
                      src={
                        demoJobsInfo?.job_details?.company
                          ?.company_profile_pic || dummy_image
                      }
                      alt=""
                    />
                  </div>
                  <div className="d-flex baseline_align">
                    <div className="me-3"></div>
                  </div>
                </div>
                <h2 className="headingOne" id="headingOne">
                  {demoJobsInfo?.job_profile} -{" "}
                  <span> {demoJobsInfo?.company}</span>
                </h2>
                <div className="d-flex">
                  <div className="doc_file">
                    <img src={draft_line} alt="" />
                  </div>
                  <div
                    className="comp_data"
                    dangerouslySetInnerHTML={{
                      __html: marked(
                        demoJobsInfo?.job_details?.company?.company_overview ||
                          ""
                      ),
                    }}
                  ></div>
                </div>
                <div className="company_address">
                  <span className="job_des">
                    <img src={Bag} alt="" /> NA
                  </span>
                  <span className="job_des">
                    <img src={loacation_icon} alt="" /> NA
                  </span>
                  <span className="job_des">
                    <img src={doc_icon} alt="" />
                    NA
                  </span>
                </div>

                <button
                  className={`accordion-button down_button ${
                    showDownButton ? "" : "d-none"
                  }`}
                  onClick={handleDownButtonClick}
                  aria-expanded={open}
                  aria-controls="collapseOne"
                >
                  <img src={Dw} alt="" />
                  <span
                    style={{
                      color: "#1e1d21",
                      fontWeight: "normal",
                      marginLeft: "10px",
                    }}
                  >
                    {open ? "View Less" : "View More"}
                  </span>
                </button>

                <div
                  id="collapseOne"
                  className={`accordion-collapse collapse ${
                    open ? "show" : ""
                  }`}
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="job_details_main soclia_meid_main_sec_body_single">
                    <div
                      className="job_dm"
                      dangerouslySetInnerHTML={renderMarkdown(
                        demoJobsInfo?.description
                      )}
                    ></div>
                    <div>
                      <button
                        className="accordion-button up_button"
                        onClick={handleUpButtonClick}
                        aria-expanded={open}
                        aria-controls="collapseOne"
                      >
                        <img src={Up} alt="" />
                        <span
                          style={{
                            color: "#1e1d21",
                            fontWeight: "normal",
                            marginLeft: "10px",
                          }}
                        >
                          {open ? "View Less" : "View More"}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/*---------------------------------------------------- Drawer------------------------------------------------------------------------------------- */}

        <>
          <div>
            {isLoadingTable ? (
              <SkeletonLoader
                excludeDetails={true}
                includeTable={true}
                tableRows={5}
                columnWidths={["25%", "20%", "20%", "15%", "10%", "10%"]}
              />
            ) : (
              <Table
                columns={columns}
                dataSource={jobApplicants}
                pagination={{
                  pageSize: 12,
                  total: jobsCount,
                  current: currentPage,
                  onChange: handlePageChange,
                  showSizeChanger: false,
                }}
              />
            )}
          </div>
        </>
      </div>
      <Modal
        visible={isTranscriptModalOpen}
        onCancel={handleTranscriptModalCancel}
        width={1200}
        footer={null}
        maskClosable={false}
        centered
      >
        <div className="custom_modal_body">
          <div className="Transcript_conatiner">
            <Tabs
              className="tablists"
              size={"large"}
              tabPosition={"left"}
              onChange={(key) => setActiveTab(key)}
              items={[
                {
                  label: "Profile",
                  key: "1",
                  children: (
                    <div className="Transcript_main">
                      <div className="profile-header">
                        <>
                          <div className="profile_name">
                            Profile: {demoJobsInfo?.full_name}
                          </div>
                          <div className="resumePdf">No Resume available</div>
                        </>
                      </div>
                      <span className="bold_text">
                        Current Annual Compensation
                      </span>
                      <div className="profile-section profile-ctc">
                        <div className="profile-ctc-value">NA</div>
                      </div>
                      <div>
                        <strong className="bold_text">
                          Annual Compensation Breakdown
                        </strong>
                        <div className="profile-section profile_base">NA</div>
                      </div>
                      <div>
                        <strong className="bold_text">
                          Assistance Benefits
                        </strong>
                        <div className="profile-section profile_base">NA</div>
                      </div>
                      <strong className="bold_text">Resignation Notice</strong>
                      <div className="profile-section profile-notice">NA</div>
                    </div>
                  ),
                },
                {
                  label: "Transcript",
                  key: "2",
                  children: (
                    <div className="Transcript_main">
                      <div className="Transcript_main_pd">
                        <div className="transcript_header">
                          <div className="transcript_header_sub">
                            <h5>
                              Interview Transcription :{" "}
                              {demoJobsInfo?.job_profile} Position
                            </h5>
                            <h6>
                              Duration:{" "}
                              {calculateTimeSpan(
                                demoJobsInfoList?.interview?.start_time,
                                demoJobsInfoList?.interview?.ended_at
                              )}{" "}
                              min
                            </h6>
                          </div>
                          <button
                            className="transcript_download_button"
                            onClick={handleDownloadPdf}
                          >
                            {loadingPdf ? ( // Show spinner if loadingPdf is true
                              <ThreeCircles
                                color="#1865c1"
                                height={20}
                                width={20}
                              />
                            ) : (
                              <img src={download_icon} alt="" />
                            )}
                          </button>
                        </div>
                        <strong className="strong_pd">
                          Timestamp:{" "}
                          {demoJobsInfoList?.interview?.date &&
                          demoJobsInfoList?.interview?.start_time
                            ? formatDateTimeToLocalWithOffset(
                                demoJobsInfoList?.interview?.date,
                                demoJobsInfoList?.interview?.start_time
                              )
                            : "Timestamp unknown"}
                        </strong>
                        {demoJobsInfoList?.interview?.tab_switch_count >= 3 && (
                          <p className="terminated">
                            <img src={alert_terminated} alt="termitated" />{" "}
                            Interview Terminated
                          </p>
                        )}
                      </div>
                      <hr />
                      {demoJobsInfoList.transcripts && (
                        <div className="Transcript_main_action">
                          {[...demoJobsInfoList.transcripts]
                            .reverse()
                            .map((item, index, arr) => (
                              <div key={index}>
                                {index === arr.length - 1 ? (
                                  <p className="trans_question">
                                    <strong>
                                      Question {index + 1} : Hairo AI
                                    </strong>{" "}
                                    <br />
                                    {item?.ai_question}
                                  </p>
                                ) : (
                                  <>
                                    <p className="trans_question">
                                      <strong>
                                        Question {index + 1} : Hairo AI
                                      </strong>{" "}
                                      <br />
                                      {item?.ai_question}
                                    </p>
                                    <p className="trans_answer">
                                      <strong className="strong_answer">
                                        Answer : {demoJobsInfo?.full_name}{" "}
                                        {item?.answer_duration && (
                                          <>
                                            {" "}
                                            | <img
                                              src={clock_icon}
                                              alt=""
                                            />{" "}
                                            {formatDurationTime(
                                              item?.answer_duration
                                            )}
                                          </>
                                        )}
                                        <br />
                                      </strong>{" "}
                                      {item?.candidate_answer}
                                      {/* {item.candidate_audio && (
                                        <AudioPlayer
                                          url={item.candidate_audio}
                                          isPlaying={
                                            activeAudioIndex ===
                                            `answer-${index}`
                                          }
                                          onTogglePlay={() =>
                                            handleTogglePlay(`answer-${index}`)
                                          }
                                        />
                                      )} */}
                                    </p>
                                    <p className="trans_interpretation">
                                      <div className="trans_interpretation_inner">
                                        <strong>
                                          AI interpretation :
                                          <br />
                                        </strong>{" "}
                                        {isEmpty(companyDetails) && (
                                          <span>
                                            (Note: Complete your company details
                                            to view the AI interpretation.)
                                          </span>
                                        )}
                                      </div>
                                      <p
                                        className={
                                          isEmpty(companyDetails)
                                            ? "blur-text"
                                            : ""
                                        }
                                      >
                                        {item?.ai_interpretation}
                                      </p>
                                      {/* <p className="blur-text">{item?.ai_interpretation}</p>  */}
                                    </p>
                                    <hr className="mr-left" />
                                  </>
                                )}
                              </div>
                            ))}
                          {selectedApplicant?.interview_details
                            ?.tab_switch_count >= 3 && (
                            <div className="termineted_section">
                              <h4 className="terminated">
                                Interview Terminated
                              </h4>
                              <p className="terminated_p">
                                The candidate's interview was terminated due to
                                frequently being distracted by switching tabs,
                                even after several reminders.
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ),
                },
                {
                  label: "Assessment",
                  key: "3",
                  children: (
                    <div className="Transcript_main">
                      <div className="Transcript_main_pd">
                        <div className="transcript_header">
                          <div className="transcript_header_sub">
                            <h5>
                              Interview Assesment : {demoJobsInfo?.job_profile}{" "}
                              Position
                            </h5>
                            <h6>
                              Duration:{" "}
                              {calculateTimeSpan(
                                demoJobsInfoList?.interview?.start_time,
                                demoJobsInfoList?.interview?.ended_at
                              )}{" "}
                              min
                            </h6>
                          </div>
                          <button
                            className="transcript_download_button"
                            onClick={() =>
                              handleDownloadAssessmentPdf(
                                assessment,
                                demoJobsInfoList,
                                demoJobsInfo
                              )
                            }
                          >
                            {loadingPdf ? ( // Show spinner if loadingPdf is true
                              <ThreeCircles
                                color="#1865c1"
                                height={20}
                                width={20}
                              />
                            ) : (
                              <img src={download_icon} alt="" />
                            )}
                          </button>
                        </div>
                        <strong className="strong_pd">
                          Timestamp:{" "}
                          {demoJobsInfoList?.interview?.date &&
                          demoJobsInfoList?.interview?.start_time
                            ? formatDateTimeToLocalWithOffset(
                                demoJobsInfoList?.interview?.date,
                                demoJobsInfoList?.interview?.start_time
                              )
                            : "Timestamp unknown"}
                        </strong>
                      </div>
                      <hr />

                      <div className="Transcript_main_action">
                        <ul className="Transcript_page">
                          <li className="trans_question">
                            <span>Problem Solving:</span>
                            <span className="rating_section">
                              {" "}
                              Rating : {assessment?.problem_solving?.rating}
                              /10
                            </span>{" "}
                            <br />
                            {assessment.problem_solving.description}
                          </li>
                          <li className="trans_question">
                            <span>Behavioral skills:</span>
                            <span className="rating_section">
                              {" "}
                              Rating : {assessment.behavioral_skills?.rating}
                              /10
                            </span>{" "}
                            <br />
                            {assessment?.behavioral_skills?.description}
                          </li>
                          <li className="trans_question">
                            <span>Communication Skills:</span>
                            <span className="rating_section">
                              {" "}
                              Rating :{" "}
                              {assessment?.communication_skills?.rating}
                              /10
                            </span>{" "}
                            <br />{" "}
                            {assessment?.communication_skills?.description}
                          </li>
                          <li className="trans_question">
                            <span>Technical Skills:</span>
                            <span className="rating_section">
                              {" "}
                              Rating : {assessment?.technical_skills?.rating}
                              /10
                            </span>{" "}
                            <br />
                            {assessment?.technical_skills?.description}
                          </li>
                          <li className="trans_question">
                            <span>Overall Rating:</span>
                            <span className="rating_section">
                              {" "}
                              Rating : {assessment?.overall_rating}/10
                            </span>{" "}
                            <br />
                            {assessment?.overall_impression}
                          </li>
                        </ul>
                      </div>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewDemoJob;

const renderStatusTag = (status = "DEMO") => {
  let color, textColor, text;

  switch (status) {
    case "DEMO":
      color = "rgb(36 67 177 / 26%)";
      textColor = "#1865c1";
      text = "DEMO";
      break;
    default:
      color = "grey";
      text = status.toUpperCase();
  }

  return (
    <span
      style={{
        backgroundColor: color,
        color: textColor,
        fontSize: "12px",
        fontWeight: "600",
        padding: "4px 25px",
        borderRadius: "4px",
        display: "inline-block",
        width: "105px",
      }}
    >
      {text}
    </span>
  );
};
