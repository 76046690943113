import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PaymentHistoryItem,
  PaymentHistoryState,
} from "../types/paymentsTypes";

const initialState: PaymentHistoryState = {
  loading: false,
  history: [],
  error: null,
};

const paymentsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    // Define a reducer and automatically generate its action
    setPaymentHistory(
      state: PaymentHistoryState,
      action: PayloadAction<PaymentHistoryItem[]>
    ) {
      state.history = action.payload;
    },
    setPaymentHistoryLoading: (
      state: PaymentHistoryState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
    setPaymentHistoryError: (
      state: PaymentHistoryState,
      action: PayloadAction<string>
    ) => {
      state.error = action.payload;
    },
  },
});

// Export the automatically generated action creators
export const {
  setPaymentHistory,
  setPaymentHistoryLoading,
  setPaymentHistoryError,
} = paymentsSlice.actions;

// Export the reducer
export default paymentsSlice.reducer;
