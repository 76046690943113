import React from "react";
import "./commonComponant.css";
import { Progress } from "antd";
import { GenerateAi } from "../../assets/images/images";
const GenerateWithAi = ({ progress }) => {
  return (
    <section className="Generate_with_ai_section">
      <div className="Generate_with_ai_main">
        <img src={GenerateAi} alt="Generator Ai image" />
        <p>Generating with AI</p>
        <div className="progress-wd">
          <Progress percent={progress} />
        </div>
      </div>
    </section>
  );
};

export default GenerateWithAi;
