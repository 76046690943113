import { Dispatch } from "redux";
import {
  setCount,
  setCountError,
  setCountLoading,
} from "../reducers/dashboardCountReducer";
// @ts-ignore
import instance from "../../api/instance";

export const fetchCount = (token: string) => async (dispatch: Dispatch) => {
  dispatch(setCountLoading(true));
  try {
    const response = await instance.get("/job/company/dashboard-counts/", {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    if (response.status === 200) {
      dispatch(setCount(response.data.data));
    } else {
      throw new Error(`API responded with status: ${response.status}`);
    }
  } catch (error: any) {
    dispatch(setCountError("Failed to fetch Count: " + error.message)); // setJobError action for error handling
  } finally {
    dispatch(setCountLoading(false));
  }
};
