import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const getPath = (role, job_seeker_id) => {
  switch (role) {
    case 1:
      return "/dashboard";
    case 2:
      return job_seeker_id ? "/applicant-dashboard" : "/resume-builder";
    default:
      return "/user-role";
  }
};

function CheckRoute({ component: Component }) {
  const authToken = useSelector((state) => state.auth.token);
  const role = useSelector(
    (state) => state.auth?.user?.role ?? state.auth?.user?.user_role
  );
  const job_seeker_id = useSelector(
    (state) => state.auth.user?.job_seeker_id ?? state.auth?.jobseeker_id
  );
  const redirectUrl = useSelector((state) => state?.auth?.redirectUrl);
  const profile_completed = useSelector(
    (state) => state?.auth?.profile_completed
  );
  const isNewUser = useSelector((state) => state?.auth?.isNewUser);
  if (isNewUser) {
    if (authToken && profile_completed && redirectUrl) {
      return <Navigate to={redirectUrl} replace />;
    }
  } else {
    if (authToken && redirectUrl) {
      return <Navigate to={redirectUrl} replace />;
    }
  }
  if (authToken) {
    return (
      <Navigate
        to={role ? getPath(role, job_seeker_id) : "/user-role"}
        replace
      />
    );
  }
  return <Component />;
}

export default CheckRoute;
