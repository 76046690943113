import { FormHelperText } from "@mui/material";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ThreeCircles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CustomOtpInput } from "../../components/CustomComponents/CustomOtpInput";
import "./LoginOtpVerificationModal.css";
import loginCodeStore from "../../zustand/loginCodeStore";

const LoginCodeModal = ({ timer, setTimer, startTimer }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
        setValue,
        reset
    } = useForm();
    const {
        formData,
        verifyLoginOtp,
        resendLoginOtp,
        closeModal,
        loading,
        modalOpen,
    } = loginCodeStore();

    const redirectUrl = useSelector((state) => state.auth.redirectUrl);

    const handleVerifyClick = async (data) => {
        const { otp } = data;

        if (!otp || otp.length !== 6) {
            toast.error("Please enter a valid 6-digit OTP");
            return;
        }

        const verified = await verifyLoginOtp(
            formData.email,
            parseInt(otp),
            dispatch,
            navigate,
            redirectUrl
        );
        if (verified) {
            toast.success("Logged in successfully!");
            closeModal();
        } else {
            toast.error("Verification Code is Invalid or Expired");
        }
    };

    const handleResendOtp = async () => {
        resendLoginOtp(formData.email);
        setValue("otp", ""); // Clear the OTP field
        setTimer(60); // Reset the timer to 60 seconds
        startTimer();
    };

    return (
        <Modal
            show={modalOpen}
            onHide={() => { }} // Override the onHide handler to do nothing
            backdrop="static" // Set static to prevent closing by clicking on the backdrop
            keyboard={false} // Disable closing modal by pressing the escape key
            className="verifictaion_mod"
        >
            <h6>Verification Code</h6>
            <p className="verification-modal-subtitle">
                We have send a verification code to{" "}
                <span className="verification-modal-email">{formData.email}</span>
                <br />
                That’s not my email.{" "}
                <span className="verification-modal-cta" onClick={() => { reset(); closeModal(); }}>
                    Change email
                </span>
                .
            </p>
            <form
                onSubmit={handleSubmit(handleVerifyClick)}
                className="enter_otp_box_sec acc_veri"
            >
                <label>Enter Verification Code</label>
                <br />
                <Controller
                    name="otp"
                    control={control}
                    defaultValue=""
                    rules={{ required: true, validate: (value) => value.length === 6 }}
                    render={({ field }) => <CustomOtpInput {...field} length={6} />}
                />
                {errors.otp && (
                    <FormHelperText error>
                        {errors.otp.type === "required"
                            ? "OTP is required"
                            : "OTP must be 6 digits"}
                    </FormHelperText>
                )}
                <br />
                <div className="resend_btn_new">
                    <button
                        type="button"
                        className="resend_otp_btn_new"
                        disabled={timer > 0}
                        onClick={handleResendOtp}
                    >
                        Resend Verification code
                    </button>
                    <span>{timer < 10 ? `00:0${timer}` : `00:${timer}`}</span>
                </div>
                <br />
                <div className="login_btn_blue send_verfy_btn">
                    <button
                        className="blue_btn_links"
                        type="submit"
                        disabled={loading || !isValid}
                    >
                        {loading ? (
                            <ThreeCircles
                                visible={true}
                                height="20"
                                width="20"
                                color="#fff"
                                ariaLabel="loading-indicator"
                            />
                        ) : (
                            "Verify"
                        )}
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default LoginCodeModal;
