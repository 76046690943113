import { useNavigate } from "react-router-dom";
import {
  face,
  haire_footer_logo,
  insta,
  linkedin_ic,
  mail_ic,
  phone_ic,
  twit,
  yout
} from "../../assets/images/images";

const HomeFooterNew = () => {
  const navigate = useNavigate();
  const handleClick = (event) => {
    event.preventDefault();
    navigate("/");
  };
  return (
    <>
      <footer className="footer_sec_pro_rect_new">
        <div className="container-fluid">
          <div className="row d-flex align-items-baseline">
            <div className="col-lg-2 col-md-2">
              <img
                src={haire_footer_logo}
                alt="footer logo"
                width={"100px"}
                onClick={handleClick}
              />
            </div>
            <div className="col-lg-8 col-md-8">
              <div className="footer_main">
                <div className="footer_about_section_new">
                  <ul>
                    <li>
                      <a href="/about-us">About Us</a>
                    </li>
                    <li>
                      <a href="/features">Features</a>
                    </li>
                    <li>
                      <a href="/jobs">Jobs</a>
                    </li>
                    <li>
                      <a href="/contact-us">Contact Us</a>
                    </li>
                    <li>
                      <a href="/Term&Condition">Terms of Service</a>
                    </li>
                    <li>
                      <a href="/PrivacyPolicy">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/RefundPolicy">Refund and Cancellation Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2">
              <div className="social-icons-new">
                <a href="https://www.facebook.com/profile.php?id=61556640899265">
                  <img src={face} alt="footer logo" />
                </a>
                <a href="https://www.instagram.com/haire.ai?igsh=MnNmZnUxbTc0aTk0">
                  <img src={insta} alt="footer logo" />
                </a>
                <a href="https://www.linkedin.com/company/hairedotai">
                  <img src={linkedin_ic} alt="footer logo" />
                </a>
                <a href="https://www.youtube.com/@HaireDotAI">
                  <img src={yout} alt="footer logo" />
                </a>
              </div>
            </div>
          </div>
          <div className="thin-line"></div>
          <div className="footer-container">
            <div className="footer-section footer-left">
              <p>© 2024 haire.ai. All rights reserved</p>
            </div>
            <div className="footer-section footer-right">
              <div className="footer-contacts">
                <div className="footer-contact-item">
                  <img src={phone_ic} alt="call" className="footer-icon" />
                  <span>
                    <a href="tel:+918047187100" className="footer-phone-number">
                      ( +91 ) 080-47187100
                    </a>
                  </span>
                </div>
                <div className="footer-contact-item">
                  <img src={mail_ic} alt="email" className="footer-icon" />
                  <span>
                    <a
                      href="mailto:support@haire.ai"
                      className="footer_new_link"
                    >
                      support@haire.ai
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default HomeFooterNew;
