import { LoadingOutlined } from "@ant-design/icons";
import { image_selector} from "../../assets/images/images";

const UploadButton = ({ loading }) => {
  return (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <img src= {image_selector} alt="" />
      )}
    </div>
  );
};

export default UploadButton;
