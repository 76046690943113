import { useState } from "react";
import axios from "axios";
import instance from "../api/instance";

const useMultipleFileUpload = () => {
  const [uploadStatus, setUploadStatus] = useState({
    loading: false,
    error: null,
  });

  const uploadFiles = async (files, token) => {
    try {
      setUploadStatus({ loading: true, error: null });

      const uploadPromises = files.map(async (file) => {
        // Fetch the pre-signed URL from your backend
        const response = await instance.post(
          "/ai/generate-presigned-url/",
          {
            file_name: file.name,
          },
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const { presigned_url, file_url } = response.data;

        // Upload the file to S3 using the pre-signed URL
        const uploadResponse = await axios.put(presigned_url, file, {
          headers: {
            "Content-Type": file.type,
          },
        });

        if (uploadResponse.status === 200) {
          return file_url;
        } else {
          throw new Error(`Failed to upload file: ${file.name}`);
        }
      });

      const uploadedUrls = await Promise.all(uploadPromises);
      setUploadStatus({ loading: false, error: null });
      return uploadedUrls;
    } catch (error) {
      setUploadStatus({ loading: false, error: error.message });
      return null;
    }
  };

  return { uploadStatus, uploadFiles };
};

export default useMultipleFileUpload;
