import { ReactComponent as CongratulationsIcon } from "../../../../assets/images/thick-right-icn.svg";
import { Link } from "react-router-dom";

export const Congratulations = () => {
  return (
    <>
      <section className="cong_sec_new">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="cong_sec_new_inner">
                <div className="cong_sec_new_inner_head">
                  <div className="cong_sec_new_inner_head_inner">
                    <CongratulationsIcon />
                    <h6>Congratulations! </h6>
                    <p>
                      You have been shortlisted for the next round of
                      interviews.
                    </p>
                  </div>
                </div>
                <h4>
                  Please check your email and phone regularly. Company HR team
                  will contact you soon with more updates, either through email
                  or phone.
                </h4>
                <div className="bavk_jobs">
                  <Link to={"/applicant-dashboard"}>Back to Jobs</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
