import axios from "axios";
import { handleInvalidToken } from "../utils/tokenUtils";

// const localURL = `http://localhost:3000`;
const liveBaseURL = process.env.REACT_APP_BACKEND_APP_URL;
const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL;

export const baseURL = `${liveBaseURL}/`;

const instance = axios.create({
  baseURL,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      handleInvalidToken();
    }
    return Promise.reject(error);
  }
);

export default instance;
export { websocketUrl };
