import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Input, Modal, Spin, Steps } from "antd";
import MuiPhoneNumber from "material-ui-phone-number";
import { useCallback, useEffect, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";
import instance from "../../api/instance";
import { debounceApi } from "../../utils/utils";
import userStore from "../../zustand/userStore";
import "./TryHaire.css";
import TryHaireOtp from "./TryHaireOtp";
import { emailRegex } from "../../utils/constants";

const { Step } = Steps;

const INDUSTRY_OPTIONS = [
  { value: "IT", label: "IT" },
  { value: "Finance", label: "Finance" },
  { value: "Health Care", label: "Healthcare" },
  { value: "Education", label: "Education" },
  { value: "other", label: "Others" }
];

const TryHaire = () => {
  const [modalOpen, setModalOpen] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({
    companyName: "",
    industryType: "",
    jobProfile: "",
    fullName: "",
    email: "",
    phoneNumber: "",
  });
  const [otherIndustry, setOtherIndustry] = useState("");
  const [otpStep, setOtpStep] = useState(false);
  const [finalFormValues, setFinalFormValues] = useState({});
  const [touchedFields, setTouchedFields] = useState({});
  const [phoneError, setPhoneError] = useState("");
  const [timer, setTimer] = useState(60);
  const loading = userStore((state) => state.loading);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const location = useSelector((state) => state?.location?.userCountry);
  const registerWithEmailPassword = userStore(
    (state) => state.registerWithEmailPassword
  );

  // Function to handle industry dropdown change
  const handleIndustryChange = (event) => {
    const selectedValue = event.target.value;
    setFormValues(prev => ({
      ...prev,
      industryType: selectedValue,
      // Clear other industry when switching away from "other"
    }));

    if (selectedValue !== "other") {
      setOtherIndustry("");
      form.setFieldValue('otherIndustry', ''); // Clear the form field value
    }

    setTouchedFields(prev => ({
      ...prev,
      industryType: true
    }));
  };

  // Function to handle other industry input
  const handleOtherIndustryChange = (event) => {
    setOtherIndustry(event.target.value);
  };

  const next = () => {
    if (isStepValid()) {
      setCurrentStep(currentStep + 1);
    }
  };

  const goToFirstStep = () => {
    setCurrentStep(0);
  };

  const goToStep2 = () => {
    setOtpStep(false);
    setModalOpen(true);
    setCurrentStep(1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let trimmedValue = value.trim();
    if (name === "jobProfile" && trimmedValue.length > 40) {
      trimmedValue = trimmedValue.slice(0, 40);
    }

    setFormValues(prev => ({
      ...prev,
      [name]: value
    }));

    setTouchedFields(prev => ({
      ...prev,
      [name]: true
    }));
  };

  const handlePhoneNumberChange = (value) => {
    if (!value) {
      setFormValues(prev => ({ ...prev, phoneNumber: "" }));
      setPhoneError("");
      setIsPhoneValid(false);
    } else if (isValidPhoneNumber(value)) {
      setPhoneError("");
      setFormValues(prev => ({ ...prev, phoneNumber: value }));
      debounceCheckPhoneExists(value);
    } else {
      setPhoneError("Please enter a valid contact number.");
      setIsPhoneValid(false);
    }
  };

  const debounceCheckPhoneExists = useCallback(
    debounceApi(async (phone) => {
      try {
        const response = await instance.post("/accounts/check-phone-exists/", {
          phone: phone,
        });

        if (response.data.message === "User not found.") {
          setPhoneError("");
          setIsPhoneValid(true);
        } else {
          setPhoneError("Phone number already exists. Please use a different number.");
          setIsPhoneValid(false);
        }
      } catch (error) {
        setPhoneError("Phone number already exists. Please use a different number.");
        setIsPhoneValid(false);
      }
    }, 500),
    []
  );

  const validateField = (field, value) => {
    if (field.rules?.some((rule) => rule.required && !value)) {
      return false;
    }

    if (field.rules?.some((rule) => rule.pattern && value && !rule.pattern.test(value))) {
      return false;
    }

    if (["jobProfile", "companyName", "fullName"].includes(field.name) && value.trim() === "") {
      return false;
    }

    return true;
  };

  const isStepValid = () => {
    const currentFields = stepsContent[currentStep].formFields;

    const areFieldsValid = currentFields.every((field) =>
      validateField(field, formValues[field.name])
    );

    if (currentStep === 1) {
      return areFieldsValid && !phoneError && isPhoneValid;
    }

    if (currentStep === 0 && formValues.industryType === "other" && !otherIndustry.trim()) {
      return false;
    }

    return areFieldsValid;
  };

  const handleSubmit = () => {
    const finalValues = {
      ...formValues,
      industryType: formValues.industryType === "other" ? otherIndustry : formValues.industryType,
    };
    setFinalFormValues(finalValues);
    if (isStepValid()) {
      registerWithEmailPassword(finalValues.email, "defaultPassword123", () => {
        setModalOpen(false);
        setOtpStep(true);
        startTimer();
      });
    }
  };

  const startTimer = () => {
    setTimer(60);
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 0) {
          clearInterval(interval);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    isStepValid();
  }, [isPhoneValid]);

  const stepsContent = [
    {
      title: "Step 1",
      header: (
        <>
          <h6 className="mdl-h6">Let's personalize this demo for you.</h6>
          <p className="verification-modal-subtitle mdl-p">
            We have a few questions about your company and hiring needs.
          </p>
        </>
      ),
      formFields: [
        {
          name: "companyName",
          label: "Company Name",
          placeholder: "Enter company name",
          rules: [
            { required: true, message: "Please enter your company name" },
            { max: 60, message: "Company name cannot exceed 60 characters" },
            {
              validator: (_, value) => {
                if (value && value.trim() === "") {
                  return Promise.reject(new Error("Company name cannot be only spaces"));
                }
                return Promise.resolve();
              },
            },
          ],
        },
        {
          name: "industryType",
          label: "Industry Type",
          placeholder: "Select industry type",
          rules: [
            { required: true, message: "Please select an industry type" }
          ],
          type: "select",
        },
        {
          name: "jobProfile",
          label: "Job Profile",
          placeholder: "Enter job profile",
          rules: [
            { required: true, message: "Please enter your job profile" },
            { max: 40, message: "Job profile cannot exceed 40 characters" },
            {
              validator: (_, value) => {
                if (value && value.trim() === "") {
                  return Promise.reject(new Error("Job Profile cannot be only spaces"));
                }
                return Promise.resolve();
              },
            },
          ],
        },
      ],
    },
    {
      title: "Step 2",
      header: (
        <>
          <button className="back-button2" onClick={goToFirstStep}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <h6 className="mdl-h6">Verify information to start demo.</h6>
          <p className="verification-modal-subtitle mdl-p">
            We need to collect a few basic personal details from you.
          </p>
        </>
      ),
      formFields: [
        {
          name: "fullName",
          label: "Full Name",
          placeholder: "Enter full name",
          rules: [
            { required: true, message: "Please enter your full name" },
            {
              pattern: /^[a-zA-Z\s'-]+$/,
              message: "Full name can only contain letters, spaces, hyphens, and apostrophes",
            },
            {
              validator: (_, value) => {
                if (value && value.trim() === "") {
                  return Promise.reject(new Error("Full name cannot be only spaces"));
                }
                return Promise.resolve();
              },
            },
          ],
        },
        {
          name: "email",
          label: "Business Email",
          placeholder: "Enter business email",
          rules: [
            {
              required: true,
              validator: (_, value) => {
                const forbiddenDomains = ["gmail.com", "outlook.com", "yahoo.com"];
                const emailDomain = value?.split("@")[1]?.toLowerCase();

                if (!value) {
                  return Promise.reject(new Error("Please enter your email"));
                }

                if (!emailRegex.test(value)) {
                  return Promise.reject(new Error("Invalid email format"));
                }

                if (forbiddenDomains.includes(emailDomain)) {
                  return Promise.reject(new Error("This is not a Business Email"));
                }

                return Promise.resolve();
              },
            },
          ],
        },
        {
          name: "phoneNumber",
          label: "Contact Number",
          placeholder: "Enter contact number",
          type: "phone",
          rules: [{ required: true, message: "Please enter your contact number" }],
        },
      ],
    },
  ];

  const renderFormField = (field) => {
    if (field.type === "select") {
      return (
        <>
          <Form.Item
            name={field.name}
            rules={[
              { required: true, message: "Please select an industry type" },
            ]}
            className="mb-0"
          >
            <select
              className="custom-select2"
              name={field.name}
              value={formValues.industryType}
              onChange={handleIndustryChange}
            >
              <option value="">{field.placeholder}</option>
              {INDUSTRY_OPTIONS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </Form.Item>
          {formValues.industryType === "other" && (
            <Form.Item
              name="otherIndustry"
              rules={[
                { required: true, message: "Please specify your industry" },
              ]}
              className="mt-2 mb-0"
            >
              <Input
                placeholder="Please specify your industry"
                value={otherIndustry}
                onChange={handleOtherIndustryChange}
                className="mt-2"
              />
            </Form.Item>
          )}
        </>
      );
    }

    if (field.type === "phone") {
      return (
        <>
          <MuiPhoneNumber
            name={field.name}
            variant="filled"
            defaultCountry={location}
            value={formValues.phoneNumber}
            onChange={handlePhoneNumberChange}
            className="custom-phone-input1"
            required
            fullWidth
            InputProps={{
              disableUnderline: true,
            }}
            disabled={loading}
            sx={{
              height: 42,
              "& .MuiInputBase-input": {
                height: "30px",
                padding: "6px 14px",
              },
              "& .MuiFilledInput-root": {
                borderRadius: "4px",
                fontSize: "14px",
                fontWeight: 400,
                fontFamily: "inherit",
                background: "rgba(248, 248, 248, 1)",
              },
            }}
          />
          {phoneError && (
            <div className="ant-form-item-explain-error">
              {phoneError}
            </div>
          )}
        </>
      );
    }

    return (
      <Input
        name={field.name}
        placeholder={field.placeholder}
        value={formValues[field.name]}
        onChange={handleInputChange}
        onBlur={() => setTouchedFields(prev => ({
          ...prev,
          [field.name]: true
        }))}
        maxLength={
          field.name === "jobProfile"
            ? 40
            : field.name === "companyName"
              ? 60
              : field.name === "fullName"
                ? 70
                : undefined
        }
      />
    );
  };

  return (
    <>
      <div className="fullscreen-bg"></div>
      <div className="content">
        <Modal
          open={modalOpen}
          footer={null}
          className="verficiation_mod"
          id="Verficiation_mod"
          closeIcon={null}
          styles={{ height: loading ? "468px" : "auto", overflow: "auto" }}
        >
          {loading ? (
            <div className="loader-cust">
              <Spin size="large" className="modal-loader" />
            </div>
          ) : (
            <>
              {stepsContent[currentStep].header}
              <Steps current={currentStep} className="steps-container">
                {stepsContent.map((step, index) => (
                  <Step key={index} />
                ))}
              </Steps>
              <div className="steps-content">
                <Form layout="vertical" form={form}>
                  {stepsContent[currentStep].formFields.map((field) => (
                    <Form.Item
                      label={field.label}
                      name={field.name}
                      key={field.name}
                      rules={field.rules}
                    >
                      {renderFormField(field)}
                    </Form.Item>
                  ))}
                </Form>
                <div className="steps-action">
                  {currentStep < stepsContent.length - 1 && (
                    <Button
                      type="primary"
                      onClick={next}
                      disabled={!isStepValid()}
                      className={!isStepValid() ? "disabled-btn" : ""}
                    >
                      Next
                    </Button>
                  )}
                  {currentStep === stepsContent.length - 1 && (
                    <Button
                      type="primary"
                      disabled={!isStepValid()}
                      className={!isStepValid() ? "disabled-btn" : ""}
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
        </Modal>
        {otpStep && (
          <TryHaireOtp
            timer={timer}
            setTimer={setTimer}
            startTimer={startTimer}
            formData={finalFormValues}
            closeModal={() => setOtpStep(false)}
            goToStep2={goToStep2}
          />
        )}
      </div>
    </>
  );
};

export default TryHaire;