export const employmentType = {
  1: "full-time",
  2: "part-time",
  3: "remote",
  4: "temporary",
  5: "contract",
  6: "freelancer",
  7: "internship",
};
export const yearsOfExperience = {
  1: "0-1",
  2: "1-2",
  3: "2-3",
  4: "3-4",
  5: "4-5",
  6: "5+",
};

export const seniority_level = {
  1: "entry-level",
  2: "mid-level",
  3: "senior-level",
  4: "director-level",
  5: "executve",
};

export const qualifications = {
  1: "unspecified",
  2: "high school or equivalent",
  3: "diploma",
  4: "bachelors degree",
  5: "masters degree",
  6: "doctorate",
};

export const mode_of_work = {
  1: "On-site",
  2: "Remote",
  3: "Hybrid",
};

export const no_of_employees1 = {
  1: "0-50",
  2: "50-100",
  3: "101-200",
  4: "201-300",
  5: "301-500",
  6: "501-1000+",
};

export const getEmploymentType = (key) => {
  return employmentType[key] || "Unknown";
};

export const getExperience = (key) => {
  return yearsOfExperience[key] || "Unknown";
};

export const getSeniority = (jobDetails, key) => {
  return seniority_level[key] || "Unknown";
};

export const getModeOfWork = (jobDetails, key) => {
  return mode_of_work[key] || "Unknown";
};

export const getQualification = (jobDetails, key) => {
  if (
    jobDetails &&
    jobDetails.qualifications &&
    typeof jobDetails.qualifications[key] !== "undefined"
  ) {
    return jobDetails.qualifications[key];
  }
  return "Unknown";
};

export const zeroCreditError =
  "You don't have enough credits to Post the Job. Please Add Credits to your account.";

export const freeFeatures = [
  "Conduct five AI-guided interviews.",
  "Obtain evaluations for five candidates.",
  "Generate interview transcripts.",
  "Access five candidate profiles and resumes.",
  "List five jobs or allocate interviews to one job.",
];

export const businessFeatures = [
  "Allocate credits to jobs as needed.",
  "Post jobs with AI assistance.",
  "Interview candidates with AI.",
  "Receive AI - generated candidate assessments.",
  "Obtain automated transcripts of interviews.",
  "Access candidate profiles and resumes.",
  "Unlimited user license.",
];

export const enterpriseFeatures = [
  "Allocate credits to jobs as needed.",
  "Post jobs with AI assistance.",
  "Interview candidates with AI.",
  "Receive AI - generated candidate assessments.",
  "Obtain automated transcripts of interviews.",
  "Access candidate profiles and resumes.",
  "Save on credit bulk purchases.",
  "Add on: Extra fee for video recording.",
  "5 Custom questions add on in Ai Interview",
  "Unlimited user license",
];

export const jobStatus = [
  { id: 1, status: "Draft" },
  { id: 2, status: "Published" },
  { id: 3, status: "Closed" },
  { id: 4, status: "Inactive" },
];

export const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/i;
