import { Form, Input } from "antd";
import { CreditCard } from "../../../../../assets/images/images";
import { Modal } from "react-bootstrap";

const AddCardModal = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} className="report_modal_sec">
      <div className="report_modal_sec_inner">
        <div className="report_modal_sec_inner_head">
          <button onClick={onHide}>
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div className="report_modal_sec_inner_body Add_card_modal">
          <h3>Add Card</h3>
          <Form name="billing-form" className="job_details_from billing_form">
            <div className="row">
              <Form.Item
                name="Name on card"
                label={
                  <span>
                    Name on card<span className="required-asterisk">*</span>
                  </span>
                }
                colon={false}
              >
                <Input placeholder="Enter your name" />
              </Form.Item>
              <Form.Item
                name="Card Number"
                label={
                  <span>
                    Card Number<span className="required-asterisk">*</span>
                  </span>
                }
                colon={false}
                className="credit_card_form"
              >
                <Input placeholder="Enter card number" />
                <img src={CreditCard} alt="" className="CreditCard_img" />
              </Form.Item>
            </div>
            <div className="row">
              <div className="col-6 text-start">
                <Form.Item
                  name="Card Expiry "
                  label={
                    <span>
                      Card Expiry<span className="required-asterisk">*</span>
                    </span>
                  }
                  colon={false}
                >
                  <Input placeholder="MM/YY" />
                </Form.Item>
              </div>
              <div className="col-6 text-start ">
                <Form.Item
                  name="CVV"
                  label={
                    <span>
                      CVV<span className="required-asterisk">*</span>
                    </span>
                  }
                  colon={false}
                >
                  {" "}
                  <Input placeholder="CVV" />
                </Form.Item>
              </div>
            </div>
            <p>
              By providing your card information, you allow HeyGen to charge
              your card for future payments in accordance with their terms.
            </p>
          </Form>
          <button className="Add_card_btn">Add Card</button>
        </div>
      </div>
    </Modal>
  );
};

export default AddCardModal;
