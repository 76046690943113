import { createAsyncThunk } from "@reduxjs/toolkit";
import { setUser } from "../reducers/authReducer";
import { AuthUser } from "../types/authTypes";

export const updateUser = createAsyncThunk(
  "auth/updateUser",
  async (user: AuthUser, { dispatch }) => {
    // You can perform any async operation here (e.g., API calls)

    // Once the async operations are done, dispatch setUser
    dispatch(setUser(user));

    // The result here will be available for .then() when the thunk is dispatched
    return user;
  }
);
