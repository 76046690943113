import { FormHelperText } from "@mui/material";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { CustomOtpInput } from "../../components/CustomComponents/CustomOtpInput";
import "../../pages/SignIn/OtpVerificationModal.css";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import userStore from "../../zustand/userStore";
import { useDispatch, useSelector } from "react-redux";
import BookDemoModal from "./BookDemoModal";
import { useState } from "react";
import { Spin } from "antd";

const TryHaireOtp = ({ timer, setTimer, startTimer, formData, goToStep2 }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const subscriptions = useSelector((state) => state.subscriptions);

  const [showVerificationModal, setShowVerificationModal] = useState(true);

  const [showBookDemoModal, setShowBookDemoModal] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const {
    verifyTryOtp,
    resendVerificationOtp,
    closeModal,
    modalOpen,
    loading,
    error,
    userId,
  } = userStore();

  const handleResendOtp = async () => {
    resendVerificationOtp(formData.email);
    setValue("otp", "");
    setTimer(60);
    startTimer();
  };

  const handleVerifyTryOtp = async (data) => {
    const { otp } = data;

    if (!otp || otp.length !== 6) {
      toast.error("Please enter a valid 6-digit OTP");
      return;
    }
    const result = await verifyTryOtp(
      formData.email,
      formData.fullName,
      formData.countryCode,
      parseInt(data.otp),
      formData.phoneNumber,
      formData.companyName,
      formData.industryType,
      formData.jobProfile,
      dispatch,
      navigate,
      subscriptions?.zoho_link
    );

    if (result.verified && !result.allowDemo) {
      setShowVerificationModal(false);
      setTimeout(() => {
        setShowBookDemoModal(true);
        // Save the state in localStorage
      }, 300);
    } else if (result.verified) {
      toast.success("Email verified successfully!");
    } else {
      toast.error("Verification Code is Invalid or Expired");
    }
  };

  return (
    <>
      <Modal
        show={showVerificationModal}
        onHide={() => setShowVerificationModal(false)}
        backdrop="static"
        keyboard={false}
        className="verifictaion_mod"
      >
        <h6>Verification Code</h6>
        <p className="verification-modal-subtitle">
          We have send a verification code to{" "}
          <span className="verification-modal-email">{formData.email}</span>
          <br />
          That’s not my email.{" "}
          <span onClick={goToStep2} className="verification-modal-cta">
            Change email
          </span>
          .
        </p>
        <form
          onSubmit={handleSubmit(handleVerifyTryOtp)}
          className="enter_otp_box_sec acc_veri"
        >
          <label>Enter Verification Code</label>
          <br />
          <Controller
            name="otp"
            control={control}
            defaultValue=""
            rules={{ required: true, validate: (value) => value.length === 6 }}
            render={({ field }) => <CustomOtpInput {...field} length={6} />}
          />
          {errors.otp && (
            <FormHelperText error>
              {errors.otp.type === "required"
                ? "OTP is required"
                : "OTP must be 6 digits"}
            </FormHelperText>
          )}
          <br />
          <div className="resend_btn_new">
            <button
              type="button"
              className="resend_otp_btn_new"
              disabled={timer > 0}
              onClick={handleResendOtp}
            >
              Resend Verification code
            </button>
            <span>{timer < 10 ? `00:0${timer}` : `00:${timer}`}</span>
          </div>
          <br />
          <div className="login_btn_blue send_verfy_btn">
            <button
              disabled={loading}
              className={
                loading ? "blue_btn_links disabled-btn" : "blue_btn_links"
              }
              type="submit"
            >
              {loading ? <Spin /> : "Verify"}
            </button>
          </div>
        </form>
      </Modal>
      <BookDemoModal
        show={showBookDemoModal}
        handleClose={() => false}
        zohoLink={subscriptions?.zoho_link}
      />
    </>
  );
};

export default TryHaireOtp;
