import { useState, useEffect } from 'react';

function useResponsiveCount() {
  const [count, setCount] = useState(getResponsiveCount());

  useEffect(() => {
    // alert(`The number of cards displayed will be: ${count}`);
    function handleResize() {
      setCount(getResponsiveCount());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function getResponsiveCount() {
    const width = window.innerWidth;
    if (width >= 1900) return 4;  // Big screens
  else if (width >= 1200) return 3;  // Small screens
  else if (width >= 700) return 2;  // Tablets
  else return 1;   // Large screens
  }

  return count;
}
export default useResponsiveCount;