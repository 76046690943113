import { Dispatch } from "redux";
// @ts-ignore
import instance from "../../api/instance";
import {
  CompanyState,
  setCompanyError,
  setCompanyLoading,
  updateCompanyDetails,
} from "../reducers/companyReducer";

export const createCompany =
  (token: string, companyDetails: CompanyState, navigate: Function) =>
  async (dispatch: Dispatch) => {
    dispatch(setCompanyLoading(true));
    try {
      const response = await instance.post(
        "/company/create_company/",
        companyDetails,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const company = {
          ...companyDetails,
          id: response.data.data.id,
          user: response.data.data.user,
          profile_pic: response.data.data.profile_pic,
        };
        dispatch(updateCompanyDetails(company));
        navigate("/dashboard");
        dispatch(setCompanyLoading(false));
      } else {
        throw new Error(`API responded with status: ${response.status}`);
      }
    } catch (error: any) {
      dispatch(
        setCompanyError("Failed to create company details: " + error.message)
      );
      dispatch(setCompanyLoading(false));
    } finally {
      dispatch(setCompanyLoading(false));
    }
  };

export const updateCompany =
  (
    token: string,
    companyDetails: CompanyState,
    company_id: number,
    navigate: Function
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setCompanyLoading(true));
    try {
      const response = await instance.put(
        `company/update-company/${company_id}/`,
        companyDetails,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const company = {
          ...companyDetails,
          id: response.data.data.id,
          user: response.data.data.user,
          profile_pic: response.data.data.profile_pic,
        };
        dispatch(updateCompanyDetails(company));
        navigate("/dashboard");
        dispatch(setCompanyLoading(false));
      } else {
        throw new Error(`API responded with status: ${response.status}`);
      }
    } catch (error: any) {
      dispatch(
        setCompanyError("Failed to create company details: " + error.message)
      );
      dispatch(setCompanyLoading(false));
    } finally {
      dispatch(setCompanyLoading(false));
    }
  };

export const deleteCompanyDetails =
  (companyId: string) => async (dispatch: Dispatch) => {
    dispatch(setCompanyLoading(true));
    try {
      await instance.delete(`/api/company/${companyId}`);
      dispatch(setCompanyLoading(false));
    } catch (error) {
      dispatch(setCompanyError("Failed to delete company details"));
      dispatch(setCompanyLoading(false));
    } finally {
      dispatch(setCompanyLoading(false));
    }
  };

export const fetchCompanyDetails =
  (id?: number, token?: string, includesJobs: boolean = true) =>
  async (dispatch: Dispatch) => {
    dispatch(setCompanyLoading(true));
    try {
      const response = await instance.get(`/company/company-details/${id}/`, {
        headers: {
          Authorization: token ? `Token ${token}` : "",
        },
      });
      if (response.status === 200) {
        const { jobs, ...companyDetailsWithoutJobs } = response.data.data;
        if (includesJobs) {
          dispatch(updateCompanyDetails(response.data.data));
        } else {
          dispatch(updateCompanyDetails(companyDetailsWithoutJobs));
        }
        dispatch(setCompanyLoading(false));
      } else {
        throw new Error(`API responded with status: ${response.status}`);
      }
    } catch (error: any) {
      dispatch(
        setCompanyError("Failed to fetch job details: " + error.message)
      );
      dispatch(setCompanyLoading(false));
    } finally {
      dispatch(setCompanyLoading(false));
    }
  };
