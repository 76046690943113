import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import "../../pages/SignIn/signin.css";
import { signInWithGoogle } from "../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";
import { Google_img } from "../../assets/images/images";
import registrationStore from "../../zustand/registrationStore";
const GoogleInUp = (props) => {
  const { formData } = registrationStore();
  const redirectUrl = useSelector((state) => state.auth.redirectUrl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginWithGoogle = async (e) => {
    e.preventDefault();
    dispatch(
      signInWithGoogle(
        navigate,
        redirectUrl,
        formData.userType === "jobseeker" ? 2 : 1
      )
    );
  };

  return (
    <>
      <button onClick={loginWithGoogle} className="signup_google_sec">
        <ReactSVG src={Google_img} disabled={props?.disabled} />
        {props.forContent}
      </button>
    </>
  );
};

export default GoogleInUp;
