import { useDispatch, useSelector } from "react-redux";
import { Credit, congrt, tick } from "../../../../../../assets/images/images";
import { Navbar } from "../../../../../../components/DashboardComponents/Navbar/Navbar";
import { useEffect } from "react";
import { fetchCompanyDetails } from "../../../../../../redux/actions/companyActions";
import { useNavigate } from "react-router-dom";
import { renderFeatures } from "../../../../../../utils/utils";

const PaymentSucceded = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const companyDetails = useSelector((state) => state.company.details);
  const companySub = companyDetails?.company_sub?.subscription_plan;
  const companyType = companyDetails?.company_sub?.type;
  const companyID = user.company_id ?? companyDetails?.id;

  const handleClickDashboard = () => {
    navigate("/dashboard", { replace: true });
  };

  useEffect(() => {
    if (companyID) {
      dispatch(fetchCompanyDetails(companyID, token, false));
    }
  }, []);

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12"></div>
          <div className="Payment_history payment_succeded">
            <div className="Payment_method_main">
              <div className="left_section">
                <div className="pricing-card bill_price_count">
                  <div className="min-h">
                    <div className="pricing-header">{companyType}</div>
                    <div className="pricing-amount">
                      ${companySub?.price} <span>/month</span>
                    </div>

                    <div className="pricing-description">
                      Features in {companyDetails?.company_sub?.type} Plan :
                    </div>

                    <div className="pricing-features">
                      <ul>
                        {renderFeatures(companyDetails?.company_sub?.type).map(
                          (feature, index) => (
                            <li key={index}>
                              <img src={tick} alt="" className="tick" />{" "}
                              {feature}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="right_section">
                <div className="Congratulations_main">
                  <img src={congrt} alt="Congratulations" />
                  <h2>Congratulations!</h2>
                  <p>
                    {" "}
                    Your purchase has been successfully processed. Thank you for
                    choosing Haire.
                  </p>
                </div>
                <div className="credits_counts">
                  <img src={Credit} alt="" />
                  {companySub?.credits_grant} Credits{" "}
                </div>

                <button
                  onClick={handleClickDashboard}
                  className="pricing-button"
                >
                  Back to Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSucceded;
