import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export const generateInvoicePdf = async (record,user,company) => {
  try {
    // Define the HTML content for the invoice
    const invoiceHtml = `
     <div id="invoice" style="background: white; ">
      <div style="font-family: Arial, sans-serif;">
        <div style="background-color: #F2F5F9; display: flex; justify-content: center; align-items: center; border-radius: 10px; font-family: Arial, sans-serif;">
          <div style="width: 100%; padding: 30px; display: flex; flex-direction: column;">
            <div style="font-size: 50px; margin-bottom: 20px; display: flex; justify-content: space-between;">
              <span style="color:#3498DB;font-size:60px;font-weight:700;">Haire.ai</span>
              <div style="text-align:right">
                <div style="color: #60737D;font-size:24px">Invoice No</div>
                  <div> #${record.key}</div>
              </div>
            	
            </div>
            <div style="display: flex; justify-content: space-between; font-size: 30px;">
              <div>
                <div>Billed To:</div>
                <div style="margin-top: 10px;">
                  <strong>${user.full_name}</strong><br>
                 <p style="width:50%;"> ${company.address}<p>
                </div>
              </div>
              <div style="text-align: right;">
                <div >
                  <div style="color: #60737D;font-size:24px">Issued on</div>
                  <div>${record.created_at}</div>
                </div>
                <div >
                  <div style="color: #60737D;font-size:24px">Payment Due</div>
                  <div>${record.expiry_date}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="padding: 10px; font-size: 35px; margin-top: 80px">
          <table style="width: 100%; border-collapse: collapse;">
            <thead>
              <tr>
                <th style="text-align: left; padding: 30px;">Services</th>
                <th style="text-align: right; padding: 30px;font-size:30px;font-weight:200;color: #60737D;">Qty.</th>
                <th style="text-align: right; padding: 30px;font-size:30px;font-weight:200;color: #60737D;">Price</th>
                <th style="text-align: right; padding: 30px;font-size:30px;font-weight:200;color: #60737D;">Total</th>
              </tr>
            </thead>
            <tbody style="background-color: #F2F5F9; border-radius: 10px;padding:30px;height:100px">
             
                 <tr >
                <td style="text-align: left; padding: 30px;">${record.type}</td>
                <td style="text-align: right; padding: 30px;">1</td>
                <td style="text-align: right; padding: 30px;">${record.price}</td>
                <td style="text-align: right; padding: 30px;">${record.price}</td>
              </tr>
              </div>
             
            </tbody>
          </table>
        </div>
        <div style="display: flex; justify-content: space-between; align-items: center; font-size: 32px; margin-top: 80px;">
          <div></div>
      <div style="background-color: #FFF; border-radius: 10px; background-color: #F2F5F9; padding: 20px; display: flex; flex-direction: column; gap: 10px;">
  <div style="display: flex; justify-content: space-between; gap: 30px; color: #60737D;">
    <p style="margin: 0; text-align: left; flex: 1;">Taxes & Fees:</p>
    <span style="font-weight: 600; color: #000; text-align: right;">0.00</span>
  </div>
  <div style="display: flex; justify-content: space-between; color: #60737D;">
    <p style="margin: 0; text-align: left; flex: 1;">Total:</p>
    <span style="font-weight: 600; color: #000; text-align: right;">${record.price}</span>
  </div>
</div>

</div>
        </div>
      </div>`;

    // Element to host the HTML content
    const container = document.createElement('div');
    container.style.position="absolute";
    container.style.left="-9999px";
    container.innerHTML = invoiceHtml;
    document.body.appendChild(container);

    // Render the HTML content to a canvas with high resolution
    const canvas = await html2canvas(container, {
      scale: 0.5, // Higher scale for better resolution
      windowWidth: container.scrollWidth,
      windowHeight: container.scrollHeight,
      dpi: 192, // Higher DPI for sharper text
      letterRendering: 1,
    });

    // Create a PDF and add the rendered image
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgWidth = 190; // full width for A4 paper less margins
    const imgHeight = (canvas.height * imgWidth) / canvas.width; // maintain aspect ratio
    pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 10, 10, imgWidth, imgHeight);

    pdf.setFont('helvetica', 'bold'); // Set font to bold
    pdf.setTextColor(33, 33, 33); // Dark grey color
    pdf.setFontSize(11);
    pdf.text("Arcot Media Pvt. Ltd.", 10, 277);
    pdf.setFont('helvetica', 'normal'); // Set font to normal for the remaining lines
    pdf.text("A - 802, Trade Link, Kamala Mills, Lower Parel, Mumbai-13.", 10, 282);
    pdf.setTextColor(0, 0, 255); // Blue color for email
    pdf.text("info@arcotgroup.com", 10, 287);

    pdf.save(`Invoice_${record.key || 'undefined'}.pdf`);
    // Clean up by removing the container
    document.body.removeChild(container);
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};
