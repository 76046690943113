import React from "react";
import { Modal, Button } from "antd";
import { close_job } from "../../../../assets/images/images";
// Adjust the import path as needed

const WarningModal = ({
  tabSwitchWarningVisible,
  handleCancelWarning,
  tabSwitchCount,
}) => {
  const renderWarningMessage = () => {
    switch (tabSwitchCount) {
      case 1:
        return (
          <div className="warning_mod">
            <h6 className="final_warning">
              We've noticed a distraction from the interview,
            </h6>
            <p>
              Such as switching away from the interview window. To ensure
              fairness, please focus on the interview questions.
            </p>
          </div>
        );
      case 2:
        return (
          <div className="warning_mod">
            <h6 className="final_warning"> You’ve been distracted again</h6>
            <p>
              You’ve been distracted again by moving away from the interview
              page. Staying on the interview page helps us evaluate everyone
              fairly
            </p>
          </div>
        );
      case 3:
        return (
          <div className="warning_mod">
            <h6 className="final_warning"> Final Distraction Warning</h6>
            <p>
              This is your final warning regarding distractions such as tab
              switching. Any further distractions will end the interview
              prematurely.
            </p>
          </div>
        );
      default:
        return <p>Warning: Please stay focused on the interview tab.</p>;
    }
  };

  return (
    <Modal
      title=""
      open={tabSwitchWarningVisible}
      onOk={handleCancelWarning}
      closable={false}
      cancelButtonProps={{ style: { display: "none" } }} // Hides the cancel button
      // Alternatively, you can provide a custom footer without a cancel button
      footer={[
        <div className="ctr-btn">
          <Button
            key="submit"
            onClick={handleCancelWarning}
            className="warning_button"
          >
            Ok, Understand
          </Button>
          ,
        </div>,
      ]}
    >
      <div className="warning_text">
        <img src={close_job} alt="Close Job Warning" />
        {renderWarningMessage()}
      </div>
    </Modal>
  );
};

export default WarningModal;
