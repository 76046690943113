// store.ts
import { create } from "zustand";
import * as Yup from "yup";
// @ts-ignore
import instance from "../api/instance";
import Cookies from "js-cookie";
import {
  setAuthToken,
  setJobseekerId,
  setNewUser,
  setUser,
} from "../redux/reducers/authReducer";
import { Dispatch } from "redux";
import { toast } from "react-toastify";
import { User } from "./userStore";
import { sendLoginOtp } from "./apis/sendLoginOtp";
import { verifyLoginOtp } from "./apis/verifyLoginOtp";
import validator from "validator";
// @ts-ignore
import { emailRegex } from "../utils/constants";

interface LoginCodeFormData {
  email: string;
}

interface FormErrors {
  [key: string]: string | undefined;
}

interface StoreState {
  formData: LoginCodeFormData;
  formErrors: FormErrors;
  setFormData: (name: keyof LoginCodeFormData, value: string) => void;
  validateForm: () => Promise<boolean>;
  validateField: (name: keyof LoginCodeFormData, value: string) => void;
  error: string | null;
  loading: boolean;
  modalOpen: boolean;
  loginWithCode: (
    email: string,
    onOtpSent: Function,
    redirectUrl?: string
  ) => void;
  verifyLoginOtp: (
    email: string,
    otp: number,
    dispatch: Dispatch,
    navigate: Function,
    redirectUrl?: string
  ) => Promise<boolean>;
  loginWithUserId: (
    email: string,
    userId: string,
    dispatch: Dispatch,
    navigate: Function,
    redirectUrl?: string
  ) => Promise<boolean>;
  resendLoginOtp: (email: string) => void;
  openModal: () => void;
  closeModal: () => void;
}

const isEmail = (email: string) => {
  return validator.isEmail(email) && emailRegex.test(email);
};

const schema = Yup.object<LoginCodeFormData>({
  email: Yup.string()
    // .email("Invalid email address")
    // .matches(
    //   // Regex for more stringent email validation
    //   /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
    //   "Please use valid email address"
    // )
    .required("Email is required")
    .test("is-valid-email", "Invalid email address", (value) =>
      isEmail(value ?? "")
    ),
});

const loginCodeStore = create<StoreState>((set, get) => ({
  formData: {
    email: "",
  },
  formErrors: {},
  setFormData: (name, value) =>
    set((state) => ({
      formData: { ...state.formData, [name]: value },
    })),
  validateForm: async () => {
    try {
      await schema.validate(get().formData, { abortEarly: false });
      set({ formErrors: {} });
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = err.inner.reduce<FormErrors>(
          (acc, { path, message }) => {
            if (path) {
              acc[path as keyof LoginCodeFormData] = message;
            }
            return acc;
          },
          {}
        );
        set({ formErrors: errors });
      }
      return false;
    }
  },
  validateField: async (name: keyof LoginCodeFormData, value: string) => {
    try {
      await schema.validateAt(name, { ...get().formData, [name]: value });
      set({ formErrors: { ...get().formErrors, [name]: undefined } });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        set({ formErrors: { ...get().formErrors, [name]: error.message } });
      }
    }
  },
  setFormErrors: (errors: any) =>
    set((state) => ({
      formErrors: { ...state.formErrors, ...errors },
    })),
  error: null,
  loading: false,
  modalOpen: false,
  loginWithCode: async (email, onOtpSent) => {
    set({ loading: true });
    try {
      const message = await sendLoginOtp(email);
      toast.success(message);
      onOtpSent();
    } catch (error) {
      if (error instanceof Error) {
        set({ error: error.message });
        toast.error(error.message);
      }
    } finally {
      set({ loading: false });
    }
  },
  verifyLoginOtp: async (email, otp, dispatch, navigate, redirectUrl) => {
    set({ loading: true });
    try {
      const loginResponse = await verifyLoginOtp(email, otp);

      const userDetails = {
        user_id: loginResponse.userId,
        email: email,
        key: loginResponse.userId,
      };

      // Send the user details to your backend
      const response = await instance.post(
        "/accounts/social-login/",
        userDetails
      );

      // Example of handling the response
      Cookies.set("token", response.data.data.token);
      dispatch(setAuthToken(response.data.data.token));
      dispatch(setNewUser(response.data.data.is_new_user));
      dispatch(setJobseekerId(response.data.data.jobseeker_id));

      const user: User = {
        user_id: response.data.data.user.id,
        job_seeker_id: response.data.data.jobseeker_id,
        full_name: response.data.data.user.full_name,
        email: email,
        type: 3,
        country_code: null,
        phone: response.data.data.contact,
        key: response.data.data.user.id,
        role: response.data.data.user.role,
        registered_company_name:
          response.data.data.user.registered_company_name,
        registered_industry_name: response.data.data.user.registered_industry_name,
        company_id: response.data.data.company_id,
        languages: null,
        websites: null,
        profile_pic: null,
        designation: null,
        dob: null,
        gender: null,
        address: null,
        introduction: null,
        resume: response.data.data.user.resume,
        resume_json: null,
        is_active: null,
        is_deleted: null,
        profile_completed: response.data.data.profile_completed,
        token: response.data.data.token,
        companyName: null,
        verified: true,
      };

      await dispatch(setUser(user));

      // Navigate based on role
      if (user.role === 1) {
        navigate("/dashboard");
      } else if (user.role === 2) {
        navigate(redirectUrl || "/applicant-dashboard");
      } else {
        navigate("/user-role");
      }
      return true; // Indicate successful verification
    } catch (error) {
      if (error instanceof Error) {
        set({ error: error.message });
      }
      return false; // Indicate failed verification
    } finally {
      set({ loading: false });
    }
  },

  loginWithUserId: async (email, userId, dispatch, navigate, redirectUrl) => {
    set({ loading: true });
    try {

      const userDetails = {
        user_id: userId,
        email: email,
        key: userId,
      };

      // Send the user details to your backend
      const response = await instance.post(
        "/accounts/social-login/",
        userDetails
      );

      // Example of handling the response
      Cookies.set("token", response.data.data.token);
      dispatch(setAuthToken(response.data.data.token));
      dispatch(setNewUser(response.data.data.is_new_user));
      dispatch(setJobseekerId(response.data.data.jobseeker_id));

      const user: User = {
        user_id: response.data.data.user.id,
        job_seeker_id: response.data.data.jobseeker_id,
        full_name: response.data.data.user.full_name,
        email: email,
        type: 3,
        country_code: null,
        phone: response.data.data.contact,
        key: response.data.data.user.id,
        role: response.data.data.user.role,
        registered_company_name:
          response.data.data.user.registered_company_name,
        registered_industry_name: response.data.data.user.registered_industry_name,
        company_id: response.data.data.company_id,
        languages: null,
        websites: null,
        profile_pic: null,
        designation: null,
        dob: null,
        gender: null,
        address: null,
        introduction: null,
        resume: response.data.data.user.resume,
        resume_json: null,
        is_active: null,
        is_deleted: null,
        profile_completed: response.data.data.profile_completed,
        token: response.data.data.token,
        companyName: null,
        verified: true,
      };

      await dispatch(setUser(user));

      // Navigate based on role
      if (user.role === 1) {
        navigate("/dashboard");
      } else if (user.role === 2) {
        navigate(redirectUrl || "/applicant-dashboard");
      } else {
        navigate("/user-role");
      }
      return true; // Indicate successful verification
    } catch (error) {
      if (error instanceof Error) {
        set({ error: error.message });
      }
      return false; // Indicate failed verification
    } finally {
      set({ loading: false });
    }
  },

  resendLoginOtp: async (email) => {
    set({ loading: true });
    try {
      const message = await sendLoginOtp(email);
      toast.success(message);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        set({ error: error.message });
      }
      return false;
    } finally {
      set({ loading: false });
    }
  },
  openModal: () => set({ modalOpen: true }),
  closeModal: () => set({ modalOpen: false, error: null }),
}));

export default loginCodeStore;
