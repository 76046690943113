import React from 'react'
import './commonComponant.css'
import {PageNotFoundImg} from '../../assets/images/images'
import { useNavigate } from 'react-router-dom';
const PageNotFound = () => {
  const navigate = useNavigate();

  const handleBack = (event) => {
    event.preventDefault();
    navigate(-1, { replace: true });
  }

  const redirectToHome = (event) => {
    event.preventDefault();
    navigate("/", {replace: true});
  }

  return (
    <section className='container'>
        <div className='page_not_found_main'>
        <img src= {PageNotFoundImg} alt='page not found'/>
            <h3>Page Not Found</h3>
            <p>We couldn’t find the page you are looking for.<br/>Please 
You can go back to the <a href='#' onClick={handleBack}>Previous Page</a> or go back to the <a href='#' onClick={redirectToHome}>Home Page</a></p>
                <button onClick={handleBack} className='Pagenotfoundbackbtn'>Go Back</button>
        </div>
    </section>
  )
}

export default PageNotFound