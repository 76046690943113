import { MuiOtpInput } from "mui-one-time-password-input";

export const CustomOtpInput = (props) => {
  const handleInput = (e) => {
    // Allow only numeric input
    const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab"];
    const nonNumericPattern = /[^0-9]/gi;

    // Check if pasting (excluding allowed keys) and pasted content is non-numeric
    if (
      e.type === "paste" &&
      !allowedKeys.includes(e.key) &&
      nonNumericPattern.test(e.clipboardData.getData("text"))
    ) {
      e.preventDefault();
      return; // Prevent default paste behavior if non-numeric
    }
  };

  return (
    <MuiOtpInput {...props} onKeyDown={handleInput} onPaste={handleInput} className="otp_box"/>
  );
};
