import React, { useEffect } from "react";
import { ApplicantRoutes, RecruiterRoutes, CommonRoutes } from "./routes";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { Modal } from "antd";
import { handleInvalidToken } from "../utils/tokenUtils";

const RoleBasedRoutes = () => {
  const userRole = useSelector(
    (state) => state.auth.user?.role ?? state.auth.user?.user_role
  );
  const token = Cookies.get("token");
  const location = useLocation();

  useEffect(() => {
    if (!token && userRole) {
      handleInvalidToken();
    }
  }, [token, userRole, location]);

  if (userRole === 1) {
    return <RecruiterRoutes />;
  } else if (userRole === 2) {
    return <ApplicantRoutes />;
  } else {
    return <CommonRoutes />;
  }
};

export default RoleBasedRoutes;
