import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface LocationState {
  userCountry: string | null;
  error: string | null;
  loading: boolean | null;
}
const initialState = {
  userCountry: "us", // Default to US, change as needed
  error: null,
  loading: false,
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLocation: (state: LocationState, action: PayloadAction<string>) => {
      state.userCountry = action.payload as string;
    },
    setLocationError: (state: LocationState, action: PayloadAction<string>) => {
      state.error = action.payload as string;
    },
    setLocationLoading: (
      state: LocationState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
    resetLocationState: () => initialState,
  },
});

export const { setLocation, setLocationLoading, setLocationError } =
locationSlice.actions;
export default locationSlice.reducer;
