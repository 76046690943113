// @ts-ignore
import instance from "../../api/instance";

/**
 * Sends a login OTP to the specified email.
 * @param {string} email - The email address to which the OTP should be sent.
 * @returns {Promise<string>} - A promise that resolves with a success message or throws an error.
 */
export async function sendLoginOtp(email: string) {
  try {
    const response = await instance.post(
      `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS}/sendLoginOtp`,
      JSON.stringify({ email }),
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    if (response.data.message === "Login OTP sent successfully") {
      return "Login OTP sent to your email successfully.";
    } else {
      throw new Error(`Server responded with status: ${response.status}`);
    }
  } catch (error: any) {
    throw new Error(
      error.response?.data?.error || "Failed to send login OTP."
    );
  }
}
