import { Box, FormHelperText } from "@mui/material";
import $ from "jquery";
import MuiPhoneNumber from "material-ui-phone-number";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ThreeCircles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { accverifiction } from "../../assets/images/images";
import DashboardHeader from "../../components/CommonComponents/DashboardHeader";
import { CustomOtpInput } from "../../components/CustomComponents/CustomOtpInput";
import {
  sendOTP,
  updateUserName,
  verifyOTP,
} from "../../redux/actions/otpVerificationActions";
import { getFirebaseVerificationErrorMessage } from "../../utils/firebaseErrorFunction";
import {
  getCountryCode,
  getPhoneNumberWithoutCountryCode,
  validatePhoneNumber,
} from "../../utils/utils";
import "../SignIn/signin.css";

const AccountVerification = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth?.user);
  const role = useSelector((state) => state.auth?.user?.role);
  const otpVerification = useSelector((state) => state?.otpVerification);
  const location = useSelector((state) => state.location?.userCountry);
  const navigate = useNavigate();

  const [timer, setTimer] = useState(60);
  const [phone, setPhone] = useState(
    user && user.phone
      ? `+91 ${user.phone.slice(0, 5)}-${user.phone.slice(5)}`
      : ""
  );
  const [isValidNumber, setIsValidNumber] = useState(true);
  const [name, setName] = useState(user?.full_name || "");
  const {
    control,
    handleSubmit: handleSubmitOTP,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      otp: "",
    },
  });
  const otpValue = watch("otp");
  const countryCode = phone !== null && phone !== "" && getCountryCode(phone);
  const verificationId = useSelector(
    (state) => state.otpVerification.verificationId
  );

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePhoneNumberChange = (phone) => {
    setPhone(phone);
    setIsValidNumber(validatePhoneNumber(phone)); // Update validation state immediately
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isValidNumber && phone) {
      try {
        await dispatch(sendOTP(phone)).then(() => {
          $("#verify_sec").show();
          $("#verify_sec_amin").hide();
          toast.success("Verification code sent successfully");
        });
        setTimer(60);
      } catch (error) {
        toast.error("Error sending OTP:", error);
      }
    } else {
      toast.error("Please enter a valid 10 digit phone number");
      setIsValidNumber(false); // Ensure the UI reflects this state
    }
  };

  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const onSubmit = async (data) => {
    toast.dismiss();
    if (verificationId) {
      try {
        await dispatch(
          verifyOTP(
            verificationId,
            data.otp,
            countryCode,
            getPhoneNumberWithoutCountryCode(phone),
            name
          )
        );
        dispatch(updateUserName(name));
        navigate(role === 1 ? "/dashboard" : "/confirm-information", {
          replace: true,
        });
      } catch (error) {
        const errorMessage = getFirebaseVerificationErrorMessage(error.code);
        reset({ otp: "" });
        $("#verify_sec").hide();
        $("#verify_sec_amin").show();
        toast.error(errorMessage);
      }
    } else {
      toast.error("Verification ID is missing");
    }
  };

  const handleResendOTP = async () => {
    if (validatePhoneNumber(phone)) {
      try {
        await dispatch(sendOTP(phone)).then((confirmationResult) => {
          toast.success("Verification code resent successfully");
        });
      } catch (error) {
        toast.error(error);
      }
      // Reset the OTP field in the form to be empty
      reset({ otp: "" });

      // Reset and start the timer again for the OTP resend cooldown
      setTimer(60);
    } else {
      toast.error("Invalid phone number");
    }
    setTimer(60);
  };
  const handleBack = () => {
    navigate("/resume-builder", { replace: true });
  };

  return (
    <>
      <ToastContainer />
      <div id="recaptcha-container"></div>

      <section className="singin_sec_new">
        <div className="singin_sec_new_left">
          <img src={accverifiction} alt="" />
        </div>
        <div className="acc_verifictaion_sec" id="verify_sec_amin">
          {role === 1 ? (
            <></>
          ) : (
            <DashboardHeader
              className="position_back"
              title=""
              backButtonLink={handleBack}
            />
          )}
          <h6>Account Verification</h6>
          <div className="acc_verifictaion_sec_inner">
            <form onSubmit={handleSubmit} className="acc_ver_in_box">
              <h5>Please Verify your account.</h5>
              <div className="inner_input_box">
                <label>Enter Full Name</label>
                <input
                  type="text"
                  placeholder="Enter Full Name"
                  value={name}
                  onChange={handleNameChange}
                />
              </div>
              <div className="inner_input_box">
                <label>Enter Mobile Number</label>
                <div className="inner_input_box_select">
                  <MuiPhoneNumber
                    defaultCountry={location}
                    value={phone}
                    onChange={handlePhoneNumberChange}
                    className="mobile_number_input"
                  />
                </div>
                {!isValidNumber && phone && (
                  <div style={{ color: "red" }}>
                    Please enter a valid 10 digit phone number
                  </div>
                )}
              </div>
              <div
                className="login_btn_blue send_verfy_btn"
                key={isValidNumber}
              >
                <button
                  type="button"
                  disabled={!phone || !isValidNumber}
                  onClick={handleSubmit}
                >
                  {otpVerification?.loading ? (
                    <ThreeCircles
                      visible={true}
                      height="20"
                      width="20"
                      color="#fff"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    "Verify"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>

        <div
          className="acc_verifictaion_sec"
          id="verify_sec"
          style={{ display: "none" }}
        >
          <h6>Verification Code</h6>
          <div className="acc_verifictaion_sec_inner">
            <div className="acc_ver_in_box">
              <form
                onSubmit={handleSubmitOTP(onSubmit)}
                className="enter_otp_box_sec acc_veri"
              >
                <label>Enter Verification Code</label>
                <div className="enter_otp_box_sec_inputs">
                  <Controller
                    control={control}
                    rules={{ validate: (value) => value.length === 6 }}
                    render={({ field, fieldState }) => (
                      <Box>
                        <CustomOtpInput sx={{ gap: 1 }} {...field} length={6} />
                        {fieldState.invalid && (
                          <FormHelperText error>
                            Verification code invalid
                          </FormHelperText>
                        )}
                      </Box>
                    )}
                    name="otp"
                  />
                </div>
                <div className="resend_btn_new">
                  <button
                    type="button"
                    onClick={handleResendOTP}
                    className="resend_otp_btn_new"
                    disabled={timer > 0}
                  >
                    Resend Verification Code
                  </button>
                  <span>{timer < 10 ? `00:0${timer}` : `00:${timer}`}</span>
                </div>
                <div className="login_btn_blue send_verfy_btn">
                  <button
                    type="submit"
                    disabled={otpValue?.length < 6}
                    className="blue_btn_links"
                  >
                    {otpVerification?.loading ? (
                      <ThreeCircles
                        visible={true}
                        height="20"
                        width="20"
                        color="#fff"
                        ariaLabel="loading-indicator"
                      />
                    ) : (
                      "Verify"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccountVerification;
