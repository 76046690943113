import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import CancellationReasonModal from "./CancellationReasonModal";  // Ensure this is the correct path

const CancelSubModal = ({ show, onHide }) => {
  const [showReasonModal, setShowReasonModal] = useState(false);


  const handleCancelSubscription = () => {
    setShowReasonModal(true);
    onHide();
  };

  const handleCloseReasonModal = () => {
    setShowReasonModal(false);
   
  };

  return (
    <>
   
    <Modal
      show={show}
      onHide={onHide}
      className="report_modal_sec"
    >
      <div className="report_modal_sec_inner">
        <div className="report_modal_sec_inner_head">
          <button onClick={onHide}>
            <i className="fas fa-times"></i>  {/* Ensure you are including FontAwesome correctly */}
          </button>
        </div>
        <div className="report_modal_sec_inner_body Add_card_modal">
          <h3>Sorry to see you go!</h3>
          <p className="plan_cancle_p">Once the plan is cancelled, your current plan and credits will remain active until the end of your billing period.</p>
          <p className="plan_cancle_p"><span>Please note:</span> After cancellation, no further charges or credit renewals will be made.</p>
          <div className="cancle_sub_btn">
            <button className="Add_card_btn wdt_btn_bg" onClick={handleCancelSubscription}>Cancel subscription</button>
            <button className="Add_card_btn clear_btn wdt_btn_bg wdt_btn_bg_hover" onClick={onHide}>I Changed my mind</button>
          </div>
        </div>
      </div>
    </Modal>
     <CancellationReasonModal show={showReasonModal} onHide={handleCloseReasonModal} />
     </>
  );
};

export default CancelSubModal;
