import Cookies from "js-cookie";
import { Modal } from "antd";

let isModalVisible = false;

export const handleInvalidToken = () => {
  if (isModalVisible) return;

  isModalVisible = true;

  Modal.error({
    title: "Session Expired",
    content: "Your session has expired. Please log in again.",
    okText: "I understand, logout",
    onOk: () => {
      Cookies.remove("token");
      Cookies.remove("activeTab");
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace("/login");
    },
    closable: false,
    maskClosable: false,
    okButtonProps: { style: { zIndex: 1000 } },
    centered: true, // Center horizontally
    onCancel: () => {
      isModalVisible = false;
    },
    afterClose: () => {
      isModalVisible = false;
    },
  });
};
