const TableSpan = ({ status }) => {
  let color, textColor, text;

  switch (status) {
    case "Published":
      color = "rgba(3, 136, 64, 0.20)";
      textColor = "#038840";
      text = "ACTIVE";
      break;
    case "Active":
      color = "rgba(3, 136, 64, 0.20)";
      textColor = "#038840";
      text = status?.toUpperCase();
      break;
    case "Closed":
      color = "rgba(218, 0, 0, 0.20)";
      textColor = "#DC0000";
      text = status.toUpperCase();
      break;
    case "Demo":
      color = "#DCEAFC";
      textColor = "#416EAC";
      text = status?.toUpperCase();
      break;
    default:
      color = "#c1c1c1";
      text = status?.toUpperCase();
  }
  return (
    <span
      style={{
        backgroundColor: color,
        color: textColor,
        fontSize: "12px",
        fontWeight: "600",
        padding: "4px 25px",
        borderRadius: "4px",
        display: "inline-block",
        maxWidth: "110px",
        width: "100%",
      }}
    >
      {text}
    </span>
  );
};

export default TableSpan;
