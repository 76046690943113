import { useLocation, useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchDemoInterviewDetails } from "../../redux/actions/jobActions";
import {
  alert_terminated,
  clock_icon,
  inbox_box,
  Qus_line,
  timer_ic,
} from "../../assets/images/images";
import { Navbar } from "../../components/DashboardComponents/Navbar/Navbar";
import { Modal } from "react-bootstrap";
import "./TryHaire.css";
import AssessmentItem from "./AssessmentItem";

const TryHaireAssesment = () => {
  const navigate = useNavigate();
  const { interviewId } = useParams();
  const token = useSelector((state) => state.auth.token);
  const companyDetails = useSelector((state) => state.company.details);
  const companyCreated = companyDetails?.id;
  const [interviewDetails, setInterviewDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const assessmentData = [
    {
      title: "Technical Skills",
      rating: interviewDetails?.assessment?.data?.technical_skills.rating,
      description:
        interviewDetails?.assessment?.data?.technical_skills.description,
    },
    {
      title: "Problem Solving",
      rating: interviewDetails?.assessment?.data?.problem_solving?.rating,
      description:
        interviewDetails?.assessment?.data?.problem_solving?.description,
    },
    {
      title: "Overall Impression",
      rating: interviewDetails?.assessment?.data?.overall_rating,
      description: interviewDetails?.assessment?.data?.overall_impression,
    },
    {
      title: "Behavioral Skills",
      rating: interviewDetails?.assessment?.data?.behavioral_skills?.rating,
      description:
        interviewDetails?.assessment?.data?.behavioral_skills?.description,
    },
    {
      title: "Communication Skills",
      rating: interviewDetails?.assessment?.data?.communication_skills?.rating,
      description:
        interviewDetails?.assessment?.data?.communication_skills?.description,
    },
  ];

  const fetchDetails = async () => {
    if (token && interviewId) {
      try {
        const details = await fetchDemoInterviewDetails(token, interviewId);
        setInterviewDetails(details);
      } catch (error) {
        message.error("Failed to fetch interview details");
        console.error(error);
      }
    } else {
      message.error("Missing token or interview ID");
    }
  };

  useEffect(() => {
    fetchDetails();
  }, [token, interviewId]);

  // Show modal after 2 seconds
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (!companyCreated) {
  //       setShowModal(true);
  //     }
  //   }, 2000);

  //   return () => clearTimeout(timer); // Cleanup the timer on component unmount
  // }, []);

  const handleClick = (e) => {
    e.preventDefault();
    navigate("/dashboard/my-jobs/");
  };

  const formatDurationTime = (duration) => {
    if (!duration) return "N/A";
    const [hours, minutes, seconds] = duration.split(":");
    const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
    const totalSeconds = Math.floor(parseFloat(seconds));
    return `${totalMinutes}:${totalSeconds.toString().padStart(2, "0")}`;
  };

  const handleCloseModal = () => setShowModal(false);
  return (
    <>
      <Navbar />

      {interviewDetails ? (
        <div className="container-fluid top-m">
          <div className="try-haire-transcript">
            <div className="transcript_header_sub-line">
              <div className="demo-report-head">
                <div>
                  <h5>Demo Report</h5>
                  <div className="timer-set">
                    <div className="timer-set-inner">
                      <img src={timer_ic} alt="timer" />
                      <p>
                        {calculateTimeSpan(
                          interviewDetails?.interview?.start_time,
                          interviewDetails?.interview?.ended_at
                        )}{" "}
                        minutes
                      </p>
                    </div>
                    <div className="timer-set-inner">
                      <img src={Qus_line} alt="timer" />
                      <p>{interviewDetails.transcripts?.length} Questions</p>
                    </div>
                  </div>
                </div>
                <div className="demo-report-head-btn-container">
                  <button
                    onClick={handleClick}
                    className="flex-end demo-report-head-btn"
                  >
                    View Demo Job
                  </button>
                </div>
              </div>

              <div className="blue_line_min"></div>
            </div>
            {/* Assessment Section */}
            <div className="Transcript_main_pd">
              <div className="transcript_header">
                <div className="transcript_header_sub transcript_header_sub_try">
                  <h5 className="transcript_header_sub_h5">Interview Assessment</h5>
                  <br />
                </div>
              </div>

              {interviewDetails?.interview?.tab_switch_count >= 3 && (
                <p className="terminated">
                  <img src={alert_terminated} alt="terminated" />
                  Interview Terminated
                </p>
              )}
            </div>
            <hr />
            {interviewDetails.assessment && (
              <div>
                <ul className="Transcript_page">
                  {assessmentData.map((item, index) => (
                    <AssessmentItem
                      key={index}
                      title={item.title}
                      rating={item.rating}
                      description={item.description}
                      companyCreated={companyCreated}
                    />
                  ))}
                </ul>
              </div>
            )}

            <hr />
            {/* Transcript Section */}
            <div className="Transcript_main_pd">
              <div className="transcript_header">
                <div className="transcript_header_sub">
                  <h5>Interview Transcription</h5>
                </div>
              </div>

              {interviewDetails?.interview?.tab_switch_count >= 3 && (
                <p className="terminated ">
                  {" "}
                  {/* Apply blur here */}
                  <img src={alert_terminated} alt="terminated" />
                  Interview Terminated
                </p>
              )}
            </div>
            <hr />
            {interviewDetails.transcripts?.length > 0 ? (
              <div>
                {[...interviewDetails.transcripts]
                  .reverse()
                  .map((item, index, arr) => (
                    <div key={index}>
                      <p className="trans_question">
                        <strong>Question {index + 1} : Hairo AI</strong> <br />
                        <span>{item?.ai_question}</span>
                      </p>

                      <p className="trans_answer">
                        <strong className="strong_answer">
                          Answer : {interviewDetails?.user_full_name}{" "}
                          {item?.answer_duration && (
                            <>
                              | <img src={clock_icon} alt="Clock Icon" />{" "}
                              {formatDurationTime(item?.answer_duration)}
                            </>
                          )}
                          <br />
                        </strong>{" "}
                        <span>{item?.candidate_answer}</span>
                      </p>
                      <p className="trans_interpretation">
                        <div className="trans_interpretation_description">
                          <strong>
                            AI interpretation :
                            <br />
                          </strong>
                          <span className="note-class">
                            (Note: Complete your company details to view the AI
                            interpretation.)
                          </span>
                        </div>
                        <span className="blur-text">
                          {item?.ai_interpretation}
                        </span>
                      </p>
                      <hr className="mr-left" />
                    </div>
                  ))}
              </div>
            ) : (
              <p>No transcripts available.</p>
            )}
          </div>
          <div className="demo-report-head-btn-container">
            <button
              onClick={handleClick}
              className="flex-end demo-report-head-btn"
            >
              View Demo Job
            </button>
          </div>
        </div>
      ) : (
        <p>Loading interview details...</p>
      )}

      {/* Modal for TryHaire */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        size="lg"
      >
        <Modal.Body className="custom-wd-md">
          <div className="guideline guideline1">
            <img src={inbox_box} alt="inbox" />
            <h1>Unlock your Demo Assessment! Upgrade Now</h1>
            <p>
              To access the detailed transcription of your AI interview,
              purchase a plan that best suits your needs.
            </p>
            <button onClick={handleClick} className="Explore_plans">
              Explore Plans
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TryHaireAssesment;
export const calculateTimeSpan = (startTime, endTime) => {
  const start = new Date(`1970-01-01T${startTime}Z`);
  const end = new Date(`1970-01-01T${endTime}Z`);
  const diff = end - start; // Difference in milliseconds

  const minutes = Math.floor(diff / 60000);
  const seconds = ((diff % 60000) / 1000).toFixed(0);

  return `${minutes}:${seconds.padStart(2, "0")}`; // Format to "mm:ss"
};
