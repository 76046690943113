import React, { useEffect, useState } from "react";
import validator from "validator";

import "./Invites.css";
import { emailRegex } from "../../../utils/constants";

const Invites = ({ onItemsChange }) => {
  const [items, setItems] = useState([]);
  const [value, setValue] = useState("");
  const [error, setError] = useState(null);

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab"].includes(evt.key)) {
      evt.preventDefault();

      processInput(value.trim());

      // const trimmedValue = value.trim();

      // if (trimmedValue && isValid(trimmedValue)) {
      //   setItems([...items, trimmedValue]);
      //   setValue("");
      // }
    }
  };

  useEffect(() => {
    onItemsChange(items);
  }, [items, onItemsChange]);

  const handleChange = (evt) => {
    setValue(evt.target.value);
    setError(null);
  };

  const handleDelete = (item) => {
    setItems(items.filter((i) => i !== item));
  };

  const handlePaste = (evt) => {
    evt.preventDefault();

    const paste = evt.clipboardData.getData("text").trim();

    processInput(paste);

    // const emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

    // if (emails) {
    //   const toBeAdded = emails.filter((email) => !isInList(email) && isValid(email));
    //   setItems([...items, ...toBeAdded]);
    // }
  };

  // const processInput = (input) => {
  //   const emailArray = input.split(/[, ]+/).map(email => email.trim());
  //   const validEmails = emailArray.filter(email => isValid(email));
  //   setItems([...items, ...validEmails]);
  //   setValue("");
  // };

  const processInput = (input) => {
    // Split the input by commas or spaces and trim each potential email.
    const emailArray = input.split(/[, ]+/).map((email) => email.trim());
    const validEmails = [];
    let invalidEmail = null;

    // Check if original input has trailing spaces
    if (input !== input.trim()) {
      setError("Trailing spaces are not allowed after an email.");
      setValue(input.trim());
      return;
    }

    emailArray.forEach((email) => {
      if (isValid(email)) {
        validEmails.push(email);
      } else {
        invalidEmail = email; // Capture the first invalid email to show in the input box
      }
    });

    if (validEmails.length) {
      setItems([...items, ...validEmails]);
    }

    if (invalidEmail) {
      setValue(invalidEmail); // Set invalid email in the input box
    } else {
      setValue(invalidEmail || ""); // Clear the input box if all emails are valid
    }
  };

  const isValid = (email) => {
    let validationError = null;

    if (isInList(email)) {
      validationError = `${email} has already been added.`;
    } else if (!isEmail(email)) {
      validationError = `${email} is not a valid email address.`;
    }

    if (validationError) {
      setError(validationError);
      return false;
    }

    return true;
  };

  const isInList = (email) => items.includes(email);

  const isEmail = (email) => {
    return validator.isEmail(email) && emailRegex.test(email);
  };

  return (
    <>
      <div className="d-block1">
        <div className="wd">
          <input
            className={`input ${error ? "has-error" : ""}`}
            value={value}
            placeholder="Type or paste email addresses and press `Enter`..."
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            onPaste={handlePaste}
          />
          {error && <p className="error">{error}</p>}
        </div>
        <div className="">
          {items.map((item) => (
            <div className="tag-item" key={item}>
              {item}
              <button
                type="button"
                className="button"
                onClick={() => handleDelete(item)}
              >
                &times;
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Invites;
