import OwlCarousel from "react-owl-carousel";
import {
  logo_sld_1,
  logo_sld_2,
  logo_sld_3,
  logo_sld_4,
  logo_sld_5,
  logo_sld_6,
  logo_sld_7,
} from "../../../assets/images/images";
import "../../Home/home.css";
import "../../Home/home_responsive.css";

const TrustedPartner = () => {
  const options = {
    lazyLoad: true,
    loop: true,
    margin: 10,
    responsiveClass: true,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: false,
    // autoHeight: true,
    // rewindNav: true,
    mouseDrag: true,
    touchDrag: true,
    smartSpeed: 1000,
    nav: false,
    dots: true,
    responsive: {
      0: {
        items: 2,
      },

      600: {
        items: 4,
      },

      1024: {
        items: 6,
      },

      1366: {
        items: 6,
      },
    },
  };
  return (
    <section className="trusted_part_sec_new wow animate__fadeInRight" data-wow-duration="3s">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="">
              <div className="trusted_partne_logo_slide">
                <h6>Trusted By</h6>
                <div className="trusted_logo_slide_owl">
                  <OwlCarousel className="olw_slide_logo" {...options}>
                    <div className="item">
                      <div className="slide_logo_img_sec">
                        <img src={logo_sld_1} alt="" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="slide_logo_img_sec">
                        <img src={logo_sld_2} alt="" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="slide_logo_img_sec">
                        <img src={logo_sld_3} alt="" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="slide_logo_img_sec">
                        <img src={logo_sld_4} alt="" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="slide_logo_img_sec">
                        <img src={logo_sld_5} alt="" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="slide_logo_img_sec">
                        <img src={logo_sld_6} alt="" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="slide_logo_img_sec">
                        <img src={logo_sld_7} alt="" />
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrustedPartner;
