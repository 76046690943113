import { Link, useNavigate } from "react-router-dom";
import {
  banner_right_img,
  big_white_cta,
  blue_arow,
  client_trust_img,
  hire_pep,
  mid_white_cta,
  secure_img,
  star_bg,
} from "../../../assets/images/images";
import Typewriter from "../../../components/CommonComponents/Typewriter";
import "../../Home/home.css";
import "../../Home/home_responsive.css";
import { WOW } from "wowjs";
import "animate.css/animate.min.css";
import { useEffect } from "react";

const Banner = () => {
  const navigate = useNavigate();
  const handleClick = (event) => {
    event.preventDefault();
    navigate("/try-haire");
  };

  useEffect(() => {
    new WOW().init();
  }, []);

  return (
    <section className="home_banner_sec_new1">
      <div className="container-fluid banner-container1">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <header
              className="header-banner wow animate__zoomIn"
              data-wow-duration="2s"
            >
              <span>
                <img src={star_bg} alt="star" /> Discover the Future of
                Recruitment
              </span>
            </header>
            <div className="AI-Driven-Smart">
              <h1 className="wow animate__zoomIn" data-wow-duration="2.5s">
                AI-Driven Smart Hiring and Interview
              </h1>
              <p className="wow animate__zoomIn" data-wow-duration="3s">
                Our AI-driven platform seamlessly identifies, interviews,
                shortlists, and <br /> assesses applicants for you.
              </p>
              <button
                className="try-button try-haire-btn wow animate__zoomIn"
                data-wow-duration="3.5s"
                onClick={handleClick}
              >
                Try Haire
                <div className="round-arow"><img
                  className="try-haire-btn-sub-new"
                  src={blue_arow}
                  alt="round arrow"
                /></div>
                
                
                {/* <img
                  className="try-haire-btn-sub"
                  src={mid_white_cta}
                  alt="round arrow"
                />
                <img
                  className="try-haire-btn-sub1"  
                  src={big_white_cta}
                  alt="round arrow"
                /> */}
              </button>
              <div
                class="scroll-down wow animate__zoomIn"
                data-wow-duration="4s"
              >
                <span class="scroll-dot"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
