// @ts-ignore
import instance from "../../api/instance";

/**
 * Verifies the login OTP for the specified email.
 * @param {string} email - The email address to which the OTP was sent.
 * @param {number} otp - The OTP to verify.
 * @returns {Promise<{ verified: boolean; message: string; userId?: string }>} - A promise that resolves with the verification result or throws an error.
 */
export async function verifyLoginOtp(email: string, otp: number) {
  try {
    const response = await instance.post(
      `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS}/verifyLoginOtp`,
      JSON.stringify({ email, otp }),
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Server responded with status: ${response.status}`);
    }
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to verify login OTP."
    );
  }
}
