import { Progress } from "antd";
import { useEffect, useState } from "react";
import { analyse_img } from "../../../../assets/images/images";

export const Analyse = () => {
  const [progress, setProgress] = useState(0);
  const analyze = () => {
    setProgress(0);
    let progressValue = 0;
    const interval = setInterval(() => {
      if (progressValue < 100) {
        progressValue += 1;
        setProgress(progressValue);
      } else {
        clearInterval(interval);
      }
    }, 100);
  };
  useEffect(() => {
    analyze();
  }, []);

  return (
    <>
      <section className="analsyse_sec_new">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-8 mx-auto">
              <div className="analsyse_sec_new_inner">
                <h6>Analysing candidate's performance.</h6>
                <img src={analyse_img} alt="" />
                <div className="progress-wd">
                  <Progress percent={progress} />
                </div>
                <ul>
                  <li>
                    Analyzing the results from the screening round
                    comprehensively.
                  </li>
                  <li>
                    Analyzing the results from the technical round
                    comprehensively.
                  </li>
                  <li>Analyzing of your overall performance.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
