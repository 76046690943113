import instance from "./instance";

export const fetchInterviewDetails = async (token, interviewId) => {
  try {
    const response = await instance.get(
      `/interviews/check-interview-assessment/${interviewId}/`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data || false;
    } else {
      throw new Error(`API responded with status: ${response.status}`);
    }
  } catch (error) {
    console.log("Error in interview-details ", error);
  }
};

export const fetchTryInterviewDetails = async (token, interviewId) => {
  try {
    const response = await instance.get(
      `/demo/check-demo-interview-assessment/${interviewId}/`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data || false;
    } else {
      throw new Error(`API responded with status: ${response.status}`);
    }
  } catch (error) {
    console.log("Error in interview-details ", error);
  }
};
