import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import * as React from "react";
import { useEffect } from "react";

const customTheme = createTheme({
  components: {
    MuiPickersPopper: {},
    MuiClockPicker: {
      styleOverrides: {
        arrowSwitcher: {
          right: 8,
          top: 8,
        },
        root: {
          width: 220,
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        spacer: {
          width: 12,
        },
      },
    },
    MuiClock: {
      styleOverrides: {
        root: {
          margin: 1,
        },
        clock: {
          transform: `scale(0.8)`,
          fontSize: "125%",
        },
      },
    },
  },
});

export default function ResponsiveTimePickers({ onTimeChange }) {
  const [selectedTime, setSelectedTime] = React.useState(dayjs("2022-04-17"));
  const formattedTime = selectedTime.format("h:mm A");
  useEffect(() => {
    onTimeChange(formattedTime);
  }, [formattedTime, onTimeChange]);
  const handleCancel = () => {
    setSelectedTime(dayjs("2022-04-17")); // Reset selected time to default value
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={customTheme}>
        <DemoContainer
          components={[
            "TimePicker",
            "MobileTimePicker",
            "DesktopTimePicker",
            "StaticTimePicker",
          ]}
        >
          <DemoItem>
            <StaticTimePicker
              value={selectedTime}
              defaultValue={dayjs("2022-04-17T15:30")}
              onChange={(newValue) => setSelectedTime(newValue)}
              onCancel={handleCancel}
            />
          </DemoItem>
        </DemoContainer>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
