import React, { useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { haire_gif_new, new_bg, reset_img } from "../../assets/images/images";
import PasswordField from "../../components/CommonComponents/PasswordField";
import passwordStore from "../../zustand/passwordStore";
import "../Login/Login.css";

const ResetPassword = () => {
  const {
    resetPasswordFormData,
    resetPasswordFormErrors,
    setResetPasswordFormData,
    validateResetPasswordField,
    validateResetPasswordForm,
    updateNewPassword,
    loading,
  } = passwordStore();
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get("oobCode");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setResetPasswordFormData(name, value); // Update the state in the store\
    validateResetPasswordField(name, value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = await validateResetPasswordForm();
    if (isValid) {
      await updateNewPassword(
        oobCode,
        resetPasswordFormData.newPassword,
        navigate
      );
      setResetPasswordFormData("newPassword", "");
      setResetPasswordFormData("confirmPassword", "");
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <section className="login_sec_new">
      <div className="singin_sec_new_left">
          <div className="post-banner">
            <h2>Welcome to Haire </h2>
            <p>Experience Smart Recruitment in AI-Driven Recruitment Excellence.</p>
          </div>
          <div className="singin_sec_new_text">
              <div className="a">
                <h4 className="signin_qoutes">
                  Step into the future of hiring with our AI tool that not only
                  matches candidates to ideal positions but also initiates smart
                  interviews.
                </h4>
              </div>
          </div>
          <img src={new_bg} alt="" />
          {/* <img src={sign_in} alt="" /> */}
        </div>
        <div className="right-wrapper">
          <div className="logo">
            <img
              className="haire-logo"
              src={haire_gif_new}
              alt=""
              onClick={() => navigate("/", { replace: true })}
            />
          </div>

          <hr className="spacer50px" />
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <label
                style={{
                  width: "100%",
                }}
              >
                <div className="label-wrapper">
                  Create Password <span className="required">*</span>
                </div>
                <PasswordField
                  name={"newPassword"}
                  value={resetPasswordFormData.newPassword}
                  placeholder={"Enter password"}
                  showPassword={showPassword}
                  togglePasswordVisibility={togglePasswordVisibility}
                  onChange={handleChange}
                />
                {resetPasswordFormErrors.newPassword && (
                  <div className="error">
                    {resetPasswordFormErrors.newPassword}
                  </div>
                )}
              </label>
            </div>
            <div className="form-row">
              <label
                style={{
                  width: "100%",
                }}
              >
                <div className="label-wrapper">
                  Confirm Password <span className="required">*</span>
                </div>
                <PasswordField
                  name={"confirmPassword"}
                  value={resetPasswordFormData.confirmPassword}
                  placeholder={"Confirm your password"}
                  showPassword={showConfirmPassword}
                  togglePasswordVisibility={toggleConfirmPasswordVisibility}
                  onChange={handleChange}
                />
                {resetPasswordFormErrors.confirmPassword && (
                  <div className="error">
                    {resetPasswordFormErrors.confirmPassword}
                  </div>
                )}
              </label>
            </div>
            <div className="button-wrapper">
              <div className="login_btn_blue">
                <button type="submit">
                  {loading ? (
                    <ThreeCircles
                      visible={true}
                      height="20"
                      width="20"
                      color="#fff"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    "Save Password"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
