export default function getFirebaseErrorMessage(errorCode: string): string {
  switch (errorCode) {
    case "auth/invalid-phone-number":
      return "Invalid phone number. Please enter a correct phone number.";
    case "auth/quota-exceeded":
      return "SMS quota exceeded. Please try again later.";
    case "auth/user-disabled":
      return "Your account has been disabled by an administrator.";
    case "auth/operation-not-allowed":
      return "Phone sign-in is disabled. Contact support.";
    case "auth/too-many-requests":
      return "We have detected too many requests from your device. Please take a break and try again later.";
    // Add more cases as needed for different Firebase errors
    default:
      return "An unexpected error occurred. Please try again.";
  }
}

export function getFirebaseVerificationErrorMessage(errorCode: string): string {
  switch (errorCode) {
    case "auth/invalid-verification-code":
      return "The verification code entered is incorrect. Please try again.";
    case "auth/missing-verification-code":
      return "The verification code is missing. Please enter the code and try again.";
    case "auth/verification-code-expired":
      return "The verification code has expired. Please request a new code.";
    case "auth/account-exists-with-different-credential":
      return "Account with this number already exists. Please use another number.";
    case "auth/too-many-requests":
      return "We have detected too many requests from your device. Please wait before trying again.";
    default:
      return "An error occurred during the verification process. Please try again.";
  }
}

export function getFirebaseLoginError(errorCode: string): string {
  switch (errorCode) {
    case "auth/user-not-found":
    case "auth/wrong-password":
      return "Invalid email or password. Please try again.";
    case "auth/user-disabled":
      return "Your account has been disabled.";

    case "auth/too-many-requests":
      return "Too many unsuccessful login attempts. Please try again later.";

    case "auth/network-request-failed":
      return "Network error. Please check your connection and try again.";

    case "auth/invalid-credential":
      return "The provided credential is invalid. Please try again or use a different account.";

    case "auth/unverified-email":
      return "Please verify your email to Login to your account.";

    default:
      return "An error occurred. Please try again later.";
  }
}
