import React from "react";
import "./commonComponant1.css";
import { MaintainancePageImg } from "../../assets/images/images";
const MaintainancePage = () => {
  return (
    <section className="container">
      <div className="maintainance_main margin_top_md">
        <img src={MaintainancePageImg} alt="page not found" />
        <h3>The site is currently down for maintenance.</h3>
        <p>
          We apologies for the inconvenience caused.
          <br />
          We are almost done.
        </p>
        <button className="Pagenotfoundbackbtn">Go Back</button>
      </div>
    </section>
  );
};

export default MaintainancePage;
