import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateJobDetails } from "../../../../redux/reducers/jobReducer";
import InterviewBudgetControl from "./InterviewBudgetControl";

const JobDetailsFrom3 = ({ form, setIsNextButtonDisabled }) => {
  // const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [value, setValue] = useState(15);
  const jobDetails = useSelector((state) => state.job.details);
  const token = useSelector((state) => state.auth.token);

  const onValuesChange = (_, allValues) => {
    dispatch(updateJobDetails(allValues));
  };
  const onFinish = (values) => { };

  return <InterviewBudgetControl form={form} setIsNextButtonDisabled={setIsNextButtonDisabled} />;
};

export default JobDetailsFrom3;
