import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom"; // Ensure Link is imported
import {
  gift_fill,
  job_post1,
  job_post3,
  job_post4,
  job_post_img,
} from "../../../../assets/images/images";
import { fetchCompanyDetails } from "../../../../redux/actions/companyActions";
import { fetchCount } from "../../../../redux/actions/dashboardCountActions";
import {
  resetJobDraftState,
  setDraftSaved,
} from "../../../../redux/reducers/jobReducer";
import { isEmpty } from "../../../../utils/utils";
import "./DashboardMain.css";
import RecentJobsTable from "./RecentJobsTable";
import SkeletonLoader from "../CommonRecruiterComponant/SkeletonLoader";
import { resetCompany } from "../../../../redux/reducers/companyReducer";
import { zeroCreditError } from "../../../../utils/constants";
import { toast } from "react-toastify";

const DashboardMain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const count = useSelector((state) => state.count.count);
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const companyDetails = useSelector((state) => state?.company?.details);
  const credits = companyDetails?.company_credits ?? 0;
  const isDraftSaved = useSelector((state) => state.job.isDraftSaved);
  const isCreditsAlloted = useSelector(
    (state) => state?.auth?.user?.creditsAlloted
  );
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const companyID = user.company_id ?? companyDetails?.id;

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchCount(token));
      if (companyID) {
        await dispatch(fetchCompanyDetails(companyID, token, false));
      } else {
        dispatch(resetCompany());
      }
      setDataFetched(true);
    };
    fetchData();
  }, [dispatch, token, companyID]);

  useEffect(() => {
    if (dataFetched) {
      if (isEmpty(companyDetails)) {
        if (window.innerWidth > 767) {
          if (isCreditsAlloted) {
            setShow(true);
          }
          setShowModal(true);
        } else {
          setShow(false);
          setShowModal(false);
        }
      } else {
        setShowModal(false);
      }
    }
  }, [companyDetails, dataFetched]);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleClick = () => {
    dispatch(setDraftSaved(false));
    navigate("/dashboard/my-jobs?tab=draftJobs");
  };

  const postJob = () => {
    if (!companyDetails?.id) {
      dispatch(resetCompany());
      navigate("/company-details", { state: { isEmpty: true } });
    } else if (credits === 0) {
      toast.error(zeroCreditError);
    } else {
      dispatch(resetJobDraftState());
      navigate("/dashboard/job-details");
    }
  };

  const handleCreditsClick = () => {
    if (!companyDetails?.id) {
      dispatch(resetCompany());
      navigate("/company-details", { state: { isEmpty: true } });
    }
  };

  if (!dataFetched) {
    return <SkeletonLoader excludeDetails={true} includeDashboard={true} />;
  }

  return (
    <div className="add_company_popup_main_section">
      <Modal
        show={showModal}
        onHide={handleClose}
        className="add_company_popup_main"
      >
        {/* Modal content here */}
        <div className="add_com_det_sec">
          <p>
            Ensure your company details are filled out to begin
            <br />
            posting jobs and strengthen your profile presence.
          </p>
          <div className="btn_comp_sec">
            <Link className="btn_comp_sec_btn" to={"/company-details"}>
              <span>
                <i className="fas fa-plus"></i>
              </span>
              Add Company Details
            </Link>
          </div>
        </div>
        <div className="skip_to" onClick={handleClose}>
          {/* Skip for now <FaArrowRight className="skip-arrow" /> */}
          Skip for now{" "}
          <i className="fas fa-long-arrow-alt-right skip-arrow"></i>
        </div>
      </Modal>

      <div className="company_details_sec_new_inner_right">
        <div className="company_details_sec_new_inner_right_head">
          {user ? (
            <p className="header_font">Welcome {user.full_name} </p>
          ) : (
            <p className="header_font">Please log in</p>
          )}
        </div>
        <div className="mobile_head_btn">
          <button onClick={postJob} className="post_blue_btn">
            <span>
              <i className="fas fa-plus"></i>
            </span>
            Post a Job
          </button>
        </div>
        <div className="company_details_sec_new_inner_right_card">
          <div className="company_details_single_card_box">
            <div className="card_bx_img">
              <img src={job_post_img} alt="" />
            </div>
            <div className="card_bx_cont_sec">
              <h5>{count?.total_jobs}</h5>
              <h6>Job Posted</h6>
            </div>
          </div>
          <div className="company_details_single_card_box">
            <div className="card_bx_img" style={{ background: "#FFF7E1" }}>
              <img src={job_post4} alt="" />
            </div>
            <div className="card_bx_cont_sec">
              <h5>{count?.total_job_applications}</h5>
              <h6>Job Applications</h6>
            </div>
          </div>
          <div className="company_details_single_card_box">
            <div className="card_bx_img" style={{ background: "#F5E6FD" }}>
              <img src={job_post3} alt="" />
            </div>
            <div className="card_bx_cont_sec">
              <h5>{count?.shortlisted}</h5>
              <h6>Shortlisted</h6>
            </div>
          </div>

          <div className="company_details_single_card_box">
            <div className="card_bx_img" style={{ background: "#FC573B1A" }}>
              <img src={job_post1} alt="" />
            </div>
            <div className="card_bx_cont_sec">
              <h5>{count?.rejected}</h5>
              <h6>Rejected</h6>
            </div>
          </div>
        </div>

        {isDraftSaved && (
          <div className="company_details_sec_draft_main">
            <div className="job_drafted">
              <p>
                Your job has been successfully saved in the draft section. Take
                a moment to review and finalize the details before publishing.
              </p>
              <button className="btn view-job-btn" onClick={handleClick}>
                View Job{" "}
              </button>
            </div>
          </div>
        )}

        {show && (
          <div className="company_details_sec_draft_main">
            <div className="job_drafted">
              <div>
                <div className="grilling">
                  <img src={gift_fill} />
                  <p
                    className="header_font"
                    style={{
                      fontSize: "large",
                      fontWeight: 700,
                      color: "#0B4A96",
                    }}
                  >
                    You've received 5 FREE interview credits!{" "}
                  </p>
                </div>
                <div style={{ paddingLeft: "30px" }}>
                  <p>
                    Credits will only be deducted after the interview . Enhance
                    your recruiting process today!
                  </p>
                </div>
              </div>
              <br />

              <button className="btn view-job-btn" onClick={handleCreditsClick}>
                Activate{" "}
              </button>
            </div>
          </div>
        )}
        <div className="recent_jobs_cont_jovs">
          <p className="header_font recent_job_head">Recent Job Posts </p>
        </div>

        <RecentJobsTable />
      </div>
    </div>
  );
};

export default DashboardMain;
