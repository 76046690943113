// @ts-ignore
import instance from "../../api/instance";

/**
 * Verifies the OTP sent to the user's email.
 * @param {string} email - The email address associated with the OTP.
 * @param {number} otp - The OTP to verify.
 * @returns {Promise<boolean>} - A promise that resolves if verified successfully or throws an error if not.
 */
export async function verifyEmailOtp(
  email: string,
  otp: number
): Promise<boolean> {
  const verificationResponse = await instance.post(
    `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS}/verifyOtp`,
    { email, otp },
    { headers: { "Content-Type": "application/json" } }
  );

  if (!verificationResponse.data.verified) {
    throw new Error(
      verificationResponse.data.message || "Failed to verify OTP"
    );
  }

  return verificationResponse.data.verified;
}
