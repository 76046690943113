import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pause_vid, play_vid, Thumbnail } from "../../../assets/images/images";
import "../../Home/home.css";
import "./vedio.css";

const HaireVideo = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    const video = videoRef.current;
    video.play();
    setIsPlaying(true);
  };

  const handlePause = () => {
    const video = videoRef.current;
    video.pause();
    setIsPlaying(false);
  };

  const handleClick = (event) => {
    event.preventDefault();
    localStorage.setItem("userType", "recruiter");
    navigate("/sign-up");
  };

  return (
    <section className="video_sec_home_new">
      <div className="container-fluid">
        <div className="col-lg-12 col-md-12 d-flex justify-content-center">
          <div
            className={`haire-landing-video position-relative ${
              isPlaying ? "playing" : ""
            }`}
          >
            <video
              ref={videoRef}
              className="video-player wow animate__zoomIn mb-3"
              loop
              data-wow-duration="3s"
              poster={Thumbnail}
            >
              <source
                src={"https://haire.s3.ap-south-1.amazonaws.com/Haire_vid.mp4"}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            {!isPlaying && (
              <button
                className="center-button play-button"
                onClick={handlePlay}
              >
                <img src={play_vid} alt="play button" />
              </button>
            )}
            {isPlaying && (
              <button
                className="center-button pause-button"
                onClick={handlePause}
              >
                <img src={pause_vid} alt="Pause button" />
              </button>
            )}
            <div
              className="ai_enhave_btn_sec wow animate__zoomIn"
              data-wow-duration="3s"
            >
              <button className="try-button Ready-btn" onClick={handleClick}>
                Ready to hire?
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HaireVideo;
