import React, { useState } from "react";
import "./ResumeGenerator.css";
import { useNavigate } from "react-router-dom";
import { TextField, Chip, Box, Autocomplete } from "@mui/material";
import DashboardHeader from "../../../../components/CommonComponents/DashboardHeader";
import { Navbar } from "../../../../components/DashboardComponents/Navbar/Navbar";

// const suggestions = [
//   "Figma",
//   "Adobe",
//   "Photoshop",
//   "After Effects",
//   "Wireframing",
//   "Interaction Design",
//   "Prototyping",
//   "User Research",
//   "Affinity Mapping",
// ];

const suggestions = [];

const ResumeGenerator = () => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [skills, setSkills] = useState([]);

  const handleAddSkill = (event, newSkill) => {
    if (newSkill && !skills.includes(newSkill)) {
      setSkills([...skills, newSkill]);
    }
    setInputValue("");
  };

  const handleDeleteSkill = (skillToDelete) => {
    setSkills(skills.filter((skill) => skill !== skillToDelete));
  };

  const handleClick = (e) => {
    e.preventDefault();
    navigate("/resume-picker");
  };

  const handleBackButtonClick = () => {
    navigate("/resume-builder", { replace: true });
  };
  return (
    <>
      <Navbar />

      <section className="head_sec_pro_rect">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <DashboardHeader
                title={"AI Resume Generator"}
                backButtonLink={handleBackButtonClick}
              />
            </div>
          </div>
        </div>
      </section>

      <div
        className="resume_generator_form"
        style={{ maxWidth: "850px", margin: "auto" }}
      >
        <form className="resume_generator_form-container">
          <div className="row mt-3">
            <div className="col">
              <label htmlFor="fullName">
                Full Name <span>*</span>
              </label>
              <input
                type="text"
                id="fullName"
                className="form-control"
                placeholder="Enter Full Name"
                required
              />
            </div>
            <div className="col">
              <label htmlFor="jobProfile">
                Job Profile <span>*</span>
              </label>
              <input
                type="text"
                id="jobProfile"
                className="form-control"
                placeholder="Enter Job Profile"
                required
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col">
              <label htmlFor="experience">
                Years of Experience <span>*</span>
              </label>
              <input
                type="text"
                id="experience"
                className="form-control"
                placeholder="Enter Experience"
                required
              />
            </div>
            <div className="col">
              <label htmlFor="mobileNo">
                Mobile No <span>*</span>
              </label>
              <div className="input-group">
                <input
                  type="tel"
                  id="mobileNo"
                  className="form-control"
                  placeholder="Enter mobile no"
                  required
                />
                <button type="button" className="btn btn-primary verify-btn ">
                  Verify
                </button>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <label htmlFor="skills">
                  Skills <span>*</span>
                </label>
                <Autocomplete
                  freeSolo
                  value={inputValue}
                  onChange={handleAddSkill}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  options={suggestions.filter(
                    (option) => !skills.includes(option)
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Skills"
                      placeholder="Enter Skills"
                    />
                  )}
                  renderTags={() => null}
                  inputValue={inputValue}
                />

                <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                  {skills.map((skill, index) => (
                    <Chip
                      key={index}
                      label={skill}
                      onDelete={() => handleDeleteSkill(skill)}
                      color="primary"
                    />
                  ))}
                </Box>
                <label>Suggestions</label>
                <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
                  {suggestions.map((suggestion, index) => (
                    <Chip
                      key={index}
                      label={suggestion}
                      onClick={() => handleAddSkill(null, suggestion)}
                      variant="outlined"
                    />
                  ))}
                </Box>
              </div>
            </div>

            <div className="row">
              <div className="col d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-primary proceed_btn"
                  onClick={handleClick}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ResumeGenerator;
