import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Navbar } from "../../../../components/DashboardComponents/Navbar/Navbar";
import "./ResumeCustomize.css";

import { message, Upload } from "antd";

import { down_img, resume_ske, up_icn } from "../../../../assets/images/images";
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

// const items = [
//   {
//     label: <a href="https://www.antgroup.com">1st menu item</a>,
//     key: "0",
//   },
//   {
//     label: <a href="https://www.aliyun.com">2nd menu item</a>,
//     key: "1",
//   },
//   {
//     type: "divider",
//   },
//   {
//     label: "3rd menu item",
//     key: "3",
//   },
// ];

const ResumeCustomize = () => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading}
      <div
        style={{
          marginTop: 8,
        }}
      >
        <div>
          <img src={up_icn} alt="up arrow" />
        </div>
      </div>
    </button>
  );

  return (
    <>
      <Navbar />
      <section className="resume_temp_cous">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="resume_temp_cous_inner">
                <div className="resume_temp_cous_inner_left">
                  <div className="resume_temp_cous_inner_left_head">
                    <h6>
                      <button>
                        <i className="fas fa-chevron-left"></i>Details
                      </button>
                    </h6>
                    <Link to="/resume-customize">
                      <img src={down_img} alt="" />
                    </Link>
                  </div>
                  <div className="resume_temp_cous_inner_left_body">
                    <div className="accordion acc_sec_new" id="myAccordion">
                      <div className="accordion-item acc_single">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            type="button"
                            className="accordion-button acc_btn"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                          >
                            Personal Details
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#myAccordion"
                        >
                          <div className="acc_single_body_sec">
                            <div className="acc_single_body_sec_head">
                              <h6>Personal Details</h6>
                              <div className="acc_single_body_sec_body">
                                <div className="acc_single_body_sec_body_upload">
                                  <div className="acc_singl_up_btn">
                                    <Upload
                                      name="avatar"
                                      listType="picture-circle"
                                      className="avatar-uploader"
                                      showUploadList={false}
                                      action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                      beforeUpload={beforeUpload}
                                      onChange={handleChange}
                                    >
                                      {imageUrl ? (
                                        <img
                                          src={imageUrl}
                                          alt="avatar"
                                          style={{
                                            width: "100%",
                                          }}
                                        />
                                      ) : (
                                        uploadButton
                                      )}
                                    </Upload>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item acc_single">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            type="button"
                            className="accordion-button collapsed acc_btn"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                          >
                            Work Experience
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#myAccordion"
                        >
                          <div className="card-body">
                            <p>
                              Bootstrap is a sleek, intuitive, and powerful
                              front-end framework for faster and easier web
                              development. It is a collection of CSS and HTML
                              conventions.{" "}
                              <a href="https://www.tutorialrepublic.com/twitter-bootstrap-tutorial/">
                                Learn more.
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="resume_temp_cous_inner_right">
                  <div className="resume_temp_cous_inner_right_img">
                    <img src={resume_ske} alt="" />
                  </div>
                  <div className="resume_temp_cous_inner_right_btn">
                    <button>Proceed</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResumeCustomize;
