import { Button, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { edit } from "../../../../../assets/images/images";
import { useCallback, useEffect, useState } from "react";
import {
  fetchBillingInfo,
  updateBillingInfo,
} from "../../../../../redux/actions/billingActions";

const BillingInformation = ({ addPayment, editBilling }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user);
  const token = useSelector((state) => state?.auth?.token);
  const companyDetails = useSelector((state) => state?.company?.details);
  const billingInfo = useSelector((state) => state?.billingInfo?.details);

  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    // This useEffect ensures that the form is updated with the latest billing information
    if (billingInfo) {
      form.setFieldsValue({
        full_name: billingInfo?.full_name,
        company_name: billingInfo?.company_name,
        country: billingInfo?.country,
        address: billingInfo?.address,
        city: billingInfo?.city,
        state: billingInfo?.state,
        pin: billingInfo?.pin,
        tax_id: billingInfo?.tax_id,
      });
    }
  }, [billingInfo, companyDetails, form, user]);

  const toggleEdit = useCallback(() => {
    setIsEditMode((prevMode) => !prevMode);
    if (!isEditMode) {
      setFormValid(
        form.getFieldsError().filter(({ errors }) => errors.length).length === 0
      );
    } else {
      // Reset form to initial values when user cancels editing
      form.resetFields();
    }
  }, [form, isEditMode]);

  const onPlaceSelected = (place) => {
    const addressComponents = place.address_components;
    const country = addressComponents.find((component) =>
      component.types.includes("country")
    );
    form.setFieldsValue({ country: country.long_name });
  };

  const handleSaveChanges = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        dispatch(updateBillingInfo(token, values)) // Assuming token is part of the user object
          .then(() => {
            message.success("Billing information updated successfully!");
            dispatch(fetchBillingInfo(token));
            toggleEdit();
          })
          .catch((error) => {
            message.error(
              "Failed to update billing information: " + error.message
            );
          });
      })
      .catch((info) => {
        console.error("Validate Failed:", info);
      });
  }, [form, toggleEdit]);

  const checkFormValidation = useCallback(() => {
    const hasErrors = form
      .getFieldsError()
      .some((field) => field.errors.length > 0);
    setFormValid(!hasErrors);
  }, [form]);

  return (
    <main className="main-container">
      <section className="billing-info">
        <header className="section-header1">
          <h5>Billing Information </h5>
        </header>
        <div className="billing-form">
          <Form
            form={form}
            name="billing-form"
            className="job_details_from billing_form"
            initialValues={{
              full_name: billingInfo?.full_name,
              company_name: billingInfo?.company_name,
              country: billingInfo?.country,
              address: billingInfo?.address,
              city: billingInfo?.city,
              state: billingInfo?.state,
              pin: billingInfo?.pin,
              tax_id: billingInfo?.tax_id,
            }}
            onFieldsChange={checkFormValidation}
          >
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-6 text-start">
                    <Form.Item
                      name="full_name"
                      label="Full name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your full name!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        disabled={!isEditMode}
                        style={isEditMode ? {} : { color: "black", opacity: 1 }}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-6 text-start">
                    <Form.Item
                      name="company_name"
                      label="Company name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Company name!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        disabled={!isEditMode}
                        style={isEditMode ? {} : { color: "black", opacity: 1 }}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 text-start">
                    <Form.Item
                      name="country"
                      label="Country or Region"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Country!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder="India"
                        disabled={!isEditMode}
                        style={isEditMode ? {} : { color: "black", opacity: 1 }}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-6 text-start">
                    <Form.Item
                      name="address"
                      label="Billing Address"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Billing Address!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        disabled={!isEditMode}
                        style={isEditMode ? {} : { color: "black", opacity: 1 }}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 text-start">
                    <Form.Item
                      name="city"
                      label="City"
                      rules={[
                        {
                          required: true,
                          message: "Please input your City!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        disabled={!isEditMode}
                        style={isEditMode ? {} : { color: "black", opacity: 1 }}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-6 text-start">
                    <Form.Item
                      name="state"
                      label="State"
                      rules={[
                        {
                          required: true,
                          message: "Please input your State!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder="State"
                        disabled={!isEditMode}
                        style={isEditMode ? {} : { color: "black", opacity: 1 }}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 text-start">
                    <Form.Item
                      name="pin"
                      label="Zip"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Zip code!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Zip Code"
                        disabled={!isEditMode}
                        style={isEditMode ? {} : { color: "black", opacity: 1 }}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-6 text-start">
                    <Form.Item
                      name="tax_id"
                      label="Tax ID / GST (Optional)"
                      rules={[
                        {
                          required: false,
                          message: "Please input your Zip code!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Tax ID / GST"
                        disabled={!isEditMode}
                        style={isEditMode ? {} : { color: "black", opacity: 1 }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </Form>
          <div className="text-end mtb button_section">
            <button className="edit_btn" onClick={toggleEdit}>
              {isEditMode ? "Cancel" : "Edit"}
            </button>
            {isEditMode && (
              <button
                className="Add_card_btn"
                onClick={handleSaveChanges}
                disabled={!formValid}
              >
                Save
              </button>
            )}
          </div>
        </div>
        {/* <div className="row">
          <header className="section-header">
            <h4>Payment Method</h4>
          </header>
          <div className="col-6">
            <div className="payment-methods">
              <div className="payment-details">
                <div className="payment-card">
                  <div>
                    <img src={visa} alt="" />
                  </div>
                  <div>
                    <span className="card-number">**** **** **** 0000</span>
                    <span className="expiry-date">Expiry 10/24</span>
                  </div>
                </div>
                <div className="payment-card-data">
                  <div>
                    <Radio>Default Payment</Radio>
                  </div>
                  <div>
                    <img src={del} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6" onClick={addPayment}>
            <div className="payment-details-add">
              <div className="Add_det">+ Add Payment Method</div>
            </div>
          </div>
        </div> */}
      </section>
    </main>
  );
};

export default BillingInformation;
