import { Progress } from "antd";
import "../../pages/SignIn/OtpVerificationModal.css";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import TranscriptionTest from "../../components/CustomComponents/microphone";
import { useNavigate, useParams } from "react-router-dom";

const TryHaireModals = () => {
  const navigate = useNavigate();
  const { interviewId,jobProfile,company } = useParams();
  const [progress, setProgress] = useState(0);
  const [audio, setAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasPlayedOnce, setHasPlayedOnce] = useState(false);
  const [showSpeakerModal, setShowSpeakerModal] = useState(true);
  const [showMicrophoneModal, setShowMicrophoneModal] = useState(false);

  const twoColors = {
    "0%": "rgba(24, 101, 193, 1)",
  };

  useEffect(() => {
    const audioObj = new Audio(
      `https://haire.s3.ap-south-1.amazonaws.com/development/test.wav`
    );
    audioObj.loop = true;
    audioObj.addEventListener("ended", () => {
      audioObj.currentTime = 0;
      audioObj.play();
    });
    setAudio(audioObj);
    return () => {
      if (audio) {
        audio.pause();
      }
      if (audioObj) {
        audioObj.pause();
        audioObj.currentTime = 0;
      }
    };
  }, []);

  const startSpeakerTest = () => {
    if (!audio) {
      return;
    }
    if (!isPlaying) {
      setHasPlayedOnce(true);
    }
    audio.paused ? audio.play() : audio.pause();
    setIsPlaying(!isPlaying);
  };

  const handleUserSpeakerConfirmation = () => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }
    setIsPlaying(false);
    setShowSpeakerModal(false);
    setShowMicrophoneModal(true);
  };

  useEffect(() => {
    let intervalId;
    if (isPlaying) {
      intervalId = setInterval(() => {
        setProgress((oldProgress) => {
          const newProgress =
            oldProgress +
            (Math.random() > 0.5 ? 1 : -1) * (5 + Math.random() * 10);
          return Math.min(100, Math.max(0, newProgress));
        });
      }, 200);
    } else {
      clearInterval(intervalId);
      setProgress(0);
    }
    return () => clearInterval(intervalId);
  }, [isPlaying]);

  const handleClick = () => {
    navigate(`/try-haire/interview/${interviewId}/${jobProfile}/${company}`);
  };

  return (
    <>
      <div className="fullscreen-bg"></div>
      <div className="content">
        {showSpeakerModal && (
          <Modal
            show={true}
            onHide={() => {}}
            backdrop="static"
            keyboard={false}
            className="verifictaion_mod bg-hairo"
          >
            <div className="InterviewModal_main">
              <div className="InterviewModal_main_inner">
                <div className="test_speaker">
                  <h2>Test Your Speaker</h2>
                </div>
                <div className="output_level">
                  <p>Output Level</p>
                  <div className="custom-progress">
                    <Progress
                      percent={progress}
                      strokeColor={twoColors}
                      showInfo={false}
                    />
                  </div>
                </div>
              </div>
              <div className="space-bt">
                <button className="clear_bg_btn" onClick={startSpeakerTest}>
                  {isPlaying ? "Pause" : "Play"}
                </button>
                <button
                  className={`blue_bg_btn ${hasPlayedOnce ? "" : "disabled"}`}
                  onClick={handleUserSpeakerConfirmation}
                  disabled={!hasPlayedOnce}
                >
                  Done
                </button>
              </div>
            </div>
          </Modal>
        )}
        {showMicrophoneModal && (
          <Modal
            show={true}
            onHide={() => {}}
            backdrop="static"
            keyboard={false}
            className="verifictaion_mod"
          >
            <TranscriptionTest onDone={handleClick} />
          </Modal>
        )}
      </div>
    </>
  );
};

export default TryHaireModals;
