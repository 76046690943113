import { Avatar, Card, Drawer, Form, Pagination, Skeleton, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../pages/Dashboard/RecruiterDashboard/MyJobs/MyJobs.css";
import JobCard from "../CommonComponents/JobCard";
import NoJobApplicantFound from "../CommonComponents/NoJobApplicantFound";
import { Navbar, PublicNavbar } from "../DashboardComponents/Navbar/Navbar";
import "./ApplicationDashboard.css";
import ResposiveContainer from "../../pages/Dashboard/RecruiterDashboard/CommonRecruiterComponant/ResponsiveContainer";
import { fetchJobsListJobSeeker } from "../../redux/actions/jobActions";

import { useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  FilterAi,
  Map_img,
  Search_grey,
  filter_logo,
  white_search,
} from "../../assets/images/images";
import { Helmet } from "react-helmet";
import useResponsiveCount from "../../hooks/useResponsiveCount";
import useQueryParams from "../../hooks/useQueryParams";
import { debounce } from "../../utils/utils";

const ApplicantDashboard = () => {
  const routeLocation = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [drawer, setDrawer] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const totalItems = useSelector((state) => state?.job?.totalCount);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  //get all query params
  const {
    activeTab = "allJobs",
    status = 1,
    currentPage = 1,
    workMode = "",
    experience = "",
    criteria = "",
    searchTerm = "",
    location = "",
    minSalary = "",
    maxSalary = "",
  } = useQueryParams();

  const {
    applicantJobsList: allJobData,
    appliedJobsList: appliedJobData,
    applicantRecommendJobsList: recommendedJobData,
  } = useSelector((state) => state.job);

  const [loading, setLoading] = useState(true);
  const itemsPerPage = 12;

  const tabStyle = {
    borderRadius: "20px",
    border: "2px dashed #blue",
    padding: "10px 20px",
    cursor: "pointer",
    marginRight: "10px",
    width: "200px",
    fontSize: "16px",
    fontWeight: "700",
  };

  const activeTabStyle = {
    ...tabStyle,
    backgroundColor: "#DFEEFF",
    color: "#1865C1",
    width: "200px",
  };

  const generateQueryString = (params) => {
    const queryString = new URLSearchParams({
      activeTab,
      status,
      currentPage,
      workMode,
      experience,
      criteria,
      searchTerm,
      location,
      minSalary,
      maxSalary,
      ...params,
    }).toString();
    return `${token ? "/applicant-dashboard" : "/jobs"}?${queryString}`;
  };

  const handlePageChange = (currentPage) => {
    const queryString = generateQueryString({ currentPage });
    navigate(queryString);
  };

  const handleTabClick = (newStatus, newActiveTab) => {
    const queryString = generateQueryString({
      status: newStatus,
      activeTab: newActiveTab,
      currentPage: 1,
    });
    navigate(queryString);
  };

  const handleSearchChange = debounce((event) => {
    const queryString = generateQueryString({
      searchTerm: event.target.value,
      currentPage: 1,
    });
    navigate(queryString);
  }, 300);

  const handleLocationChange = debounce((event) => {
    const queryString = generateQueryString({
      location: event.target.value,
      currentPage: 1,
    });
    navigate(queryString);
  }, 300);

  const handleWorkChange = (workMode) => {
    const queryString = generateQueryString({ workMode, currentPage: 1 });
    navigate(queryString);
  };

  const handleExperience = (experience) => {
    const queryString = generateQueryString({ experience, currentPage: 1 });
    navigate(queryString);
  };

  const handleSortChange = (criteria) => {
    const queryString = generateQueryString({ criteria, currentPage: 1 });
    navigate(queryString);
  };

  const handleSalary = (minSalary, maxSalary) => {
    const queryString = generateQueryString({
      minSalary,
      maxSalary,
      currentPage: 1,
    });
    navigate(queryString);
  };

  const handleReset = () => {
    navigate(
      `${
        token
          ? `/applicant-dashboard?status=${status}&activeTab=${activeTab}`
          : `/jobs?status=1`
      }`
    );
  };

  const handleSubmit = (event) => {
    // Handle submit logic if needed
    setLoading(true);
    dispatch(
      fetchJobsListJobSeeker(
        token,
        status,
        searchTerm,
        location,
        criteria,
        workMode,
        maxSalary,
        minSalary,
        experience,
        currentPage
      )
    );
  };

  useEffect(() => {
    setLoading(true);

    if (!token) {
      dispatch(
        fetchJobsListJobSeeker(
          token,
          1,
          searchTerm,
          location,
          criteria,
          workMode,
          maxSalary,
          minSalary,
          experience,
          currentPage
        )
      );
    } else {
      dispatch(
        fetchJobsListJobSeeker(
          token,
          status,
          searchTerm,
          location,
          criteria,
          workMode,
          maxSalary,
          minSalary,
          experience,
          currentPage
        )
      );
    }
  }, [
    token,
    activeTab,
    status,
    currentPage,
    workMode,
    experience,
    criteria,
    searchTerm,
    location,
    minSalary,
    maxSalary,
  ]);

  useEffect(() => {
    setDataToDisplay(allJobData);
  }, [allJobData]);

  useEffect(() => {
    setDataToDisplay(appliedJobData);
  }, [appliedJobData]);

  useEffect(() => {
    setDataToDisplay(recommendedJobData);
  }, [recommendedJobData]);

  useEffect(() => {
    // Simulate a delay for loading state
    if (dataToDisplay.length > 0) {
      setLoading(false);
    }
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay time as needed

    return () => clearTimeout(timer);
  }, [dataToDisplay]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const onJobCardClick = (jobId) => {
    if (activeTab)
      if (token) {
        navigate(`/applicant-dashboard/related-jobs/${jobId}`, {
          state: { currentPage },
        });
      } else {
        navigate(`/jobs/related-jobs/${jobId}`);
      }
  };
  const showDrawer = () => {
    setDrawer(true);
  };
  const onClose = () => {
    setDrawer(false);
  };
  return (
    <>
      <Helmet>
        <title>Discover AI-Powered Job Opportunities | Haire Jobs</title>
        <meta
          name="description"
          content="Find AI-driven job listings and career opportunities with Haire.ai's innovative AI interview and recruitment solutions"
        />
        <link rel="canonical" href="https://haire.ai/jobs" />
      </Helmet>
      {token ? <Navbar /> : <PublicNavbar />}
      <section className="Dashboard_main_section">
        <div className="container-fluid">
          {routeLocation.pathname === "/applicant-dashboard" && (
            <div className="row">
              <div className="col-6 menulist menutabs-wd">
                <div className="d-flex justify-content-between">
                  <div
                    className="text-center"
                    style={
                      activeTab === "recommended_jobs"
                        ? activeTabStyle
                        : tabStyle
                    }
                    onClick={() => handleTabClick(3, "recommended_jobs")}
                  >
                    Recommended Jobs
                  </div>
                  <div
                    className="text-center"
                    style={activeTab === "allJobs" ? activeTabStyle : tabStyle}
                    onClick={() => handleTabClick(1, "allJobs")}
                  >
                    All Jobs
                  </div>
                  <div
                    className="text-center"
                    style={
                      activeTab === "appliedJobs" ? activeTabStyle : tabStyle
                    }
                    onClick={() => handleTabClick(2, "appliedJobs")}
                  >
                    Applied Jobs
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row mt-4 flex-justify-center">
            <div className="col-lg-6 col-md-4 dw-4">
              <div className="Search_jobs">
                <img src={Search_grey} alt="search_job" />
                <input
                  type="search"
                  className="form-control shadow-sm shadow_pill"
                  placeholder="Search Job, Companies, Skills"
                  // value={searchTerm}
                  defaultValue={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
            <div className="col-lg-5 col-md-4 dw-4">
              <div className="Search_location">
                <img src={Map_img} alt="search_job" />
                <input
                  type="search"
                  className="form-control shadow-sm shadow_pill"
                  placeholder="Location"
                  defaultValue={location}
                  onChange={handleLocationChange}
                />
              </div>
            </div>
            <div className="col-lg-1 col-md-4">
              <div className="Search_btn">
                <button
                  type="button"
                  style={{ borderRadius: "50px" }}
                  className="btn btn-primary "
                  onClick={handleSubmit}
                >
                  <img src={white_search} alt="search_job" />
                </button>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-6 col-md-10 col-sm-12">
              <div className="count_details">
                {activeTab === "allJobs" && <h5>All Jobs</h5>}
                {activeTab === "appliedJobs" && <h5>Applied Jobs</h5>}
                {activeTab === "recommended_jobs" && <h5>Recommended Jobs</h5>}
                <h5>
                  <span className="count_details_data">
                    {loading ? (
                      <Skeleton.Input
                        style={{ width: 100 }}
                        active
                        size="small"
                      />
                    ) : (
                      `${totalItems} Jobs Found`
                    )}
                  </span>
                </h5>
              </div>
            </div>
          </div>

          {/* <div className="container my-4"> */}

          <div className="custom_filter_componant">
            <button
              className="FilterAi_btn_res"
              type="button"
              onClick={showDrawer}
            >
              <img src={FilterAi} alt="" />
              Filter
            </button>
            <Drawer
              title="Filter"
              // width={720}
              onClose={onClose}
              open={drawer}
              placement="left"
              styles={{
                body: {
                  paddingBottom: 80,
                },
              }}
              extra={
                <Space>
                  <div className="filter_btn_cont">
                    <div
                      className="text-danger resetButton"
                      style={{ cursor: "pointer" }}
                      onClick={handleReset}
                    >
                      Reset
                    </div>
                  </div>
                </Space>
              }
            >
              <div className="applicant_dashboard_filter">
                <WorkFilter
                  onWorkChange={handleWorkChange}
                  selectedValue={workMode}
                />
                <ExperienceFilter
                  onExperienceClick={handleExperience}
                  selectedValue={experience}
                />
                <SalaryFilter1
                  onSalaryChange={handleSalary}
                  selectedMinSalary={minSalary}
                  selectedMaxSalary={maxSalary}
                />
                <SortFilter
                  onSortChange={handleSortChange}
                  selectedValue={criteria}
                />
                <div className="filter_by_search_main">
                  <button className="filter_by_search" onClick={onClose}>
                    Search
                  </button>
                </div>
              </div>
            </Drawer>
          </div>

          <div className="flexRow my-4 non-responsive">
            <div className="col-lg-8 col-md-9 col-sm-12">
              <div className="flexRowRight">
                <div className=" filterclass">
                  <img src={filter_logo} alt="" />
                  Filters
                </div>

                <div className="">
                  <WorkFilter
                    onWorkChange={handleWorkChange}
                    selectedValue={workMode}
                  />
                </div>
                <div className="flexRowRight">
                  <ExperienceFilter
                    onExperienceClick={handleExperience}
                    selectedValue={experience}
                  />
                </div>
                <div className="">
                  <SalaryFilter
                    onSalaryChange={handleSalary}
                    selectedMinSalary={minSalary}
                    selectedMaxSalary={maxSalary}
                  />
                </div>

                <div
                  className="text-danger resetButton"
                  style={{ cursor: "pointer" }}
                  onClick={handleReset}
                >
                  Reset
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 dropdown-inline-container">
              {/* <span>Sort By</span> */}
              <label htmlFor="sortby-select">Sort by: </label>
              <SortFilter
                onSortChange={handleSortChange}
                selectedValue={criteria}
              />
            </div>
          </div>
          {/* </div> */}
          {loading ? (
            <JobCardSkeleton />
          ) : (
            <>
              <div>
                {dataToDisplay && dataToDisplay.length > 0 && (
                  <div className="posted_jobs_main_sec_body">
                    {dataToDisplay.map((job, index) => (
                      <JobCard
                        key={index}
                        jobData={job}
                        activeTab={activeTab}
                        onClick={() => onJobCardClick(job?.job_details?.id)}
                      />
                    ))}
                  </div>
                )}
                {dataToDisplay.length === 0 && <NoJobApplicantFound />}
              </div>
              <div className="pagination-container">
                <Pagination
                  current={currentPage}
                  onChange={handlePageChange}
                  total={totalItems}
                  pageSize={itemsPerPage}
                  showSizeChanger={false} // Optional: set to true if you want to allow changing the number of items per page
                />
              </div>
            </>
          )}
        </div>
      </section>
      {/* <div className="popUp">
        <ResposiveContainer />
      </div> */}
    </>
  );
};

export default ApplicantDashboard;

export const WorkFilter = ({ onWorkChange, selectedValue }) => {
  return (
    <>
      <div className="select-wrapper">
        <select
          className="Sortby"
          aria-label="Default select example"
          onChange={(e) => onWorkChange(e.target.value)}
          value={selectedValue ?? ""}
        >
          <option value="">Work Mode</option>
          <option value={1}>Work From Office</option>
          <option value={2}>Remote</option>
          <option value={3}>Hybrid</option>
        </select>
      </div>
    </>
  );
};

export const ExperienceFilter = ({ onExperienceClick, selectedValue }) => {
  return (
    <>
      <div className="">
        <select
          className="Sortby"
          aria-label="Default select example"
          onChange={(e) => onExperienceClick(e.target.value)}
          value={selectedValue ?? ""}
        >
          <option value="">Experience</option>
          {/* <option value="">Fresher</option> */}
          <option value="1">0-1 Years</option>
          <option value="2">1-2 Years</option>
          <option value="3">2-3 Years</option>
          <option value="4">3-4 Years</option>
          <option value="5">4-5 Years</option>
          <option value="6">5+ Years</option>
        </select>
      </div>
    </>
  );
};

export const SortFilter = ({ onSortChange, selectedValue }) => {
  return (
    <>
      <div className="">
        <select
          className="Sortby"
          aria-label="Default select example"
          onChange={(e) => onSortChange(e.target.value)}
          value={selectedValue ?? ""}
        >
          <option value="">All</option>
          <option value="-published_date">Newest Job</option>
          <option value="published_date">Oldest Job</option>
          <option value="title">A-Z</option>
          <option value="-title">Z-A</option>
        </select>
      </div>
    </>
  );
};

export const SalaryFilter = ({
  onSalaryChange,
  selectedMinSalary,
  selectedMaxSalary,
}) => {
  const [showSalaryInput, setShowSalaryInput] = useState(false);
  const [minSalary, setMinSalary] = useState(selectedMinSalary);
  const [maxSalary, setMaxSalary] = useState(selectedMaxSalary);
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSalaryInput(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    setMinSalary(selectedMinSalary);
    setMaxSalary(selectedMaxSalary);
  }, [selectedMinSalary, selectedMaxSalary]);

  const handleMinSalaryChange = (e) => {
    const newValue = e.target.value;
    setMinSalary(newValue);
    onSalaryChange(newValue, maxSalary);
  };

  const handleMaxSalaryChange = (e) => {
    const newValue = e.target.value;
    setMaxSalary(newValue);
    onSalaryChange(minSalary, newValue);
  };

  return (
    <div ref={wrapperRef}>
      <button
        className="salary-button"
        onClick={() => setShowSalaryInput(!showSalaryInput)}
      >
        Annual Salary
      </button>
      {showSalaryInput && (
        <div className="salary-input-container">
          <div className="salary-input-group">
            <label htmlFor="minSalary" className="salary-label">
              Min
            </label>
            <input
              type="number"
              id="minSalary"
              placeholder="0"
              className="salary-input"
              value={minSalary}
              onChange={handleMinSalaryChange}
            />
          </div>
          <span className="salary-dash"> - </span>
          <div className="salary-input-group">
            <label htmlFor="maxSalary" className="salary-label">
              Max
            </label>
            <input
              type="number"
              id="maxSalary"
              placeholder="30000"
              className="salary-input"
              value={maxSalary}
              onChange={handleMaxSalaryChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export const SalaryFilter1 = ({
  onSalaryChange,
  selectedMinSalary,
  selectedMaxSalary,
}) => {
  const [showSalaryInput, setShowSalaryInput] = useState(false);
  const [minSalary, setMinSalary] = useState(selectedMinSalary);
  const [maxSalary, setMaxSalary] = useState(selectedMaxSalary);
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSalaryInput(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    setMinSalary(selectedMinSalary);
    setMaxSalary(selectedMaxSalary);
  }, [selectedMinSalary, selectedMaxSalary]);

  const handleMinSalaryChange = (e) => {
    const newValue = e.target.value;
    setMinSalary(newValue);
    onSalaryChange(newValue, maxSalary);
  };

  const handleMaxSalaryChange = (e) => {
    const newValue = e.target.value;
    setMaxSalary(newValue);
    onSalaryChange(minSalary, newValue);
  };

  return (
    <div ref={wrapperRef}>
      <button className="salary-button salary-button1">Annual Salary</button>
      <div className="salary-input-container salary-input-container1 salary-input-container11">
        <div className="salary-input-group">
          <label htmlFor="minSalary" className="salary-label">
            Min
          </label>
          <input
            type="number"
            id="minSalary"
            placeholder="0"
            className="salary-input"
            value={minSalary}
            onChange={handleMinSalaryChange}
          />
        </div>
        <span className="salary-dash"> - </span>
        <div className="salary-input-group">
          <label htmlFor="maxSalary" className="salary-label">
            Max
          </label>
          <input
            type="number"
            id="maxSalary"
            placeholder="30000"
            className="salary-input"
            value={maxSalary}
            onChange={handleMaxSalaryChange}
          />
        </div>
      </div>
    </div>
  );
};

const JobCardSkeleton = () => {
  const count = useResponsiveCount();
  return (
    <div className="responsive_skeleton">
      {Array.from({ length: count }).map((_, index) => (
        <Card
          key={index}
          style={{
            width: count === 1 ? "100%" : "50%",
            height: count === 1 ? "200px" : "auto",
            borderRadius: "8px",
            border: "1px solid #d9d9d9",
            boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
          }}
          actions={[
            <Skeleton.Button
              active
              size="small"
              style={{ width: 80 }}
              shape="round"
            />,
          ]}
        >
          <Skeleton loading={true} avatar active>
            <Card.Meta
              avatar={<Avatar style={{ backgroundColor: "#f56a00" }} />}
              title={
                <Skeleton.Input style={{ width: "80%" }} active size="small" />
              }
              description={
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Skeleton.Input
                    style={{ width: "100%" }}
                    active
                    size="small"
                  />
                  <Skeleton.Input
                    style={{ width: "60%" }}
                    active
                    size="small"
                  />
                </Space>
              }
            />
          </Skeleton>
        </Card>
      ))}
    </div>
  );
};
