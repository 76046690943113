import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthUser } from "../types/authTypes";
import Cookies from "js-cookie";

export interface AuthState {
  user: AuthUser | null;
  token: string | null;
  jobseeker_id: number | null;
  isNewUser: boolean | null;
  role: number | null;
  redirectUrl: string | null;
  loading: boolean;
  error: string | null;
  temp_resume: any;
}

// export interface LoginState {
//   login: ConfirmationResult;
//   loading: boolean;
//   error: string | null;
// }
const initialState: AuthState = {
  user: null,
  token: null,
  jobseeker_id: null,
  isNewUser: null,
  role: null,
  redirectUrl: null,
  loading: false,
  error: null,
  temp_resume: null
  // login: {
  //   verificationId: "",
  //   confirm: function (verificationCode: string): Promise<UserCredential> {
  //     throw new Error("Function not implemented.");
  //   },
  // },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state: AuthState, action: PayloadAction<AuthUser>) => {
      state.user = action.payload;
      state.loading = false;
    },
    setTempResume: (state: AuthState, action: PayloadAction<AuthUser>) => {
      state.temp_resume = action.payload;
      state.loading = false;
    },
    setAuthToken: (state: AuthState, action: PayloadAction<string>) => {
      state.token = action.payload;
      Cookies.set("token", action.payload);
    },
    setRole: (state: AuthState, action: PayloadAction<number>) => {
      state.role = action.payload;
    },
    setRedirectUrl: (state: AuthState, action: PayloadAction<string>) => {
      state.redirectUrl = action.payload;
    },
    setUserName: (state: AuthState, action: PayloadAction<string>) => {
      if (state.user) {
        state.user.full_name = action.payload;
      }
    },
    setJobseekerId: (state: AuthState, action: PayloadAction<number>) => {
      state.jobseeker_id = action.payload;
    },
    setNewUser: (state: AuthState, action: PayloadAction<boolean>) => {
      state.isNewUser = action.payload;
    },
    setUserId: (state: AuthState, action: PayloadAction<string>) => {
      if (state.user) {
        state.user.user_id = action.payload;
      }
    },
    // setLoginResult: (
    //   state: LoginState,
    //   action: PayloadAction<ConfirmationResult>
    // ) => {
    //   state.login = action.payload;
    // },
    updateUserPhoneNumber: (
      state: AuthState,
      action: PayloadAction<{ countryCode: number; phone: string }>
    ) => {
      if (state.user) {
        state.user.country_code = action.payload.countryCode;
        state.user.phone = action.payload.phone;
      }
    },

    resetUser: () => initialState,
    setUserRole: (state: AuthState, action: PayloadAction<number>) => {
      if (state.user) {
        state.user.role = action.payload;
      } else {
      }
    },

    setLoading: (state: AuthState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state: AuthState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const {
  setUser,
  setLoading,
  setError,
  setRole,
  setUserName,
  setUserRole,
  setAuthToken,
  resetUser,
  setUserId,
  setRedirectUrl,
  setJobseekerId,
  setNewUser,
  // setLoginResult,
  updateUserPhoneNumber,
  setTempResume,
} = authSlice.actions;
export default authSlice.reducer;