import { IconButton, InputAdornment, TextField } from "@mui/material";
import { ReactComponent as EyeIconHide } from "../../assets/images/eye_icon_grey.svg";
import { ReactComponent as EyeIconShow } from "../../assets/images/eye_icon.svg";

const PasswordField = ({
  name,
  value,
  placeholder,
  showPassword,
  togglePasswordVisibility,
  onChange,
  disabled,
}) => {
  return (
    <TextField
      name={name}
      value={value}
      placeholder={placeholder}
      type={showPassword ? "text" : "password"}
      label=""
      variant="filled"
      onChange={onChange}
      disabled={disabled}
      required
      fullWidth
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <EyeIconShow /> : <EyeIconHide />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{
        height: 42, // Set height of the TextField
        "& .MuiInputBase-input": {
          height: "30px", // Adjust the height of the input to align with the 42px of the TextField
          padding: "6px 14px", // Adjust padding to vertically center the text
        },
        "& .MuiFilledInput-root": {
          borderRadius: "4px", // Optional: Adjust border radius if needed
          fontSize: "14px",
          fontWeight: 400,
          fontFamily: "inherit",
          background: "rgba(248, 248, 248, 1)",
        },
      }}
    />
  );
};

export default PasswordField;
