import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SubscriptionGroup } from "../types/subscriptionTypes";

const initialState: SubscriptionGroup = {
  razorpay: {
    free: [],
    business: [],
    enterprise: [],
  },
  stripe: {
    free: [],
    business: [],
    enterprise: [],
  },
  zoho_link: null,
  loading: false,
  error: null,
};

const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    // Define a reducer and automatically generate its action
    setSubscriptions(state, action: PayloadAction<SubscriptionGroup>) {
      state.razorpay = action.payload.razorpay;
      state.stripe = action.payload.stripe;
      state.zoho_link = action.payload.zoho_link;
    },
    setSubscriptionLoading: (
      state: SubscriptionGroup,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
    setSubscriptionError: (
      state: SubscriptionGroup,
      action: PayloadAction<string>
    ) => {
      state.error = action.payload;
    },
  },
});

// Export the automatically generated action creators
export const {
  setSubscriptions,
  setSubscriptionLoading,
  setSubscriptionError,
} = subscriptionsSlice.actions;

// Export the reducer
export default subscriptionsSlice.reducer;
