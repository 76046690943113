import { FaChevronRight, FaStop, FaPlay } from "react-icons/fa";
import { faq_icn, New_Avtar, vc_img } from "../../../assets/images/images";
import Loader from "../../../components/CommonComponents/Loader/Loader";
import { useEffect, useRef, useState } from "react";
import HairoLipSync from "../../../assets/gifs/newhairenodehead.gif";
import HairoListening from "../../../assets/gifs/haire+node+head.gif";
import "../../Home/home_responsive.css";
import { Image } from "antd";

const FAQ = ({
  answer,
  audioPath,
  socketLoading,
  handleAIGeneration,
  setFaqQuestion,
  isFaqVisible
}) => {
  const textareaRef = useRef(null);
  const audioRef = useRef(null);
  const [faqQuestion, setFaqQuestionLocal] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isAudioStopped, setIsAudioStopped] = useState(true);

  useEffect(() => {
    const audioElement = audioRef.current;

    if (audioElement) {
      const handleAudioPlay = () => {
        setIsAudioPlaying(true);
        setIsAudioStopped(false);
        setIsButtonDisabled(true);
      };
      const handleAudioPause = () => {
        setIsAudioPlaying(false);
        setIsAudioStopped(true);
        setIsButtonDisabled(false);
      };
      const handleAudioEnded = () => {
        setIsAudioPlaying(false);
        setIsAudioStopped(true);
        setIsButtonDisabled(false);
      };

      audioElement.addEventListener("play", handleAudioPlay);
      audioElement.addEventListener("pause", handleAudioPause);
      audioElement.addEventListener("ended", handleAudioEnded);

      return () => {
        audioElement.removeEventListener("play", handleAudioPlay);
        audioElement.removeEventListener("pause", handleAudioPause);
        audioElement.removeEventListener("ended", handleAudioEnded);
      };
    }
  }, [audioPath]);

  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleQuestionChange = (event) => {
    setFaqQuestionLocal(event.target.value);
    setFaqQuestion(event.target.value);
    adjustTextareaHeight(event.target);
  };

  const isGenerateButtonDisabled = () => {
    return !faqQuestion || socketLoading || isButtonDisabled;
  };

  const handleQuestionChangeNew = (event) => {
    setFaqQuestionLocal(event.target.value);
    if (event.key === "Enter") {
      handleAIGeneration();
    }
  };

  const stopAudio = () => {
    const audioElement = audioRef.current;
    if (audioElement) {
      audioElement.pause();
      audioElement.currentTime = 0; // Reset to the start
    }
  };

  return (
    <section className="faq_sec_pro_rect" style={{ marginTop: "30px" }}>
      <div className="container-fluid faq_section faq-new-bg">
        <div className="row faq_section faq_inner_sec faq-new-bg ">
          <div className="col-lg-6 col-md-6" data-wow-duration="1.5s">
            <div className="faq_left_sec_new ">
              <div className="faq_left_sec_new_head">
                <h6>
                  Have a Question?
                  <br />
                  Ask Hairo!
                </h6>
              </div>
              <div className="faq_left_sec_new_body_sec">
                <div className="faq_left_sec_new_body_sec_form_sec">
                  <form onSubmit={(event) => event.preventDefault()}>
                    <div className="question-input d-flex align-items-baseline">
                      <textarea
                        ref={textareaRef}
                        value={faqQuestion}
                        onChange={handleQuestionChange}
                        placeholder="What types of professions does your AI cater to?"
                        disabled={socketLoading}
                        className="question-input"
                        rows={1}
                        onKeyDown={handleQuestionChangeNew}
                      ></textarea>

                      <button
                        className="rounded ansbtn icon-button"
                        onClick={handleAIGeneration}
                        disabled={isGenerateButtonDisabled()}
                      >
                        <FaChevronRight />
                      </button>
                    </div>
                    {answer && (
                        <div className="answer_main_div anser_main_new">
                          <div className="answer">
                            <div className="answer_inner">
                              {socketLoading ? (
                                <Loader />
                              ) : (
                                <>
                                  <p>{answer}</p>
                                  {audioPath && (
                                    <audio
                                      autoPlay
                                      ref={audioRef}
                                      style={{ display: "none" }}
                                    >
                                      <source src={audioPath} type="audio/mpeg" />
                                      Your browser does not support the audio element.
                                    </audio>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                  </form>
                </div>
                <div className="body_sec_accordian_sec">
                  <div
                    className="accordion accordion-flush rounded-3 New-accordian"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item mb-3 New-accordian">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          What is an AI avatar-led interview?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                        It's an interview conducted by an AI-powered avatar that asks dynamic questions based on the candidate's responses.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item mb-3 New-accordian">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          Is job posting free on Haire.ai?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                        Yes, job posting is currently free on Haire.ai.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item mb-3 New-accordian">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          How does Haire.ai determine the pricing for its AI-driven interview ?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                        Haire.ai charges for AI-driven screening by AI avatars using a credit system. One credit is equal to $5. Each time you conduct an AI-driven screening interview, one credit is deducted from your account.
                        </div>
                      </div>
                    </div>

                    {/* <div className="accordion-item mb-3 New-accordian">
                      <h2 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          I'm a recruiter. How can AI Hiring help me find the
                          right candidates?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          AI Hiring streamlines candidate screening and offers
                          objective insights. Sign up, input your criteria, and
                          let AI do the initial legwork, presenting you with a
                          curated list of potential candidates.
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6" data-wow-duration="1.5s">
            <div className="faq_boy_icn_new_sec1">
              <Image
                preview={false}
                src={isAudioPlaying ? HairoLipSync : HairoListening}
                alt=""
              />
              <form>
                
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
