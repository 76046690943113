import React from "react";
import HomeHeader from "../../components/HomeComponents/HomeHeader";
import GetInTouch from "../../components/HomeComponents/GetInTouch";
import HomeFooter from "../../components/HomeComponents/HomeFooter";
import { Helmet } from "react-helmet";
import HomeFooterNew from "../../components/HomeComponents/Footer";

const Contactus = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us for AI Recruitment Support | Haire</title>
        <meta
          name="description"
          content="Reach out to Haire.ai for support on AI interview platform and recruitment solutions. Connect with our team today."
        />
        <link rel="canonical" href="https://haire.ai/contact-us" />
      </Helmet>
      <HomeHeader />
      <hr className="spacer30px" />
      <GetInTouch />
      <HomeFooterNew/>
      {/* <HomeFooter /> */}
    </>
  );
};

export default Contactus;
