import React from "react";
import { Subnow } from "../../../../../assets/images/images";
import { usePricingModal } from "../../../../../contexts/PricingModalContext";
import { useSelector } from "react-redux";

const FreeCredit = () => {
  const { showPricingModal } = usePricingModal();
  const companyDetails = useSelector((state) => state.company.details);
  const credits = companyDetails?.company_credits ?? 0
  return (
    <>
      <div>
        <h3>Subscription</h3>
      </div>
      <div className="subscription-card">
        <div class="card-header card_header3">
          <div className="br-right">
            <h2>Free</h2>
          </div>
          <div>
            <h2>{credits} Credits</h2>
          </div>
          <div className="cancle_btn_plan">
            <button className="cancel-button" onClick={showPricingModal}>
              Upgrade Plan
            </button>
          </div>
        </div>
      </div>
      <div className="freesub_img">
        <img src={Subnow} alt="" />
        <p onClick={showPricingModal}>
          You don’t have any subscription. <span>Subscribe Now</span>
        </p>
      </div>
    </>
  );
};

export default FreeCredit;
