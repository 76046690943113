// import React, { useEffect, useState } from "react";
// import ImageUploading from "react-images-uploading";
// import { useDispatch } from "react-redux";
// import { toast } from "react-toastify";
// import { delete_ic, replace, uplolad_ict } from "../../assets/images/images";
// import useS3Uploader from "../../hooks/useS3Uploader";
// import "../../pages/Dashboard/RecruiterDashboard/MyCompany/MyCompany.css";
// import { updateCompanyGallery } from "../../redux/reducers/companyReducer";

// const GalleryComponent = ({
//   initialUrls,
//   onUpdate,
//   imageUploadingLoader,
//   setImageUploadingLoader,
// }) => {
//   const dispatch = useDispatch();
//   const { handleUpload } = useS3Uploader();
//   const [images, setImages] = useState([]);
//   const maxNumber = 69;
//   const maxFileSize = 10 * 1024 * 1024; // 10 MB
//   const allowedFileTypes = ["image/jpeg", "image/png"];
//   const disallowedFileTypes = [
//     "video/mp4",
//     "video/avi",
//     "video/mkv",
//     "image/svg+xml",
//   ];

//   useEffect(() => {
//     if (initialUrls) {
//       const initialImages = initialUrls.split(",").map((url, index) => ({
//         data_url: url.trim(),
//         file: { name: `Image${index + 1}`, type: "image/jpeg" },
//       }));
//       setImages(initialImages);
//     }
//   }, [initialUrls]);

//   const autoSaveGallery = async (currentImages, showSuccessToast = true) => {
//     if (currentImages.length === 0) {
//       const emptyGallery = "";
//       dispatch(updateCompanyGallery(emptyGallery));
//       onUpdate(emptyGallery);
//       return;
//     }
//     try {
//       const newImages = currentImages.filter(
//         (img) => !img.data_url.startsWith("https://haire.s3")
//       );
//       setImageUploadingLoader(true);
//       const uploadResults =
//         newImages.length > 0
//           ? await handleUpload(newImages.map((img) => img.file))
//           : new Map();
//       setImageUploadingLoader(false);
//       const newUrls = Array.from(uploadResults.values());

//       const allUrls = [
//         ...currentImages
//           .filter((img) => img.data_url.startsWith("https://haire.s3"))
//           .map((img) => img.data_url),
//         ...newUrls,
//       ];
//       const updatedGallery = allUrls.join(",");

//       if (showSuccessToast) {
//         toast.success("Images updated successfully");
//       }

//       onUpdate(updatedGallery);
//     } catch (error) {
//       console.log(error);
//       setImageUploadingLoader(false);
//     }
//   };

//   const onChange = (imageList) => {
//     const validImages = [];
//     let hasInvalidFiles = false;
//     let errorShown = false;

//     imageList.forEach((image) => {
//       if (
//         !allowedFileTypes.includes(image.file.type) ||
//         image.file.size > maxFileSize
//       ) {
//         if (!errorShown) {
//           toast.error(
//             "You can only upload JPG/PNG files and the image must be smaller than 10MB!"
//           );
//           errorShown = true;
//         }
//         hasInvalidFiles = true;
//       } else {
//         validImages.push(image);
//       }
//     });
//     if (!hasInvalidFiles) {
//       setImages(validImages);
//       if (validImages.length === 0) {
//         autoSaveGallery([]);
//       } else {
//         autoSaveGallery(validImages);
//       }
//     }
//   };

//   const handleImageRemove = (index, imageList) => {
//     const updatedList = imageList.filter((_, i) => i !== index);
//     setImages(updatedList);
//     toast.success("Image deleted successfully");
//     autoSaveGallery(updatedList, false);
//   };

//   return (
//     <div className="App">
//       <ImageUploading
//         multiple
//         value={images}
//         onChange={onChange}
//         maxNumber={maxNumber}
//         dataURLKey="data_url"
//       >
//         {({
//           imageList,
//           onImageUpload,
//           onImageRemoveAll,
//           onImageUpdate,
//           onImageRemove,
//           isDragging,
//           dragProps,
//         }) => (
//           <div className="upload__image-wrapper">
//             <div className="image-main-div">
//               <div
//                 className="addimg"
//                 style={isDragging ? { color: "red" } : undefined}
//                 onClick={onImageUpload}
//                 {...dragProps}
//               >
//                 <img src={uplolad_ict} alt="" className="add_up_img" />
//               </div>
//               {imageList.map((image, index) => (
//                 <div key={index} className="image-item">
//                   <img src={image.data_url} alt="" width="100" />
//                   <div className="image-item__btn-wrapper">
//                     <img
//                       onClick={() => {
//                         onImageUpdate(index);

//                       }}
//                       src={replace}
//                       alt="replace"
//                       className="replace_btn"
//                       title="Replace Image"
//                     />
//                     <img
//                      onClick={() => {
//                       handleImageRemove(index, imageList);
//                     }}
//                       src={delete_ic}
//                       alt="delete"
//                       className="replace_btn"
//                       title="Delete"
//                     />
//                   </div>
//                 </div>
//               ))}
//             </div>
//             {images.length > 0 && (
//               <div className="save_img_main">
//                 <button
//                   onClick={() => {
//                     onImageRemoveAll();
//                     setImages([]);
//                     autoSaveGallery([]);
//                     toast.success("All images removed successfully");
//                   }}
//                   className="removeAll_btn"
//                 >
//                   Remove all
//                 </button>
//               </div>
//             )}
//           </div>
//         )}
//       </ImageUploading>
//     </div>
//   );
// };

// export default GalleryComponent;

import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { delete_ic, replace, uplolad_ict } from "../../assets/images/images";
import useMultipleFileUpload from "../../hooks/useMultipleFileUpload";
import "../../pages/Dashboard/RecruiterDashboard/MyCompany/MyCompany.css";
import { updateCompanyGallery } from "../../redux/reducers/companyReducer";

const GalleryComponent = ({
  initialUrls,
  onUpdate,
  imageUploadingLoader,
  setImageUploadingLoader,
}) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.token); // Retrieve token from the store
  const { uploadFiles } = useMultipleFileUpload();
  const [images, setImages] = useState([]);
  const maxNumber = 69;
  const maxFileSize = 10 * 1024 * 1024; // 10 MB
  const allowedFileTypes = ["image/jpeg", "image/png"];

  useEffect(() => {
    if (initialUrls) {
      const initialImages = initialUrls.split(",").map((url, index) => ({
        data_url: url.trim(),
        file: { name: `Image${index + 1}`, type: "image/jpeg" },
      }));
      setImages(initialImages);
    }
  }, [initialUrls]);

  const autoSaveGallery = async (currentImages, showSuccessToast = true) => {
    if (currentImages.length === 0) {
      const emptyGallery = "";
      dispatch(updateCompanyGallery(emptyGallery));
      onUpdate(emptyGallery);
      return;
    }
    try {
      const newImages = currentImages.filter(
        (img) => !img.data_url.startsWith("https://haire.s3")
      );
      setImageUploadingLoader(true);

      // Upload new images using the multiple file upload hook
      const uploadResults =
        newImages.length > 0
          ? await uploadFiles(
              newImages.map((img) => img.file),
              token
            ) // Pass token here
          : [];

      setImageUploadingLoader(false);

      const allUrls = [
        ...currentImages
          .filter((img) => img.data_url.startsWith("https://haire.s3"))
          .map((img) => img.data_url),
        ...uploadResults,
      ];
      const updatedGallery = allUrls.join(",");

      if (showSuccessToast) {
        toast.success("Images updated successfully");
      }

      onUpdate(updatedGallery);
    } catch (error) {
      console.error(error);
      setImageUploadingLoader(false);
      toast.error("Failed to update images");
    }
  };

  const onChange = (imageList) => {
    const validImages = [];
    let hasInvalidFiles = false;
    let errorShown = false;

    imageList.forEach((image) => {
      if (
        !allowedFileTypes.includes(image.file.type) ||
        image.file.size > maxFileSize
      ) {
        if (!errorShown) {
          toast.error(
            "You can only upload JPG/PNG files and the image must be smaller than 10MB!"
          );
          errorShown = true;
        }
        hasInvalidFiles = true;
      } else {
        validImages.push(image);
      }
    });

    if (!hasInvalidFiles) {
      setImages(validImages);
      if (validImages.length === 0) {
        autoSaveGallery([]);
      } else {
        autoSaveGallery(validImages);
      }
    }
  };

  const handleImageRemove = (index, imageList) => {
    const updatedList = imageList.filter((_, i) => i !== index);
    setImages(updatedList);
    toast.success("Image deleted successfully");
    autoSaveGallery(updatedList, false);
  };

  return (
    <div className="App">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <div className="upload__image-wrapper">
            <div className="image-main-div">
              <div
                className="addimg"
                style={isDragging ? { color: "red" } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                <img src={uplolad_ict} alt="" className="add_up_img" />
              </div>
              {imageList.map((image, index) => (
                <div key={index} className="image-item">
                  <img src={image.data_url} alt="" width="100" />
                  <div className="image-item__btn-wrapper">
                    <img
                      onClick={() => {
                        onImageUpdate(index);
                      }}
                      src={replace}
                      alt="replace"
                      className="replace_btn"
                      title="Replace Image"
                    />
                    <img
                      onClick={() => {
                        handleImageRemove(index, imageList);
                      }}
                      src={delete_ic}
                      alt="delete"
                      className="replace_btn"
                      title="Delete"
                    />
                  </div>
                </div>
              ))}
            </div>
            {images.length > 0 && (
              <div className="save_img_main">
                <button
                  onClick={() => {
                    onImageRemoveAll();
                    setImages([]);
                    autoSaveGallery([]);
                    toast.success("All images removed successfully");
                  }}
                  className="removeAll_btn"
                >
                  Remove all
                </button>
              </div>
            )}
          </div>
        )}
      </ImageUploading>
    </div>
  );
};

export default GalleryComponent;
