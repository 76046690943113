import { Dispatch } from "redux";

// @ts-ignore
import instance from "../../api/instance";
// @ts-ignore
import { baseURL } from "../../api/instance";

import {
  setCreditHistory,
  setCreditHistoryError,
  setCreditHistoryLoading,
} from "../reducers/creditsReducer";
import { setJobApplicantsList, setJobsCount } from "../reducers/jobReducer";

// Async thunk action to load subscription history
export const loadCreditHistory =
  (token: string, sort?: string, search?: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setCreditHistoryLoading(true));

    const url = new URL(`${baseURL}subscription/get-credit-history/`);
    const params = new URLSearchParams();

    if (search) params.append("search", search);
    if (sort) params.append("sort", sort);

    url.search = params.toString();
    try {
      const response = await instance.get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      if (response.status === 200) {
        dispatch(setCreditHistory(response?.data?.data));
        dispatch(setJobApplicantsList(response?.data?.data));
        dispatch(setJobsCount(response?.data?.count));
      }
      dispatch(setCreditHistoryLoading(false));
    } catch (error: any) {
      dispatch(setCreditHistoryError(error.toString()));
    } finally {
      dispatch(setCreditHistoryLoading(false));
    }
  };
