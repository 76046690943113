import { Dispatch } from "redux";
// @ts-ignore
import instance from "../../api/instance";
// @ts-ignore
import { baseURL } from "../../api/instance";

import {
  setPaymentHistory,
  setPaymentHistoryError,
  setPaymentHistoryLoading,
} from "../reducers/paymentsReducer";
import { setJobsCount } from "../reducers/jobReducer";

// Async thunk action to load subscription history
export const loadPaymentHistory =
  (token: string, sort?: string, search?: string,currentPage?: number) =>
  async (dispatch: Dispatch) => {
    dispatch(setPaymentHistoryLoading(true));

    const url = new URL(`${baseURL}subscription/get-subscription-history/`);
    const params = new URLSearchParams();

    if (search) params.append("search", search);
    if (sort) params.append("sort", sort);
    params.append("page", currentPage?.toString() || "");

    url.search = params.toString();

    try {
      const response = await instance.get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      if (response.status === 200) {
        dispatch(setPaymentHistory(response?.data?.data));
        dispatch(setJobsCount(response?.data?.count));
      }
      dispatch(setPaymentHistoryLoading(false));
    } catch (error: any) {
      dispatch(setPaymentHistoryError(error.toString()));
    } finally {
      dispatch(setPaymentHistoryLoading(false));
    }
  };
