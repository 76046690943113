import { useState } from "react";

const useFilters = (initialFilters, maxCount) => {
  const [filters, setFilters] = useState(initialFilters);

  const handleFilterChange = (filterName) => (selectedItems) => {
    if (selectedItems.length <= maxCount) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: selectedItems,
      }));
    } else {
      // Optional: Notify the user that the maximum count has been exceeded
    }
  };

  return [filters, handleFilterChange];
};

export default useFilters;
