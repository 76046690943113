import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Frame1, Frame2, Frame3 } from "../../../assets/images/images";
import "./aiInterviewGuide.css";

const GuideLineModal = ({ show, onHide }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="max-wid"
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="modal-header">
        <Modal.Title id="contained-modal-title-vcenter">
          AI Interview Platform Guidelines for Candidates
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <div className="guideline">
          <div className="guideline-item">
            <img src={Frame1} alt="" />
            <p>
              The interview will be conducted virtually using Video Conferencing
              Platform.
            </p>
          </div>
          <div className="guideline-item">
            <img src={Frame1} alt="" />
            <p>
              AI analyzes visual cues like eye movements and facial expressions
              alongside your voice.
            </p>
          </div>
          <div className="guideline-item">
            <img src={Frame2} alt="" />
            <p>
              Experience rounds in Cultural, Behavioural, Technical, and HR
              areas, aimed at a deeper understanding of candidates.
            </p>
          </div>
          <div className="guideline-item">
            <img src={Frame3} alt="" />
            <p>
              This interview is a crucial step in your application. It's your
              chance to stand out!
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <button onClick={onHide} className="blue_bg_btn">
          Continue
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default GuideLineModal;
