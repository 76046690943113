import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePricingModal } from "../../../../contexts/PricingModalContext";
import { fetchBillingInfo } from "../../../../redux/actions/billingActions";
import { resetCompany } from "../../../../redux/reducers/companyReducer";
import "./MonitizationPage.css";
import AddCardModal from "./components/AddCardModal";
import BillingModal from "./components/BillingModal";
import Plans from "./components/Plans";
import SubscriptionTabbar from "./components/SubscriptionTabbar";
import BillingInformation from "./tabs/BillingInformation";
import CreditHistory from "./tabs/CreditHistory";
import PaymentHistory from "./tabs/PaymentHistory";
import SubscriptionOverview from "./tabs/SubscriptionOverview";

const Monitization = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.token);
  const companyData = useSelector((state) => state.company.details);
  const { showPricingModal } = usePricingModal();
  const [activeTab, setActiveTab] = useState("overView");
  const [showreport, setshowreport] = useState(false);
  const [showBilling, setbilling] = useState(false);
  const [criteria, setSortCriteria] = useState(null);
  const country = "US";
  const subscriptions = useSelector((state) => state.subscriptions);
  const free = subscriptions?.razorpay?.free;
  const stripeFree = subscriptions?.stripe?.free;
  const business = subscriptions?.razorpay?.business ?? [];
  const enterprise = subscriptions?.razorpay?.enterprise ?? [];
  const stripeBusiness = subscriptions?.stripe?.business ?? [];
  const stripeEnterprise = subscriptions?.stripe?.enterprise ?? [];
  const companySub = companyData?.company_sub;
  const subscriptionPlan = companySub?.subscription_plan;

  useEffect(() => {
    if (activeTab === "billingInfo") {
      dispatch(fetchBillingInfo(token)); // Dispatch the action when the billing info tab is active
    }
  }, [activeTab, dispatch]);

  const hideModal = () => {
    setshowreport(false);
    setbilling(false);
  };

  const handleSortChange = (criteria) => {
    setSortCriteria(criteria);
  };

  const editBilling = () => {
    setbilling(true);
  };

  const addPayment = () => {
    setshowreport(true);
  };
  const opensubs = () => {
    showPricingModal();
  };

  const handleSubscribePlan = () => {
    if (!companyData?.id) {
      dispatch(resetCompany());
      navigate("/company-details", { state: { isEmpty: true } });
    }
  }

  const tabComponents = {
    BillingInfo: (
      <BillingInformation addPayment={addPayment} editBilling={editBilling} />
    ),
    PaymentHistory: <PaymentHistory addPayment={addPayment} />,
    overView: <SubscriptionOverview onCancelPlan={opensubs} />,
    creditHistory: <CreditHistory />,
  };

  return (
    <>
      {
        !companyData ?
          <div className="report_modal_sec_inner">
            {/* <div className="report_modal_sec_inner_head">
              <button onClick={onHide}>
                <i class="fas fa-times"></i>
              </button>
            </div> */}
            <div className="report_modal_sec_inner_body Add_card_modal">
              <h3 className="Pricing_head">
                Pricing{" "}
                <span className="Pricing_credit">
                  Credit Plans for Smarter Hiring
                </span>
              </h3>
              <Plans
                free={country === "India" ? free : stripeFree}
                business={country === "India" ? business : stripeBusiness}
                enterprise={country === "India" ? enterprise : stripeEnterprise}
                country={country}
                subscriptionPlan={subscriptionPlan}
                handleSubscribePlan={handleSubscribePlan}
              />
            </div>
          </div>
          : <div className="comp_det_rigt_main tab-content subscription_plan_mt">
            <div className="company_details_sec_new_inner_right">
              <SubscriptionTabbar
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />

              {tabComponents[activeTab]}
            </div>
          </div>
      }
      {/* ADD card Modal Start ---------------------------------------- */}
      <AddCardModal show={showreport} onHide={hideModal} />
      {/* Billing Modal start---------------------------------------- */}
      <BillingModal show={showBilling} onHide={hideModal} />
    </>
  );
};

export default Monitization;
