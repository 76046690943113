import React from "react";
import { Card, Skeleton, Space } from "antd";

const SkeletonLoader = ({
  includeTable = false,
  excludeDetails = false,
  includeDashboard = false,
  tableRows = 5,
  columnWidths = ["20%", "15%", "15%", "15%", "15%", "10%"],
  onlyColumns = false,
}) => {
  const detailsSkeleton = (
    <>
      <Skeleton.Image shape="square" paragraph={{ rows: 1 }} />
      <Skeleton
        title={false}
        active
        paragraph={{ rows: 2 }}
        style={{ margin: "10px 10px 10px 0" }}
      />
      <Space style={{ justifyContent: "space-evenly" }}>
        <Skeleton.Button
          style={{ width: 110, margin: "10px 10px 10px 0" }}
          active
          size="small"
          shape="square"
        />
        <Skeleton.Button
          style={{ width: 110 }}
          active
          size="small"
          shape="square"
        />
        <Skeleton.Button
          style={{ width: 110 }}
          active
          size="small"
          shape="square"
        />
        <Skeleton.Button
          style={{ width: 110 }}
          active
          size="small"
          shape="square"
        />
      </Space>
    </>
  );

  const tableSkeleton = (
    <>
      {Array.from(new Array(onlyColumns ? 1 : tableRows)).map((_, index) => (
        <Space
          key={index}
          style={{
            width: "100%",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          {columnWidths.map((width, colIndex) => (
            <Skeleton.Input
              key={colIndex}
              style={{ width }}
              active
              size="default"
            />
          ))}
        </Space>
      ))}
    </>
  );

  const dashboardSkeleton = (
    <Space
      style={{ width: "100%", justifyContent: "space-around", padding: 20 }}
    >
      {Array.from(new Array(4)).map((_, index) => (
        <Skeleton.Button
          key={index}
          style={{ width: 140, height: 60 }}
          active
          shape="square"
        />
      ))}
    </Space>
  );

  return (
    <Card>
      {!excludeDetails && detailsSkeleton}
      {includeTable && tableSkeleton}
      {includeDashboard && dashboardSkeleton}
    </Card>
  );
};

export default SkeletonLoader;
