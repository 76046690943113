import { Modal } from "react-bootstrap";
import { job_post_img } from "../../assets/images/images";
import { useNavigate } from "react-router-dom";

const BookDemoModal = ({ show, handleClose, zohoLink }) => {
  const navigate = useNavigate();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      className="verifictaion_mod p-40"
      keyboard={false}
    >
      <div className="main-container-demo">
        <h6>Book a Demo</h6>

        <p className="verification-modal-subtitle">
          Unfortunately, the demo is not available at the moment. Please book a
          demo using the link below.
        </p>
        <img src={job_post_img} className="job-img-cont" />
        <div className="job-img-cont-btn">
          <button className="Btn-out" onClick={() => navigate("/")}>
            Back to home
          </button>

          <button
            className="blue_btn_links demo-button"
            onClick={() => window.open(zohoLink, "_blank")}
          >
            Book a Demo
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default BookDemoModal;
