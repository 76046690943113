import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoaderApply from "../components/CommonComponents/Loader/LoaderApply";

const CheckAuthAndRedirectJobs = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [isRedirecting, setIsRedirecting] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      try {
        if (token) {
          navigate(`/applicant-dashboard/related-jobs/${jobId}`);
        } else {
          navigate(`/jobs/related-jobs/${jobId}`);
        }
      } catch (err) {
        setError(true);
      } finally {
        setIsRedirecting(false);
      }
    }, 100); // Small delay to ensure smooth transition

    return () => clearTimeout(redirectTimer);
  }, [token, jobId, navigate]);

  if (!error) {
    return (
      <div style={{ 
        minHeight: '100vh', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center' 
      }}>
        <div style={{ textAlign: 'center' }}>
          <h2 style={{ 
            fontSize: '1.25rem', 
            fontWeight: '600', 
            marginBottom: '0.5rem' 
          }}>
            Something went wrong
          </h2>
          <p style={{ color: '#666' }}>
            Please try refreshing the page or navigate back
          </p>
        </div>
      </div>
    );
  }

  return (
    <div style={{ 
      minHeight: '100vh', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center' 
    }}>
      {isRedirecting && <LoaderApply />}
    </div>
  );
};

export default CheckAuthAndRedirectJobs;