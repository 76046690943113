import React from "react";
import HomeFooter from "../../components/HomeComponents/HomeFooter";
import HomeHeader from "../../components/HomeComponents/HomeHeader";
import "../Features/Features.css";
import "../TermsCondition/TermsAndConditon.css";
import HomeFooterNew from "../../components/HomeComponents/Footer";

const RefundPolicy = () => {
  return (
    <>
      <HomeHeader />

      <section className="feature_sec_page_new">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="feature_sec_page_new_head">
                <h2 className="Term_head">
                  Haire.ai Refund and Cancellation Policy: Exception-Based
                  Refunds
                </h2>
                <p className="term_policy">
                  At Haire.ai, we are dedicated to offering innovative AI-driven
                  recruitment solutions designed to streamline and enhance the
                  hiring process for companies while providing unparalleled
                  opportunities for job seekers. Our services are structured
                  around subscription models and credit packages tailored to
                  meet diverse hiring needs. While our standard policy
                  emphasises that fees for utilised services and credits are
                  generally non-refundable, we recognize the importance of
                  flexibility and fairness in certain exceptional circumstances.{" "}
                </p>
                <h5>Exception-Based Refund Conditions</h5>
                <p>
                  We are committed to our subscribers and aim to offer
                  satisfaction and trust through provisions for refunds under
                  specific exceptional conditions outlined below. These
                  exceptions address inadvertent charges and technical
                  discrepancies, ensuring our subscribers are treated with
                  fairness and transparency.
                </p>
                <ol className="sub-list">
                  <li>
                    <h5>Accidental Charges Post-Subscription Cancellation:</h5>
                    <ol type="a" className="type_a">
                      <li>
                        Subscribers who have successfully cancelled their
                        subscription before the next billing date but are
                        subsequently charged due to a processing delay or system
                        error shall notify Haire.ai within 7 days of the charge
                        by providing proof of cancellation and billing charges.
                        Acceptable proof shall include confirmation emails or
                        screenshots showing the cancellation date along with
                        subsequent billing charges
                      </li>
                      <li>
                        Upon notification, Haire.ai will verify the cancellation
                        through our records and investigate the cause of the
                        accidental charge, Once verified the amount charged will
                        be refunded in accordance to clause 4 & 5
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h5>
                      {" "}
                      Technical Difficulties Resulting in Incorrect Billing:
                    </h5>
                    <ol type="a" className="type_a">
                      <li>
                        Subscribers who have been charged due to glitches,
                        errors in our billing system, or any technical
                        malfunction leading to incorrect billing amounts or
                        unauthorised charges shall report the issue to Haire.ai
                        within 7 days of noticing the discrepancy, including
                        detailed information such as transaction IDs,
                        screenshots of the billing error, and any other relevant
                        evidence.
                      </li>
                      <li>
                        Haire.ai will conduct a thorough investigation to verify
                        the technical issue, involving our IT and billing
                        departments to identify and rectify the cause of the
                        error and such charges will be refunded in accordance
                        with Clause 4 & 5, once verified.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h5>Refund Process:</h5>
                    <ol type="a" className="type_a">
                      <li>
                        Subscribers experiencing any of the exceptional
                        circumstances above are required to promptly contact
                        Haire.ai’s customer support at [info@haire.ai] or
                        through our support portal.
                      </li>
                      <li>
                        Haire.ai will conduct a thorough investigation into the
                        reported issue. This investigation will include:
                        <li>i. Verification of transaction records.</li>
                        <li>ii. Confirmation of subscription cancellation.</li>
                        <li>
                          iii. Assessment of the claimed technical error through
                          system logs and IT analysis.
                        </li>
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h5> Resolution:</h5>
                    <p>
                      Upon confirming the validity of the claim under the
                      exception-based conditions:
                    </p>
                    <ol type="a" className="type_a">
                      <li>
                        Haire.ai will process the refund to the original payment
                        method used by the customer
                      </li>
                      <li>
                        The customer will be notified via email once the refund
                        has been processed.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h5>Time Frame:</h5>
                    <ol type="a" className="type_a">
                      <li>
                        Refunds will be processed within 14 business days
                        following the resolution confirmation. Any delays in the
                        process will be communicated to the customer with an
                        updated timeline.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h5>Limitations:</h5>
                    <ol type="a" className="type_a">
                      <li>
                        Refunds are limited to the most recent billing cycle
                        charge and do not cover any charges beyond this period
                        unless explicitly acknowledged as an error by Haire.ai.
                      </li>
                      <li>
                        This policy does not affect your statutory rights, which
                        remain protected under applicable law.
                      </li>
                      <li>
                        subscribers must report/notify Haire or raise a ticket
                        regarding the glitch or accidental charges within 7
                        days; failure to do so will result in ineligibility for
                        any refund.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h5> General Conditions:</h5>
                    <ol type="a" className="type_a">
                      <li>
                        Haire.ai reserves the right to modify or terminate this
                        exception-based refund policy at any time without prior
                        notice. Any modifications made to this exception-based
                        refund policy will not affect the processing of refund
                        requests or tickets raised before the date of
                        modification
                      </li>
                      <li>
                        Subscribers shall be responsible for keeping themselves
                        updated with the latest version of the Haire.ai refund
                        policy. Haire.ai will not be held responsible for any
                        claims arising from the user's failure to review and
                        stay informed about the current refund policy
                      </li>
                      <li>
                        This policy is applicable in conjunction with the
                        broader terms and conditions agreed upon by subscribers
                        upon signing up for Haire.ai’s services.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h5>Contact Information:</h5>
                    <h5>
                      For refund requests, billing inquiries, or to discuss any
                      billing concerns under the exception-based conditions,
                      please reach out through info@haire.ai
                    </h5>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <HomeFooter /> */}
      <HomeFooterNew/>
    </>
  );
};

export default RefundPolicy;
