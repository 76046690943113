import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { clock_icon, haire_logo } from "../../assets/images/images";
import { calculateTimeSpan, formatDateTimeToLocalWithOffset, formatDurationTime } from "../utils";

export const generatePdf = async (selectedTranscript, selectedApplicant) => {
  const content = selectedTranscript.map((item, index) => ({
    questionText: item?.ai_question,
    answerText: item?.candidate_answer,
    answerTime: item?.answer_duration,
    interpretationText: item?.ai_interpretation,
    question: `Question ${selectedTranscript.length - index}: Hairo AI`,
    answer: `Answer : ${selectedApplicant?.user_full_name}`,
    answerDuration: item?.answer_duration ? formatDurationTime(item?.answer_duration) : null
  })).reverse(); // Reverse the order of the content

  const pdf = new jsPDF();
  const container = document.createElement("div");
  container.style.width = "800px";
  container.style.margin = "0 auto";
  container.style.padding = "0";

  // Append container to document body for html2canvas usage
  document.body.appendChild(container);

  let yOffset = 10; // Initial y offset
  let isFirstPage = true;

  const interviewDuration = calculateTimeSpan(
    selectedApplicant?.interview_details?.started_at,
    selectedApplicant?.interview_details?.ended_at
  );
  const questionCount = selectedTranscript.length;
  const timestamp = selectedApplicant?.interview_details?.date &&
                    selectedApplicant?.interview_details?.started_at
                      ? formatDateTimeToLocalWithOffset(
                          selectedApplicant?.interview_details?.date,
                          selectedApplicant?.interview_details?.started_at
                        )
                      : "Timestamp unknown";

  for (const data of content) {
    const element = document.createElement("div");
    element.style.backgroundColor = "#fff";
    element.style.fontFamily = "Arial, sans-serif";
    element.style.fontSize = "14px";
    element.style.lineHeight = "1.6";
    element.style.color = "#333";
    element.style.padding = "0px";
    element.style.pageBreakInside = "avoid";
    element.style.marginBottom = "10px"; // Reduced margin-bottom for spacing

    if (isFirstPage) {
      element.innerHTML = `
        <div style="display: flex;">
          <img src="${haire_logo}" style="width: 80px; height: 40px; margin-right: 20px;">
          <div style="text-align: right; flex-grow: 1;">
            <div style="font-family: helvetica; font-weight: bold; font-size: 16px;">${selectedApplicant?.user_full_name} | ${selectedApplicant.job_title}</div>
            <div style="font-family: helvetica; font-weight: normal; font-size: 14px;">${timestamp}</div>
          </div>
        </div>
        <div>
          <div style="padding: 0;">
            <div style="font-size: 12px; font-weight: bold; color: #333; margin-bottom: 0;">Interview Report</div>
            <div style="font-size: 18px; color: #000; font-weight: bold; margin-bottom: 0;">${selectedApplicant.job_title}</div>
            <div style="font-size: 14px; color: #000; display: flex; align-items: center;">
              <div style="margin-right: 10px; color: #000;">⏱ ${interviewDuration}</div>
              <div style="color: #000;">📋 ${questionCount} Questions</div>
            </div>
            <hr style="margin-top: 10px; border: 0; border-top: 5px solid #1865C1;" />
          </div>
          <div style="border-left: 2px solid #1865C1; padding-left: 10px;">
            <h2 style="font-size: 16px; color: #1865C1; margin: 0 0 8px 0;">${data.question}</h2>
            <p>${data.questionText}</p>
          </div>
          <div style="border-left: 2px solid #0085FF; padding-left: 10px;">
<h2 style="font-size: 16px; color: #0085FF; margin: 0 0 5px 0;">${data.answer} | ${data.answerDuration ? `<img src="${clock_icon}" /> ${data.answerDuration}` : ''}</h2>            <p>${data.answerText}</p>
          </div>
          <div style="border-left: 2px solid #08BCD0; padding-left: 10px;">
            <h2 style="font-size: 16px; color: #08BCD0; margin: 0 0 5px 0;">AI Interpretation</h2>
            <p>${data.interpretationText}</p>
          </div>
        </div>
        <hr />
      `;
      isFirstPage = false;
    } else {
      element.innerHTML = `
        <div style="border-left: 2px solid #1865C1; padding-left: 10px; margin-top: 10px;">
          <h2 style="font-size: 16px; color: #1865C1; margin: 0 0 8px 0;">${data.question}</h2>
          <p>${data.questionText}</p>
        </div>
        <div style="border-left: 2px solid #0085FF; padding-left: 10px; margin-top: 10px;">
          <h2 style="font-size: 16px; color: #0085FF; margin: 0 0 8px 0;">${data.answer} | ${data.answerDuration ? `<img src="${clock_icon}" /> ${data.answerDuration}` : ''}</h2>
          <p>${data.answerText}</p>
        </div>
        <div style="border-left: 2px solid #08BCD0; padding-left: 10px; margin-top: 10px;">
          <h2 style="font-size: 16px; color: #08BCD0; margin: 0 0 8px 0;">AI Interpretation</h2>
          <p>${data.interpretationText}</p>
        </div>
        <hr />
      `;
    }

    container.appendChild(element);
    const canvas = await html2canvas(element, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");
    const imgHeight = (canvas.height * 190) / canvas.width;

    if (yOffset + imgHeight > pdf.internal.pageSize.getHeight() - 20) { // Consider space for footer
      pdf.addPage();
      yOffset = 10; // Reset y-offset for the new page
    }

    pdf.addImage(imgData, "PNG", 10, yOffset, 190, imgHeight);
    yOffset += imgHeight + 10; // Increment y-offset for the next content, add consistent space
  }

  if (selectedApplicant?.interview_details?.tab_switch_count >= 3) {
    const terminationElement = document.createElement("div");
    terminationElement.innerHTML = `
      <div style="padding: 20px; margin-top: 20px;background-color: hsla(0, 100%, 43%, 0.08);border-radius:6px">
        <h4 style="color: #DC0000; margin: 0;font-size:14px;margin-bottom:10px">Interview Terminated</h4>
        <p style="margin: 0;font-size:12px"">The candidate's interview was terminated due to frequently being distracted by switching tabs, even after several reminders.</p>
      </div>
    `;
    container.appendChild(terminationElement);
    const terminationCanvas = await html2canvas(terminationElement, { scale: 2 });
    const terminationImgData = terminationCanvas.toDataURL("image/png");
    const terminationImgHeight = (terminationCanvas.height * 190) / terminationCanvas.width;

    if (yOffset + terminationImgHeight > pdf.internal.pageSize.getHeight() - 20) {
      pdf.addPage();
      yOffset = 10;
    }

    pdf.addImage(terminationImgData, "PNG", 10, yOffset, 190, terminationImgHeight);
  }


  const addFooter = () => {
    const pageCount = pdf.internal.getNumberOfPages(); // Get total number of pages
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(10);
    pdf.setTextColor(150);
    for (let i = 1; i <= pageCount; i++) {
      pdf.setPage(i);
      pdf.text(
        "Page " + i + " of " + pageCount,
        pdf.internal.pageSize.getWidth() - 20,
        pdf.internal.pageSize.getHeight() - 10,
        { align: "right" }
      );
    }
  };

  addFooter(); // Add page numbers after all content has been added
  pdf.save(`${selectedApplicant?.user_full_name}-${selectedApplicant.job_title}-Transcript.pdf`);
  document.body.removeChild(container); // Clean up
};
