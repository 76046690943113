import { useSelector } from "react-redux";
import FreeCredit from "../components/FreeCredit";
import SubscriptionPlan from "../components/SubscriptionPlan";

const SubscriptionOverview = ({ onCancelPlan }) => {
  const companyDetails = useSelector((state) => state.company?.details);
  const isPremium = companyDetails?.is_premium;
  const cancelled = companyDetails?.company_sub?.cancelled;

  let componentToRender;

  switch (true) {
    case cancelled === null && !isPremium:
      componentToRender = <FreeCredit />;
      break;
    case cancelled === false && isPremium:
      componentToRender = (
        <SubscriptionPlan
          onCancelPlan={onCancelPlan}
          showUpgradeButton={false}
        />
      );
      break;
    case cancelled === true && !isPremium:
      componentToRender = (
        <SubscriptionPlan
          onCancelPlan={onCancelPlan}
          showUpgradeButton={true}
        />
      );
      break;
    default:
      componentToRender = <FreeCredit />;
      break;
  }

  return <>{componentToRender}</>;
};

export default SubscriptionOverview;
