import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DashboardCount } from "../types/dashboardCountTypes";

export interface CountState {
  count: DashboardCount | null;
  error: string | null;
  loading: boolean | null;
}
const initialState: CountState = {
  count: null,
  error: null,
  loading: false,
};

const countSlice = createSlice({
  name: "count",
  initialState,
  reducers: {
    setCount: (state: CountState, action: PayloadAction<DashboardCount>) => {
      state.count = action.payload as DashboardCount;
    },
    updateCount: (state: CountState, action: PayloadAction<DashboardCount>) => {
      if (state.count == null) {
        state.count = action.payload as DashboardCount;
      } else {
        state.count = { ...state.count, ...action.payload };
      }
    },
    setCountError: (state: CountState, action: PayloadAction<string>) => {
      state.error = action.payload as string;
    },
    setCountLoading: (state: CountState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    resetCountState: () => initialState,
  },
});

export const {
  setCount,
  updateCount,
  setCountError,
  setCountLoading,
  resetCountState,
} = countSlice.actions;
export default countSlice.reducer;
