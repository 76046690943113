import React from "react";
import "./CommonRecruiter.css";
import { Under_review } from "../../../../assets/images/images";

const PostJobModal = ({ name }) => {
  return (
    <section>
      <div className="under_review_main">
        <h4>
          {name === "doc"
            ? "Uploading your resume"
            : "Your Posted Job is Under Review"}
        </h4>
        {name === "doc" ? (
          <p>AI is currently fetching details from your resume.</p>
        ) : (
          <p>
            AI is verifying the details and will notify you. Thank you for your
            patience.
          </p>
        )}
        <img src={Under_review} alt="Postjob" width="80" />
      </div>
    </section>
  );
};

export default PostJobModal;
