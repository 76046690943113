import { useEffect, useRef, useState } from "react";
import { down_arrow } from "../../../../../assets/images/images";

const SubscriptionTabbar = ({ activeTab, setActiveTab }) => {
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="row">
      <div className="col-6 col-sm-12 menulist new_menu_sec_jobs wd_menu res-monitization">
        <div className="d-flex justify-content-between align-items-center">
          <div
            className={`text-center ${activeTab === "overView" ? "active1" : ""}`}
            onClick={() => setActiveTab("overView")}
          >
            Overview
          </div>

          <div
            className={`text-center ${activeTab === "BillingInfo" || activeTab === "PaymentHistory" ? "active1" : ""}`}
            onClick={toggleDropdown}
          >
            {activeTab === "BillingInfo" || activeTab === "PaymentHistory" ? activeTab.replace(/([A-Z])/g, ' $1').trim() : "Billing Info"}
            <span className={`dropdown-icon ${isDropdownOpen ? "open" : ""}`}>
              <img src={down_arrow} alt="" />
            </span>
          </div>
          {isDropdownOpen && (
            <div className="dropdown-menu1" ref={dropdownRef}>
              <div
                className="dropdown-item"
                onClick={() => {
                  setActiveTab("BillingInfo");
                  setDropdownOpen(false);
                }}
              >
                Billing Info
              </div>
              <div
                className="dropdown-item"
                onClick={() => {
                  setActiveTab("PaymentHistory");
                  setDropdownOpen(false);
                }}
              >
                Payment History
              </div>
            </div>
          )}
          <div
            className={`text-center ${activeTab === "creditHistory" ? "active1" : ""}`}
            onClick={() => setActiveTab("creditHistory")}
          >
            Credit History
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionTabbar;
