import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";

const HaireRecruiters = () => {
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    localStorage.setItem("userType", "recruiter");
    navigate("/sign-up");
  };

  const accordionData = [
    {
      title: "Comprehensive Candidate Filtering",
      content:
        "Minimizes errors and biases, ensuring precise job seeker selection.",
      duration: "4s",
    },
    {
      title: "Anti-Cheating Measures",
      content:
        "Implements cutting-edge technology to prevent dishonesty, ensuring integrity in evaluations.",
      duration: "4.2s",
    },
    {
      title: "Detailed Analytics",
      content:
        "Provides granular insights and data-driven reports, enabling effective decision-making.",
      duration: "4.4s",
    },
    {
      title: "User-Friendly Interface",
      content:
        "Designed for ease of use to enhance user experience, facilitating smoother navigation and operations.",
      duration: "4.6s",
    },
  ];

  return (
    <section className="haire_key_sec_new">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="haire_key_sec_new_inner">
              <div className="haire_key_sec_new_inner_left">
                <h5 className="wow animate__slideInLeft" data-wow-duration="4s">
                  Haire Key Features for
                  <br /> <span>Recruiters</span>
                </h5>
                <button
                  className="sign_up_btn_new after_mobile gradient-button wow animate__slideInLeft responsive-hide-btn"
                  data-wow-duration="4s"
                  onClick={handleClick}
                >
                  Sign up as Recruiter
                </button>
              </div>
              <div className="haire_key_sec_new_inner_right">
                <Accordion defaultActiveKey="0">
                  {accordionData.map((item, index) => (
                    <Accordion.Item
                      eventKey={`${index}`}
                      className="wow animate__slideInUp"
                      data-wow-duration={item.duration}
                    >
                      <Accordion.Header>
                        <div className="filter-container1">
                          <div>{item.title}</div>
                          <div className="number-count">{index + 1}</div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>{item.content}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
                <button
                  className="sign_up_btn_new after_mobile gradient-button wow animate__slideInLeft responsive-hide-btn-on"
                  data-wow-duration="4s"
                  onClick={handleClick}
                >
                  Sign up as Recruiter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HaireRecruiters;
