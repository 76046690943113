import React, { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { signOut } from "./redux/actions/authActions";
import { fetchUserCountry } from "./redux/actions/locationAction";
import RoleBasedRoutes from "./routes/RoleBasedRoutes";
import ScrollToTop from "./utils/ScrollToTop";
import {
  setJobPageFilters,
} from "./redux/reducers/jobReducer";

function App() {
  const userLoggedIn = useSelector((state) => !!state.auth.user);
  const dispatch = useDispatch();

  const onIdle = () => {
    dispatch(signOut());
  };

  const onActive = () => {
    localStorage.setItem("lastActiveTime", Date.now());
  };

  useIdleTimer({
    onIdle,
    onActive,
    timeout: 1800000, //30 minutes
    throttle: 0,
  });

  useEffect(() => {
    dispatch(fetchUserCountry());
  }, [dispatch, userLoggedIn]);

  useEffect(() => {
    const lastActiveTime = localStorage.getItem("lastActiveTime");
    if (lastActiveTime && Date.now() - parseInt(lastActiveTime) > 1800000) {
      // 30 minutes
      dispatch(signOut());
    }
  }, [dispatch]);

  useEffect(() => {
    //on page reload reset Job Page filters
    dispatch(
      setJobPageFilters({
        current: 1,
        sortBy: "All",
        status: "All",
        searchInput: "",
        type: "All",
      })
    );
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <RoleBasedRoutes />
    </Router>
  );
}

export default App;
