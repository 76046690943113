import React, { useEffect, useRef } from "react";
import { useMyGeoLocation } from "react-geolocation-x";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import HomeFooterNew from "../../components/HomeComponents/Footer";
import GetInTouch from "../../components/HomeComponents/GetInTouch";
import HomeHeader from "../../components/HomeComponents/HomeHeader";
import { loadSubscriptions } from "../../redux/actions/subscriptionActions";
import useGeolocationStore from "../../zustand/locationStore";
import "../AboutUs/Aboutus.css";
import Plans from "../Dashboard/RecruiterDashboard/Monitization/components/Plans";
import ChosePlan from "../Home/NewSections/ChoseThePlan";
import NeedHelp from "../Home/NewSections/NeedHelp";

const Pricing = () => {
  const locationData = useMyGeoLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, fetchLocation } = useGeolocationStore();
  const country = "US";
  const subscriptions = useSelector((state) => state.subscriptions);
  const free = subscriptions?.razorpay?.free;
  const stripeFree= subscriptions?.stripe?.free;
  const business = subscriptions?.razorpay?.business ?? [];
  const enterprise = subscriptions?.razorpay?.enterprise ?? [];
  const stripeBusiness = subscriptions?.stripe?.business ?? [];
  const stripeEnterprise = subscriptions?.stripe?.enterprise ?? [];
  const getInTouchRef = useRef(null);
  const handleClick = (event) => {
    event.preventDefault();
    navigate("/sign-up");
  };

  const ctaBtn = (
    <Link className="get_start_btn" onClick={handleClick}>
      {/* <img src="../../images/btn-bg.svg" className="btn_bg_img" />  */}
      Get Started
    </Link>
  );
  const handleGetInTouchScroll = () => {
    if (getInTouchRef.current) {
      getInTouchRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    if (locationData.latitude && locationData.longitude) {
      fetchLocation(locationData.latitude, locationData.longitude);
    }
    dispatch(loadSubscriptions());
  }, [dispatch, locationData.latitude, locationData.longitude]);

  return (
    <>
      <Helmet>
        <title>About Us | AI Recruitment Company | Haire</title>
        <meta
          name="description"
          content="Learn about Haire.ai, The leading AI recruitment company offering innovative AI interview and hiring platform solutions."
        />
        <link rel="canonical" href="https://haire.ai/about-us" />
      </Helmet>
      <HomeHeader />
      <section className="conatiner-fluid">
        <ChosePlan />
      </section>
      <section className="conatiner-fluid pd-2">
      <Plans free={country === "India" ? free : stripeFree} business={country === "India" ? business : stripeBusiness} enterprise={country === "India" ? enterprise : stripeEnterprise} country={country}/>
      </section>
     

      {/* <section className="abut_sec_page_new">
        
      </section>

      <section className="hire_ab_sec_new">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="hire_ab_sec_new_inner">
                <p>
                  At <span>Haire,</span> we believe in the power of artificial
                  intelligence to transform the way talent is discovered and
                  opportunities are realized. Our platform is not just a tool;
                  it's a catalyst for change in the traditional hiring approach.
                  Whether you're a recruiter seeking the best talent or a
                  candidate on the journey to your dream job, we have designed a
                  suite of features that redefine recruitment dynamics.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="why_coose_sec_new">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="why_coose_sec_new_head">
                <h6>
                  Why <span>Choose Us?</span>
                </h6>
              </div>
              <div className="ai_trusted_hring_inner_body">
                <div
                  className="ai_trusted_hring_inner_body_single wow fadeInLeftBig"
                  data-wow-duration="1s"
                >
                  <span className="ai_trusted_img">
                    <img src={ai_trust_2} alt="" />
                  </span>
                  <div className="ai_trsut_cont_sec">
                    <h6>Efficient Screening and Shortlisting</h6>
                    <p>
                      AI automates the initial screening and shortlisting of
                      candidates, saving time and picking the best candidates.
                    </p>
                  </div>
                </div>

                <div
                  className="ai_trusted_hring_inner_body_single wow fadeInRightBig"
                  data-wow-duration="1.5s"
                >
                  <span className="ai_trusted_img">
                    <img src={ai_trust_4} alt="" />
                  </span>
                  <div className="ai_trsut_cont_sec">
                    <h6>Transparent Decision-Making</h6>
                    <p>
                      AI is open about how it decides things, so recruiters can
                      trust it and check how decisions are made.
                    </p>
                  </div>
                </div>

                <div
                  className="ai_trusted_hring_inner_body_single wow fadeInLeftBig"
                  data-wow-duration="2s"
                >
                  <span className="ai_trusted_img">
                    <img src={ai_trust_3} alt="" />
                  </span>
                  <div className="ai_trsut_cont_sec">
                    <h6>Enhanced Candidate Matching</h6>
                    <p>
                      AI algorithms match candidates to job requirements more
                      accurately,
                    </p>
                  </div>
                </div>

                <div
                  className="ai_trusted_hring_inner_body_single wow fadeInRightBig"
                  data-wow-duration="2.5s"
                >
                  <span className="ai_trusted_img">
                    <img src={ai_trust_6} alt="" />
                  </span>
                  <div className="ai_trsut_cont_sec">
                    <h6>Feedback and Analysis</h6>
                    <p>
                      Offers detailed feedback, helping recruiters learn and
                      enhance their hiring strategies.
                    </p>
                  </div>
                </div>
              </div>
              <div className="why_btn_main"></div>
              <div className="ai_enhave_btn_sec">{ctaBtn}</div>
            </div>
          </div>
        </div>
      </section>

      <section className="future_hiring_easy new_esay about_easy">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="future_hiring_easy_main">
                <div className="future_hiring_easy_head future_hiring_easy_head1 text-center d-flex">
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <h3>Embrace the future of Recruitment.</h3>
                  </div>
                  <div className="future_hiring_easy_right col-lg-8 col-md-12 col-sm-12 text-start">
                    <div className="future_hiring_easy-section">
                      <p className="text-left">
                        Choose <span>Haire</span> for a smarter, faster, and
                        more efficient recruitment experience. Join us in
                        revolutionizing the way you hire and find your dream
                        job.
                      </p>
                    </div>
                    <button className="explore_btn">Lets Explore</button>
                  </div>
                </div>
                <img src={border_corner} alt="" className="border-corner" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div ref={getInTouchRef} className="getInTouchRef">
        <GetInTouch />
      </div> */}

      <NeedHelp />
      <GetInTouch />
      <HomeFooterNew />
    </>
  );
};

export default Pricing;
