import { Modal } from "antd";
import React from "react";

const PrivacyPolicy = ({ isVisible, onClose }) => {
  return (
    <Modal
      open={isVisible}
      onCancel={onClose}
      footer={null} // Removes the default footer buttons
      width={800}
    >
      <div className="modal-content1">
        <h2>Haire.ai Privacy Policy</h2>
        <p>
        This Privacy Policy describes how Haire.ai ("Haire.ai," "we,"
              "us," or "our") collects, uses, and discloses information about
              you when you use our AIpowered HR services. By accessing or using
              our services, you agree to the terms of this Privacy Policy.
        </p>
        <ol className="">
              <li>
                <h5> Information We Collect</h5>
                <ol  className="type_a">
                  <li>
                    Information You Provide: When you use our services, you may
                    provide us with personal information such as your name,
                    contact information, employment history, and other relevant
                    data, including but not limited to professional
                    qualifications, resume and cover letter, social media
                    profiles, work preferences, performance evaluations,
                    interview feedback, and referrals.
                  </li>
                  <li>
                    Information We Collect Automatically: We may automatically
                    collect certain information about your use of our services,
                    including your IP address, device information, browser type,
                    and usage patterns.
                  </li>
                  <li>
                    Cookies and Similar Technologies: We may use cookies and
                    similar technologies to collect information about your
                    interactions with our services and to improve your user
                    experience. These technologies may store data for varying
                    periods depending on their purpose.
                  </li>
                </ol>
              </li>
              <li>
                <h5>How We Use Your Information</h5>
                <ol  className="type_a">
                  <li>
                    Service Improvement: We use the information we collect to
                    provide, maintain, and improve our services, including
                    personalized recommendations and insights based on AI
                    analysis. The data used for these recommendations includes
                    your employment history, professional qualifications, resume
                    details, job preferences, and interaction history. Our AI
                    algorithms analyze this data to make tailored suggestions,
                    such as job openings matching your skills, potential career
                    paths, and skills or certifications that could enhance your
                    employability.
                  </li>
                  <li>
                    Communication: We may use your information to communicate
                    with you about our services, including updates, promotions,
                    and administrative messages. Service updates are sent as
                    needed, promotional messages are typically sent biweekly or
                    monthly, and administrative messages are sent immediately
                    when necessary.
                  </li>
                  <li>
                    Research and Analytics: We may use your information for
                    research and analytics purposes to understand trends and
                    patterns in HR data and to improve our AI algorithms.
                  </li>
                </ol>
              </li>
              <li>
                <h5>How We Share Your Information</h5>
                <ol className="type_a">
                  <li>
                    Third-Party Service Providers: We may share your information
                    with Third-Party service providers who help us deliver our
                    services, such as cloud hosting providers and analytics
                    platforms. These providers are contractually obligated to
                    protect your information and use it only for the services
                    they provide to us.
                  </li>
                  <li>
                    Business Partners and Affiliates: We may share your
                    information with our business partners, affiliates, or other
                    third parties for purposes such as marketing and
                    advertising. These partners are also required to protect
                    your information and use it only for the agreed upon
                    purposes.
                  </li>
                  <li>
                    Legal Requirements: We may disclose your information if
                    required by law or legal process, or if we believe that such
                    disclosure is necessary to protect our rights, property, or
                    safety, or the rights, property, or safety of others.
                  </li>
                </ol>
              </li>

              <li>
                <h5>Data Security</h5>
                <ol  className="type_a">
                  <li>
                    We take reasonable measures to protect the security of your
                    information and to prevent unauthorized access, disclosure,
                    alteration, or destruction. These measures include using
                    encryption, firewalls, and secure server technology.
                  </li>
                </ol>
              </li>
              <li>
                <h5>Data Retention</h5>
                <ol  className="type_a">
                  <li>
                    We retain your information for as long as necessary to
                    fulfill the purposes outlined in this Privacy Policy, unless
                    a longer retention period is required or permitted by law.
                    When your information is no longer needed, we will securely
                    delete or anonymize it.
                  </li>
                </ol>
              </li>

              <li>
                <h5>Your Choices</h5>
                <ol  className="type_a">
                  <li>
                    OptOut: You may optout of receiving promotional
                    communications from us by following the instructions
                    provided in such communications.
                  </li>
                  <li>
                    Cookie Management: You may disable cookies or adjust your
                    browser settings to limit the collection of certain
                    information. Please note that disabling cookies may affect
                    the functionality of our services.
                  </li>
                </ol>
              </li>
              <li>
                <h5> International Data Transfers</h5>
                <ol  className="type_a">
                  <li>
                    Your information may be transferred to and processed in
                    countries other than your own, where data protection laws
                    may be different from those in your jurisdiction. We comply
                    with relevant data privacy laws, such as the General Data
                    Protection Regulation (GDPR), the California Consumer
                    Privacy Act (CCPA), and the Personal Data Protection Act
                    (PDPA), to ensure that your information receives an adequate
                    level of protection.
                  </li>
                </ol>
              </li>
              <li>
                <h5>Haire.ai Chatbot Interactions</h5>
                <ol className="type_a">
                  <li>
                    The assessment provided by the Haire.ai chatbot is not 100%
                    accurate. Interactions between candidates and the chatbot
                    are monitored to enhance communication and support and are
                    not used for other purposes.
                  </li>
                </ol>
              </li>
              <li>
                <h5>Third-Party Links</h5>
                <ol  className="type_a">
                  <li>
                    Our services may contain links to Third-Party websites. We
                    are not responsible for the privacy practices or content of
                    these Third-Party sites. We encourage you to read the
                    privacy policies of any Third-Party websites you visit.
                  </li>
                </ol>
              </li>
              <li>
                <h5>Consent for Data Processing</h5>
                <ol  className="type_a">
                  <li>
                    By using our services, you consent to the processing of your
                    personal information as described in this Privacy Policy. If
                    you do not agree with any part of this policy, we kindly ask
                    that you refrain from using our services.
                  </li>
                </ol>
              </li>
              <li>
                <h5> Changes to this Privacy Policy</h5>
                <ol  className="type_a">
                  <li>
                    We may update this Privacy Policy from time to time to
                    reflect changes in our practices or applicable law. We will
                    notify you of any material changes by posting the updated
                    Privacy Policy on our website. Your continued use of our
                    services after such updates constitutes your acceptance of
                    the revised policy. It is your responsibility to keep
                    yourself updated with the Privacy Policy.
                  </li>
                </ol>
              </li>
              <li>
                <h5>Contact Us</h5>
                <ol>
                  <li>
                    If you have any questions or concerns about this Privacy
                    Policy or our data practices, please contact us at
                    info@haire.ai.
                  </li>
                </ol>
              </li>
            </ol>
      </div>
    </Modal>
  );
};

export default PrivacyPolicy;
