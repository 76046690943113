import axios from "axios";
import { Dispatch } from "redux";
import { setLocation, setLocationLoading } from "../reducers/locationReducer";

interface AddressComponent {
  types: string[];
  short_name: string;
}

export const fetchUserCountry = () => async (dispatch: Dispatch) => {
  if (navigator.geolocation) {
    dispatch(setLocationLoading(true));

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`
          );
          const addressComponents = response.data.results[0]
            ?.address_components as AddressComponent[];
          const countryComponent = addressComponents?.find(
            (component: AddressComponent) => component.types.includes("country")
          );
          if (countryComponent) {
            const countryCode = countryComponent.short_name.toLowerCase();
            dispatch(setLocation(countryCode));
            dispatch(setLocationLoading(false));
          }
        } catch (error) {
          console.error("Failed to fetch country code:", error);
          dispatch(setLocationLoading(false));
        }
      },
      (error) => {
        console.error("Geolocation error:", error);
        dispatch(setLocationLoading(false));
      }
    );
  }
};
