import React from "react";
import { haire_logo, video_img } from "../../assets/images/images";

const TryHaireInterviewModuleNav = (navData) => {
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const currentTime = currentDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit", // Include seconds if you want them
  });
  return (
    <>
      <section className="inter_view_panel_sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="inter_view_panel_sec_inner">
                <div className="inter_view_panel_sec_inner_left">
                  <img src={video_img} className="vid_img" alt="" />
                  <img src={haire_logo} className="logo_img_sec" alt="" />
                </div>
                <div className="inter_view_panel_sec_inner_right">
                  <h6>
                    {navData?.data?.jobProfile && (
                      <>{navData?.data?.jobProfile} Interview </>
                    )}
                    {navData?.data?.company && <> - {navData?.data?.company}</>}
                  </h6>
                  <p>
                    {formattedDate} - {currentTime}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TryHaireInterviewModuleNav;
