import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { noti_icn } from "../../../assets/images/images";
import "./navbar.css";

const Notification = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Function to close the dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Add event listener to the document body
    document.body.addEventListener("click", handleClickOutside);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const viewAll = () => {
    $(".bell_drop_sec_cont_sec").removeClass("show");
  };

  return (
    <>
      <div className="dropdown bell_drop_sec" ref={dropdownRef}>
        {/* <button className="dropdown-toggle bell_svg" type="button" onClick={toggleDropdown}>
            <img src={bell} alt="Bell" />
            </button> */}
        <div
          className={`dropdown-menu dropdown-menu-right bell_drop_sec_cont_sec ${
            isOpen ? "show" : ""
          }`}
        >
          <div className="bell_drop_sec_cont_sec_inner">
            <h6>Notifications</h6>
            <div className="bell_drop_sec_cont_sec_inner_single active">
              <span>1m ago</span>
              <img src={noti_icn} alt="profile" />
              <div className="sing_cont_sec">
                <h5>
                  <b>Hi Nikhil,</b>
                </h5>
                <h5>
                  Your scheduled interview with AI at <b>Arcitech</b> is set on{" "}
                  <b>January 4, 2024, at 12:00 PM.</b>
                </h5>
                <h5>Please ensure you are prepared. Best of luck!</h5>
              </div>
              <button className="che_dwn">
                <i className="fas fa-chevron-right"></i>
              </button>
            </div>
            <div className="bell_drop_sec_cont_sec_inner_single active">
              <span>1m ago</span>
              <img src={noti_icn} alt="profile" />
              <div className="sing_cont_sec">
                <h5>
                  <b>Hi Nikhil,</b>
                </h5>
                <h5>
                  Your scheduled interview with AI at <b>Arcitech</b> is set on{" "}
                  <b>January 4, 2024, at 12:00 PM.</b>
                </h5>
                <h5>Please ensure you are prepared. Best of luck!</h5>
              </div>
              <button className="che_dwn">
                <i className="fas fa-chevron-right"></i>
              </button>
            </div>
            <div className="bell_drop_sec_cont_sec_inner_single">
              <span>1m ago</span>
              <img src={noti_icn} alt="notification" />
              <div className="sing_cont_sec">
                <h5>
                  <b>Hi Nikhil,</b>
                </h5>
                <h5>
                  Your scheduled interview with AI at <b>Arcitech</b> is set on{" "}
                  <b>January 4, 2024, at 12:00 PM.</b>
                </h5>
                <h5>Please ensure you are prepared. Best of luck!</h5>
              </div>
              <button className="che_dwn">
                <i className="fas fa-chevron-right"></i>
              </button>
            </div>
            <button className="view_all_btn" onClick={viewAll}>
              View All
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
