import { useSelector } from "react-redux";
import { tick } from "../../../../../assets/images/images";
import {
  formatNextRenewalDateISO,
  renderFeatures,
} from "../../../../../utils/utils";
import { useState } from "react";
import CancleSubModal from "../components/CancleSubscriptionModal";
import { usePricingModal } from "../../../../../contexts/PricingModalContext";

const SubscriptionPlan = ({ onCancelPlan, showUpgradeButton }) => {
  const { showPricingModal } = usePricingModal();
  const [showModal, setShowModal] = useState(false);
  const companyDetails = useSelector((state) => state.company?.details);
  const companySub = companyDetails?.company_sub;
  const subscriptionPlan = companySub?.subscription_plan;

  const handleOpenModal = () => {
    setShowModal(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="subscription-card">
      <div class="card-header">
        <div className="br-right">
          <h2>{companySub?.type}</h2>
          <p>
          {subscriptionPlan?.currency}{subscriptionPlan?.price} for {subscriptionPlan?.credits_grant}
            -credit / Month
          </p>
        </div>
        <div>
          <h2>{companyDetails?.company_credits} Credits left</h2>
          {showUpgradeButton ? (
            <p style={{ color: "red" }}>Your plan has been cancelled.</p>
          ) : (
            <p>
              Next Renewal{" "}
              {formatNextRenewalDateISO(companySub?.next_bill_date)}
            </p>
          )}
        </div>
        <div className="cancle_btn_plan">
          {showUpgradeButton ? (
            <button className="cancel-button" onClick={showPricingModal}>
              Upgrade Plan
            </button>
          ) : (
            <button className="cancel-button" onClick={handleOpenModal}>
              Cancel Plan
            </button>
          )}
        </div>
      </div>
      <div className="card-body1">
        <h3>Features :</h3>
        <ul>
          {renderFeatures(companyDetails?.company_sub?.type).map(
            (feature, index) => (
              <li key={index}>
                <img src={tick} alt="" className="tick" /> {feature}
              </li>
            )
          )}
        </ul>
      </div>
      <CancleSubModal show={showModal} onHide={handleCloseModal} />
    </div>
  );
};

export default SubscriptionPlan;
