import { ThreeCircles } from "react-loader-spinner";

const ThreeDotLoader = () => {
  return (
    <ThreeCircles
      visible={true}
      height="20"
      width="20"
      color={"url(#loaderGradient)"}
      ariaLabel="three-circles-loading"
      wrapperStyle={{}}
      wrapperClass=""
    />
  );
};

export default ThreeDotLoader;
