import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { useEffect, useState } from "react";

export default function DateCalendarValue({ onDateChange }) {
  const [value, setValue] = useState(dayjs(new Date()));
  const formattedValue = value.format("ddd D, MMMM YYYY");

  useEffect(() => {
    onDateChange(formattedValue);
  }, [formattedValue, onDateChange]);

  const shouldDisableDate = (date) => {
    return date.isBefore(dayjs(), "day"); // Disable dates before today
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DateCalendar", "DateCalendar"]}>
        <DemoItem>
          <DateCalendar
            value={value}
            onChange={(newValue) => setValue(newValue)}
            shouldDisableDate={shouldDisableDate}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
}
