// store.ts
import { create } from "zustand";
import * as Yup from "yup";
import { auth, signInWithEmailAndPassword } from "../config/firebaseConfig";
// @ts-ignore
import instance from "../api/instance";
import Cookies from "js-cookie";
import {
  setAuthToken,
  setJobseekerId,
  setNewUser,
  setUser,
  setUserId,
} from "../redux/reducers/authReducer";
import { Dispatch } from "redux";
import { AuthUser } from "../redux/types/authTypes";
import { toast } from "react-toastify";
import { getFirebaseLoginError } from "../utils/firebaseErrorFunction";
import { sendVerificationOtp } from "./apis/sendVerificationOtp";
import { verifyEmailOtp } from "./apis/verifyEmailOtp";
import validator from "validator";
// @ts-ignore
import { emailRegex } from "../utils/constants";

interface LoginFormData {
  email: string;
  password: string;
}

interface FormErrors {
  [key: string]: string | undefined;
}

interface StoreState {
  formData: LoginFormData;
  formErrors: FormErrors;
  setFormData: (name: keyof LoginFormData, value: string) => void;
  validateForm: () => Promise<boolean>;
  validateField: (name: keyof LoginFormData, value: string) => void;
  error: string | null;
  loading: boolean;
  modalOpen: boolean;
  loginWithEmailPassword: (
    email: string,
    password: string,
    dispatch: Dispatch,
    navigate: Function,
    onOtpSent: Function,
    redirectUrl?: string
  ) => void;
  verifyUnverifiedAccount: (
    email: string,
    otp: number,
    loginBackAgain: Function
  ) => void;
  openModal: () => void;
  closeModal: () => void;
}

const isEmail = (email: string) => {
  return validator.isEmail(email) && emailRegex.test(email);
};

const schema = Yup.object<LoginFormData>({
  email: Yup.string()
    .required("Email is required")
    .test("is-valid-email", "Invalid email address", (value) =>
      isEmail(value ?? "")
    ),

  password: Yup.string()
    .trim() // Optional, depends on whether you want to ignore leading/trailing spaces.
    .matches(
      /^(?=.*\S).+$/,
      "Password must contain at least one non-whitespace character"
    )
    .min(8, "Password must be at least 8 characters long")
    .required("Password is required"),
});

const loginStore = create<StoreState>((set, get) => ({
  formData: {
    email: "",
    password: "",
  },
  formErrors: {},
  setFormData: (name, value) =>
    set((state) => ({
      formData: { ...state.formData, [name]: value },
    })),
  validateForm: async () => {
    try {
      await schema.validate(get().formData, { abortEarly: false });
      set({ formErrors: {} });
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = err.inner.reduce<FormErrors>(
          (acc, { path, message }) => {
            if (path) {
              acc[path as keyof LoginFormData] = message;
            }
            return acc;
          },
          {}
        );
        set({ formErrors: errors });
      }
      return false;
    }
  },
  validateField: async (name: keyof LoginFormData, value: string) => {
    try {
      await schema.validateAt(name, { ...get().formData, [name]: value });
      set({ formErrors: { ...get().formErrors, [name]: undefined } });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        set({ formErrors: { ...get().formErrors, [name]: error.message } });
      }
    }
  },
  setFormErrors: (errors: any) =>
    set((state) => ({
      formErrors: { ...state.formErrors, ...errors },
    })),
  error: null,
  loading: false,
  modalOpen: false,
  loginWithEmailPassword: async (
    email,
    password,
    dispatch,
    navigate,
    onOtpSent,
    redirectUrl
  ) => {
    set({ loading: true });
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const { user } = userCredential;

      // Optionally, handle different logic if user needs verification
      if (!user.emailVerified) {
        await sendVerificationOtp(email);
        onOtpSent();
        throw new Error("auth/unverified-email");
      }

      const userDetails = {
        user_id: user.uid,
        email: user.email,
        // Add other necessary fields that your social-login endpoint might need
      };

      const loginResponse = await instance.post(
        "/accounts/social-login/",
        userDetails,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (loginResponse.status === 200) {
        Cookies.set("token", loginResponse.data.data.token);
        dispatch(setAuthToken(loginResponse.data.data.token));
        dispatch(setUserId(loginResponse.data.data.user.id));
        dispatch(setNewUser(loginResponse.data.data.is_new_user));
        dispatch(setJobseekerId(loginResponse.data.data.jobseeker_id));

        const user: AuthUser = {
          user_id: loginResponse.data.data.user.id,
          job_seeker_id: loginResponse.data.data.jobseeker_id,
          full_name: loginResponse.data.data.user.full_name,
          email: loginResponse.data.data.user.email,
          type: loginResponse.data.data.user.type,
          country_code: null,
          phone: loginResponse.data.data.user.phone,
          key: loginResponse.data.data.user.key,
          role: loginResponse.data.data.user.role,
          registered_company_name:
            loginResponse.data.data.user.registered_company_name,
          registered_industry_name: loginResponse.data.data.user.registered_industry_name,
          company_id: loginResponse.data.data.company_id,
          languages: null,
          websites: null,
          profile_pic: null,
          designation: null,
          dob: null,
          gender: null,
          address: null,
          introduction: null,
          resume: loginResponse.data.data.user.resume,
          resume_json: null,
          is_active: null,
          is_deleted: null,
          profile_completed: loginResponse.data.data.profile_completed,
        };

        await dispatch(setUser(user));

        // Navigate based on role
        if (user.role === 1) {
          navigate("/dashboard");
        } else if (user.role === 2) {
          navigate(redirectUrl || "/applicant-dashboard");
        } else {
          navigate("/user-role");
        }
      }
    } catch (error: any) {
      set({ error: error.message });
      const errorMessage = getFirebaseLoginError(error.code ?? error.message);
      toast.error(errorMessage);
    } finally {
      set({ loading: false });
    }
  },
  verifyUnverifiedAccount: async (email, otp, loginBackAgain) => {
    set({ loading: true });
    try {
      await verifyEmailOtp(email, otp);
      loginBackAgain();
      return true;
    } catch (error) {
      if (error instanceof Error) {
        set({ error: error.message });
      }
      return false; // Indicate failed verification
    } finally {
      set({ loading: true });
    }
  },
  openModal: () => set({ modalOpen: true }),
  closeModal: () => set({ modalOpen: false, error: null }),
}));

export default loginStore;
