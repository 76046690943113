import React, { useState, useEffect } from "react";
import "../InterviewModule/InterviewModule.css";
import { Link } from "react-router-dom";
import InterviewModuleNav from "./InterviewModuleNav";
import { analyse_img, thick_right_icn } from "../../../../assets/images/images";
// import { Analyse } from './Analyse';
// import { Congratulations } from './Congratulations';

const InterviewAnalyser = () => {
  const [popupCompontStatus, setPopupCompontStatus] = useState(1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPopupCompontStatus((prevStatus) => {
        if (prevStatus < 2) {
          return prevStatus + 1;
        } else {
          clearInterval(intervalId);
          return 2;
        }
      });
    }, 5000);
    return () => clearInterval(intervalId);
  }, [popupCompontStatus]);

  return (
    <>
      <InterviewModuleNav />
      {popupCompontStatus === 1 && <Analyse />}
      {popupCompontStatus === 2 && <Congratulations />}
    </>
  );
};

export default InterviewAnalyser;

export const Analyse = () => {
  return (
    <section className="analsyse_sec_new">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-8 mx-auto">
            <div className="analsyse_sec_new_inner">
              <h6>Analysing candidate's performance.</h6>
              <img src={analyse_img} alt="" />
              <ul>
                <li>
                  Analyzing the results from the screening round
                  comprehensively.
                </li>
                <li>
                  Analyzing the results from the technical round
                  comprehensively.
                </li>
                <li>Analyzing of your overall performance.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const Congratulations = () => {
  return (
    <section className="cong_sec_new">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="cong_sec_new_inner">
              <div className="cong_sec_new_inner_head">
                <div className="cong_sec_new_inner_head_inner">
                  <img src={thick_right_icn} alt="" />
                  <h6>Congratulations! </h6>
                  <p>
                    You have been shortlisted for the next round of interviews.
                  </p>
                </div>
              </div>
              <h4>
                Please check your email and phone regularly. Company HR team
                will contact you soon with more updates, either through email or
                phone.
              </h4>
              <div className="bavk_jobs">
                <Link>Back to Jobs</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
