import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompanyBillingInfo, CompanyDetails } from "../types/companyTypes";

export interface BillingState {
  details: CompanyBillingInfo | null;
  loading: boolean;
  error: string | null;
}

const initialState: BillingState = {
  details: null,
  loading: false,
  error: null,
};

const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    setBillingInfo: (
      state: BillingState,
      action: PayloadAction<CompanyBillingInfo>
    ) => {
      state.details = action.payload as CompanyBillingInfo;
    },
    setBillingLoading: (
      state: BillingState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
    setBillingError: (state: BillingState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    resetBilling: (state: BillingState) => {
      state.details = initialState.details;
    },
  },
});

export const {
  setBillingInfo,
  setBillingLoading,
  setBillingError,
  resetBilling,
} = billingSlice.actions;
export default billingSlice.reducer;
