import React, { useEffect, useState } from "react";
import "../../pages/Dashboard/RecruiterDashboard/MyCompany/MyCompany.css";

const ImageGallery = ({ images }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [imageArray, setImageArray] = useState([]);

  useEffect(() => {
    if (typeof images === "string") {
      // Ensure that the string is not empty after trimming and splitting
      const trimmedImages = images.split(",").map((image) => image.trim()).filter(image => image !== "");
      setImageArray(trimmedImages);
    } else {
      setImageArray(images || []);
    }
  }, [images]);

  const visibleImages = isExpanded ? imageArray : imageArray?.slice(0, 5);
  const toggleImages = () => setIsExpanded(!isExpanded);
  if (imageArray?.length > 0) {
  return (
    <div className="comp_gallery_sec">
      <h6>Company Gallery</h6>
      <div className="comp_gallery_sec_body">
        {visibleImages?.map((image, index) => (
          <div key={index} className="comp_gallery_sec_body_single">
            <img src={image} alt={`Gallery ${index}`} />
          </div>
        ))}
        {!isExpanded && imageArray?.length > 5 && (
          <div className="photos_main_text" onClick={() => setIsExpanded(true)}>
            <h5>+{imageArray.length - 5} Photos</h5>
          </div>
        )}
      </div>
      {imageArray?.length > 5 && (
        <>
          <div className="see_phot_btn">
            <button onClick={toggleImages} style={{ paddingBottom: "20px" }}>
              {isExpanded ? "See Less Photos" : "See All Photos"}
            </button>
          </div>
        </>
      )}
    </div>
  );
} else {
  return <div className="comp_gallery_sec_no_data"></div>;
}
};

export default ImageGallery;
