import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../SignIn/signin.css";
import { useDispatch } from "react-redux";
import { updateUserRole } from "../../redux/actions/otpVerificationActions";
import {
  rect_img_1,
  rect_img_2,
  recruit_sign_in,
} from "../../assets/images/images";
const RecruitingJobSeeking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleRecruiterClick = async () => {
    await dispatch(updateUserRole(1));
    navigate("/dashboard", { replace: true });
  };

  const handleJobSeekerClick = async () => {
    await dispatch(updateUserRole(2));
    navigate("/resume-builder", { replace: true });
  };
  return (
    <>
      <section className="singin_sec_new">
        <div className="singin_sec_new_left">
          <img src={recruit_sign_in} alt="" />
        </div>
        <div className="job_seeking_rect_sec">
          <div className="job_seeking_rect_sec_head">
            <h6>Step into Your Role</h6>
            <h5>Are You Recruiting or Job Seeking?</h5>
          </div>
          <div className="job_seeking_rect_sec_body_sec">
            <div onClick={handleRecruiterClick}>
              <div className="job_seeking_rect_single">
                <span className="img_span">
                  <img src={rect_img_1} alt="" />
                </span>
                <h6>I am a Recruiter</h6>
              </div>
            </div>
            <div onClick={handleJobSeekerClick}>
              <div className="job_seeking_rect_single">
                <span className="img_span">
                  <img src={rect_img_2} alt="" />
                </span>
                <h6>I am a Job Seeker</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RecruitingJobSeeking;
