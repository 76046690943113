import { useState } from "react";
import axios from "axios";
import instance from "../api/instance";

const useFileUpload = () => {
  const [uploadStatus, setUploadStatus] = useState({
    loading: false,
    error: null,
  });

  const uploadFile = async (file, token) => {
    try {
      setUploadStatus({ loading: true, error: null });

      // Fetch the pre-signed URL from your backend
      const response = await instance.post(
        "/ai/generate-presigned-url/",
        {
          file_name: file.name,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const { presigned_url, file_url } = response.data;

      // Upload the file to S3 using the pre-signed URL
      const uploadResponse = await axios.put(presigned_url, file, {
        headers: {
          "Content-Type": file.type,
        },
        // Axios will only resolve with status in the range 2xx
        validateStatus: (status) => status === 200,
      });

      if (uploadResponse.status === 200) {
        const headResponse = await axios.head(file_url);
        if (headResponse.status === 200) {
          setUploadStatus({ loading: false, error: null });
          return file_url;
        } else {
          throw new Error("File upload verification failed");
        }
      } else {
        throw new Error("Failed to upload file");
      }
    } catch (error) {
      setUploadStatus({ loading: false, error: error.message });
      return null;
    }
  };

  return { uploadStatus, uploadFile };
};

export default useFileUpload;
