import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CreditHistoryItem, CreditHistoryState } from "../types/creditsTypes";

const initialState: CreditHistoryState = {
  history: [],
  loading: false,
  error: null,
};

const creditsSlice = createSlice({
  name: "credits",
  initialState,
  reducers: {
    // Define a reducer and automatically generate its action
    setCreditHistory(
      state: CreditHistoryState,
      action: PayloadAction<CreditHistoryItem[]>
    ) {
      state.history = action.payload;
    },
    setCreditHistoryLoading: (
      state: CreditHistoryState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
    setCreditHistoryError: (
      state: CreditHistoryState,
      action: PayloadAction<string>
    ) => {
      state.error = action.payload;
    },
  },
});

// Export the automatically generated action creators
export const {
  setCreditHistory,
  setCreditHistoryLoading,
  setCreditHistoryError,
} = creditsSlice.actions;

// Export the reducer
export default creditsSlice.reducer;
