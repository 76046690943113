import React from 'react';
import { ThreeCircles } from "react-loader-spinner";

const LoaderApply = ({ color = "#FFFFFF" }) => {  
  return (
    <ThreeCircles
      visible={true}
      height="16"
      width="20"
      color={color}  
      ariaLabel="loading-indicator"
    />
  );
};

export default LoaderApply;
