import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  ab_left,
  ai_trust_2,
  ai_trust_3,
  ai_trust_4,
  ai_trust_6,
  border_corner,
  ab_miss_img,
  main_gi,
} from "../../assets/images/images";
import GetInTouch from "../../components/HomeComponents/GetInTouch";
import HomeFooter from "../../components/HomeComponents/HomeFooter";
import HomeHeader from "../../components/HomeComponents/HomeHeader";
import "../AboutUs/Aboutus.css";
import { Helmet } from "react-helmet";
import HomeFooterNew from "../../components/HomeComponents/Footer";

import { WOW } from "wowjs";
import "animate.css/animate.min.css";

const Aboutus = () => {
  const navigate = useNavigate();
  const getInTouchRef = useRef(null);
  const handleClick = (event) => {
    event.preventDefault();

    localStorage.setItem("userType", "recruiter");

    navigate("/sign-up");
  };

  const ctaBtn = (
    <Link className="get_start_btn" onClick={handleClick}>
      {/* <img src="../../images/btn-bg.svg" className="btn_bg_img" />  */}
      Get Started
    </Link>
  );
  const handleGetInTouchScroll = () => {
    if (getInTouchRef.current) {
      getInTouchRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    new WOW().init();
  }, []);

  return (
    <>
      <Helmet>
        <title>About Us | AI Recruitment Company | Haire</title>
        <meta
          name="description"
          content="Learn about Haire.ai, The leading AI recruitment company offering innovative AI interview and hiring platform solutions."
        />
        <link rel="canonical" href="https://haire.ai/about-us" />
      </Helmet>
      <HomeHeader />

      <section className="abut_sec_page_new" style={{ display: "none" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="abut_sec_page_new_head">
                <h6>
                  About Haire: Revolutionizing <br />
                  <span>Recruitment with AI</span>
                </h6>
              </div>
              <div className="abut_sec_page_new_body_sec">
                <div className="abut_sec_page_new_body_sec_left">
                  <img src={ab_left} alt="" title="" />
                </div>
                <div className="abut_sec_page_new_body_sec_right">
                  <h4>
                    <span>Welcome to Haire,</span> where innovation meets
                    talent. We're dedicated to seamlessly connecting companies
                    and candidates for the perfect match. Haire goes beyond a
                    typical AI recruitment website—it's a game-changer in the
                    hiring landscape.
                  </h4>
                  <h3>Our Mission</h3>
                  <p>
                    At the core of Haire is a mission to redefine the
                    recruitment experience. We are passionate about connecting
                    businesses with exceptional talent, and we're doing it
                    differently. By harnessing the power of artificial
                    intelligence, we've transformed the traditional interview
                    process, making it more insightful, fair, and effective.
                  </p>
                  <Link onClick={handleGetInTouchScroll}>Get in touch</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hire_ab_sec_new" style={{ display: "none" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="hire_ab_sec_new_inner">
                <p>
                  At <span>Haire,</span> we believe in the power of artificial
                  intelligence to transform the way talent is discovered and
                  opportunities are realized. Our platform is not just a tool;
                  it's a catalyst for change in the traditional hiring approach.
                  Whether you're a recruiter seeking the best talent or a
                  candidate on the journey to your dream job, we have designed a
                  suite of features that redefine recruitment dynamics.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="why_coose_sec_new" style={{ display: "none" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="why_coose_sec_new_head">
                <h6>
                  Why <span>Choose Us?</span>
                </h6>
              </div>
              <div className="ai_trusted_hring_inner_body">
                <div
                  className="ai_trusted_hring_inner_body_single wow fadeInLeftBig"
                  data-wow-duration="1s"
                >
                  <span className="ai_trusted_img">
                    <img src={ai_trust_2} alt="" />
                  </span>
                  <div className="ai_trsut_cont_sec">
                    <h6>Efficient Screening and Shortlisting</h6>
                    <p>
                      AI automates the initial screening and shortlisting of
                      candidates, saving time and picking the best candidates.
                    </p>
                  </div>
                </div>

                <div
                  className="ai_trusted_hring_inner_body_single wow fadeInRightBig"
                  data-wow-duration="1.5s"
                >
                  <span className="ai_trusted_img">
                    <img src={ai_trust_4} alt="" />
                  </span>
                  <div className="ai_trsut_cont_sec">
                    <h6>Transparent Decision-Making</h6>
                    <p>
                      AI is open about how it decides things, so recruiters can
                      trust it and check how decisions are made.
                    </p>
                  </div>
                </div>

                <div
                  className="ai_trusted_hring_inner_body_single wow fadeInLeftBig"
                  data-wow-duration="2s"
                >
                  <span className="ai_trusted_img">
                    <img src={ai_trust_3} alt="" />
                  </span>
                  <div className="ai_trsut_cont_sec">
                    <h6>Enhanced Candidate Matching</h6>
                    <p>
                      AI algorithms match candidates to job requirements more
                      accurately,
                    </p>
                  </div>
                </div>

                <div
                  className="ai_trusted_hring_inner_body_single wow fadeInRightBig"
                  data-wow-duration="2.5s"
                >
                  <span className="ai_trusted_img">
                    <img src={ai_trust_6} alt="" />
                  </span>
                  <div className="ai_trsut_cont_sec">
                    <h6>Feedback and Analysis</h6>
                    <p>
                      Offers detailed feedback, helping recruiters learn and
                      enhance their hiring strategies.
                    </p>
                  </div>
                </div>
              </div>
              <div className="why_btn_main"></div>
              <div className="ai_enhave_btn_sec">{ctaBtn}</div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="future_hiring_easy new_esay about_easy"
        style={{ display: "none" }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="future_hiring_easy_main">
                <div className="future_hiring_easy_head future_hiring_easy_head1 text-center d-flex">
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <h3>Embrace the future of Recruitment.</h3>
                  </div>
                  <div className="future_hiring_easy_right col-lg-8 col-md-12 col-sm-12 text-start">
                    <div className="future_hiring_easy-section">
                      <p className="text-left">
                        Choose <span>Haire</span> for a smarter, faster, and
                        more efficient recruitment experience. Join us in
                        revolutionizing the way you hire and find your dream
                        job.
                      </p>
                    </div>
                    <button className="explore_btn">Lets Explore</button>
                  </div>
                </div>
                <img src={border_corner} alt="" className="border-corner" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* New Sections */}

      <section className="about_us_header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="about_us_header_inner">
                <h4 className="wow animate__bounceIn" data-wow-duration="1.3s">
                  Shaping the Future
                  <br />
                  of Recruitment with AI
                </h4>
                <p className="wow animate__bounceIn" data-wow-duration="1.8s">
                  Welcome to Haire, where innovation intersects with talent. We
                  are committed to seamlessly connecting organizations with
                  exceptional candidates, ensuring an ideal fit. Haire
                  transcends the conventional AI recruitment platform setting
                  new standards in the hiring industry.
                </p>
                <div
                  className="scroll-down wow animate__bounceIn"
                  data-wow-duration="2s"
                >
                  <span class="scroll-dot"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our_mission_sec_new">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="our_mission_sec_new_inner">
                <div className="our_mission_sec_new_inner_left">
                  <h3
                    className="wow animate__lightSpeedInLeft"
                    data-wow-duration="2.5s"
                  >
                    Our Mission
                  </h3>
                  <p
                    className="wow animate__lightSpeedInLeft"
                    data-wow-duration="2.7s"
                  >
                    Haire.ai is a commitment to revolutionizing the recruitment
                    experience. Our mission is to connect businesses with
                    exceptional talent through a unique approach. Utilizing the
                    power of artificial intelligence, we have redefined the
                    traditional interview process, making it more insightful,
                    equitable, and effective.
                  </p>

                  <p>
                    At Haire, we harness the transformative power of artificial
                    intelligence to innovate talent acquisition and uncover
                    opportunities. Our platform serves as a catalyst for change
                    in the conventional hiring methodology. Whether you are a
                    recruiter in search of top-tier talent or a candidate
                    pursuing your ideal career, our suite of innovative features
                    is designed to redefine the recruitment landscape.
                  </p>
                  <Link to={"/sign-up"} onClick={handleClick}>
                    Get Started
                  </Link>
                </div>
                <div
                  className="our_mission_sec_new_inner_right wow animate__lightSpeedInRight"
                  data-wow-duration="2.1s"
                >
                  <img src={ab_miss_img} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="industries_serve">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="industries_serve_inner">
                <div className="industries_serve_inner_head">
                  <h4 className="wow animate__zoomIn" data-wow-duration="1.5s">
                    Industries We Serve
                  </h4>
                </div>
                <div className="industries_serve_inner_body">
                  <div className="industries_serve_inner_body_single">
                    <span
                      className="wow animate__zoomIn"
                      data-wow-duration="1.5s"
                    >
                      1
                    </span>
                    <h5
                      className="wow animate__slideInUp"
                      data-wow-duration="1.6s"
                    >
                      Technology
                    </h5>
                    <p
                      className="wow animate__fadeInRight"
                      data-wow-duration="1.7s"
                    >
                      In the fast-paced world of technology, Haire.ai helps
                      companies find skilled developers, engineers, and IT
                      professionals. Our AI-driven recruitment process ensures a
                      perfect match between job seekers and employers, fostering
                      innovation and growth.
                    </p>
                  </div>
                  <div className="industries_serve_inner_body_single">
                    <span
                      className="wow animate__zoomIn"
                      data-wow-duration="1.6s"
                    >
                      2
                    </span>
                    <h5
                      className="wow animate__slideInUp"
                      data-wow-duration="1.7s"
                    >
                      Banking and Finance
                    </h5>
                    <p
                      className="wow animate__fadeInRight"
                      data-wow-duration="1.8s"
                    >
                      For the banking and finance sector, Haire.ai offers a
                      streamlined hiring process to find qualified candidates
                      with expertise in financial analysis, accounting, and
                      investment banking. Our platform enhances efficiency and
                      accuracy in candidate selection.
                    </p>
                  </div>
                  <div className="industries_serve_inner_body_single">
                    <span
                      className="wow animate__zoomIn"
                      data-wow-duration="1.8s"
                    >
                      3
                    </span>
                    <h5
                      className="wow animate__slideInUp"
                      data-wow-duration="1.8s"
                    >
                      NBFC (Non-Banking Financial Companies)
                    </h5>
                    <p
                      className="wow animate__fadeInRight"
                      data-wow-duration="1.9s"
                    >
                      Haire.ai supports NBFCs by identifying top talent for
                      roles in credit analysis, risk management, and financial
                      planning. Our AI tools simplify the recruitment process,
                      ensuring you hire the best candidates for your needs.
                    </p>
                  </div>
                  <div className="industries_serve_inner_body_single">
                    <span
                      className="wow animate__zoomIn"
                      data-wow-duration="1.10s"
                    >
                      4
                    </span>
                    <h5
                      className="wow animate__slideInUp"
                      data-wow-duration="1.9s"
                    >
                      Hospitality
                    </h5>
                    <p
                      className="wow animate__fadeInRight"
                      data-wow-duration="2.0s"
                    >
                      In the hospitality industry, finding the right staff is
                      crucial. Haire.ai connects you with experienced
                      professionals in hotel management, culinary arts, and
                      customer service, ensuring exceptional guest experiences.
                    </p>
                  </div>
                  <div className="industries_serve_inner_body_single">
                    <span
                      className="wow animate__zoomIn"
                      data-wow-duration="1.12s"
                    >
                      5
                    </span>
                    <h5
                      className="wow animate__slideInUp"
                      data-wow-duration="2s"
                    >
                      Fintech
                    </h5>
                    <p
                      className="wow animate__fadeInRight"
                      data-wow-duration="2.1s"
                    >
                      The fintech industry thrives on innovation and expertise.
                      Haire.ai’s AI-driven platform helps you find candidates
                      with the right mix of technical and financial skills to
                      drive your business forward.
                    </p>
                  </div>
                  <div className="industries_serve_inner_body_single">
                    <span
                      className="wow animate__zoomIn"
                      data-wow-duration="2s"
                    >
                      6
                    </span>
                    <h5
                      className="wow animate__slideInUp"
                      data-wow-duration="2.1s"
                    >
                      eCommerce
                    </h5>
                    <p
                      className="wow animate__fadeInRight"
                      data-wow-duration="2.2s"
                    >
                      For eCommerce companies, Haire.ai streamlines the hiring
                      of experts in digital marketing, supply chain management,
                      and customer service. Our platform ensures you find the
                      right talent to scale your business.
                    </p>
                  </div>
                  <div className="industries_serve_inner_body_single">
                    <span
                      className="wow animate__zoomIn"
                      data-wow-duration="2.1s"
                    >
                      7
                    </span>
                    <h5
                      className="wow animate__slideInUp"
                      data-wow-duration="2.2s"
                    >
                      Warehousing
                    </h5>
                    <p
                      className="wow animate__fadeInRight"
                      data-wow-duration="2.3s"
                    >
                      Efficient warehousing is vital for supply chain
                      management. Haire.ai helps you hire skilled professionals
                      in logistics, inventory management, and distribution,
                      optimizing your operations.
                    </p>
                  </div>
                  <div className="industries_serve_inner_body_single">
                    <span
                      className="wow animate__zoomIn"
                      data-wow-duration="2.2s"
                    >
                      8
                    </span>
                    <h5
                      className="wow animate__slideInUp"
                      data-wow-duration="2.3s"
                    >
                      Staffing
                    </h5>
                    <p
                      className="wow animate__fadeInRight"
                      data-wow-duration="2.4s"
                    >
                      As a staffing agency, your success depends on finding the
                      best candidates for your clients. Haire.ai enhances your
                      recruitment process, offering AI-driven solutions to
                      identify and place top talent quickly.
                    </p>
                  </div>
                  <div className="industries_serve_inner_body_single">
                    <span
                      className="wow animate__zoomIn"
                      data-wow-duration="2.3s"
                    >
                      9
                    </span>
                    <h5
                      className="wow animate__slideInUp"
                      data-wow-duration="2.5s"
                    >
                      Healthcare
                    </h5>
                    <p
                      className="wow animate__fadeInRight"
                      data-wow-duration="2.6s"
                    >
                      In the healthcare industry, hiring the right professionals
                      is critical. Haire.ai connects you with qualified doctors,
                      nurses, and administrative staff, ensuring the highest
                      standards of patient care.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div ref={getInTouchRef} className="getInTouchRef">
        <GetInTouch />
      </div>

      <HomeFooterNew />
    </>
  );
};

export default Aboutus;
