import { Form, Input } from "antd";
import { Modal } from "react-bootstrap";

const BillingModal = ({show,onHide}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      className="report_modal_sec"
    >
      <div className="report_modal_sec_inner">
        <div className="report_modal_sec_inner_head">
          <button onClick={onHide}>
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div className="report_modal_sec_inner_body Add_card_modal">
          <h3>Billing Info</h3>
          <Form name="billing-form" className="job_details_from billing_form">
            <div className="row">
              <Form.Item
                name="Full Name "
                label={
                  <span>
                    Full Name<span className="required-asterisk">*</span>
                  </span>
                }
                colon={false}
              >
                <Input placeholder="Enter your name" />
              </Form.Item>
              <Form.Item
                name="Company Name"
                label={
                  <span>
                    Company Name<span className="required-asterisk">*</span>
                  </span>
                }
                colon={false}
              >
                {" "}
                <Input placeholder="Enter Company Name" />
              </Form.Item>
              <Form.Item
                name="Tax ID / GST (Optional)"
                label={
                  <span>
                    Tax ID / GST (Optional)
                  </span>
                }
                colon={false}
              >
                <Input placeholder="27A123fgV7364" />
              </Form.Item>
              <Form.Item
                name="Company Address"
                label={
                  <span>
                    Company Address
                    <span className="required-asterisk">*</span>
                  </span>
                }
                colon={false}
              >
                {" "}
                <Input placeholder="Enter Company Address" />
              </Form.Item>
              <Form.Item
                name="Country or region"
                label={
                  <span>
                    Country or region
                    <span className="required-asterisk">*</span>
                  </span>
                }
                colon={false}
              >
                <Input placeholder="Enter Country or region" />
              </Form.Item>
              <Form.Item
                name="City"
                label={
                  <span>
                    City<span className="required-asterisk">*</span>
                  </span>
                }
                colon={false}
              >
                {" "}
                <Input placeholder="Enter City Name" />
              </Form.Item>
              <Form.Item
                name="Pin "
                label={
                  <span>
                    Pin<span className="required-asterisk">*</span>
                  </span>
                }
                colon={false}
              >
                <Input placeholder="Enter Pin" />
              </Form.Item>
              <Form.Item
                name="State"
                label={
                  <span>
                    State<span className="required-asterisk">*</span>
                  </span>
                }
                colon={false}
              >
                {" "}
                <Input placeholder="Enter State Name" />
              </Form.Item>
            </div>
          </Form>
          <button className="Add_card_btn">Save & Update</button>
        </div>
      </div>
    </Modal>
  );
};

export default BillingModal;
