import { useEffect } from "react";
import { Link } from "react-router-dom";

import { close_job } from "../../../../assets/images/images";

export const Malpractice = () => {
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = ""; // Chrome requires returnValue to be set
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  return (
    <>
      <section className="cong_sec_new">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="cong_sec_new_inner">
                <div className="cong_sec_new_inner_head">
                  <div className="cong_sec_new_inner_head_inner">
                    <img src={close_job} alt="Close Job Warning" />
                    <br />
                    <h6>Interview Concluded</h6>
                    <p>
                      Unfortunately, the interview has been automatically ended
                      due to repeated distractions. We hope you
                      <br />
                      understand the need for maintaining focus during the
                      process, Thank you for your participation.
                    </p>
                  </div>
                </div>
                <div className="bavk_jobs">
                  <Link to={"/applicant-dashboard"}>Back to Jobs</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
