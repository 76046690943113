import React from 'react'
import './commonComponant.css'
import {applicantnotfound} from '../../assets/images/images'

const NoJobApplicantFound = () => {
  return (
    <div>
        <div className='nojobapplicantfound_container'>
            <div className='nopage_found'>
                <img src= {applicantnotfound} alt=''/>
                <p>No job applications found. Browse and apply for opportunities.</p>
            </div>
        </div>
    </div>
  )
}

export default NoJobApplicantFound