import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation, matchPath } from "react-router-dom";

function PrivateRoute({ element, excludedRoutes = [] }) {
  const authToken = useSelector((state) => state.auth.token);
  const role = useSelector((state) => state.auth.user?.role);
  const job_seeker_id = useSelector(
    (state) => state.auth.user?.job_seeker_id ?? state.auth?.jobseeker_id
  );
  const location = useLocation();
  const navigate = useNavigate();

  const [redirected, setRedirected] = useState(false);

  const isExcludedRoute = excludedRoutes.some((excludedRoute) =>
    matchPath(excludedRoute, location.pathname)
  );

  useEffect(() => {
    if (!redirected && !isExcludedRoute) {
      if (!authToken) {
        navigate("/login", { state: { from: location }, replace: true });
        setRedirected(true);
      } else if (role === 2 && !job_seeker_id) {
        navigate("/resume-builder", {
          state: { from: location },
          replace: true,
        });
        setRedirected(true);
      }
    }
  }, [authToken, role, job_seeker_id, navigate, location, redirected]);

  // Render the element only if conditions are met
  if (isExcludedRoute || (authToken && (role !== 2 || job_seeker_id))) {
    return element;
  }

  // Prevent any output until navigation is decided
  return null;
}

export default PrivateRoute;
