import { useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as UnderReviewIcon } from "../../../../assets/images/under-review.svg";

export const UnderReview = () => {
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = ""; // Chrome requires returnValue to be set
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  return (
    <>
      <section className="cong_sec_new">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="cong_sec_new_inner">
                <div className="cong_sec_new_inner_head">
                  <div className="cong_sec_new_inner_head_inner">
                    <UnderReviewIcon />
                    <br />
                    <h6>Assessment Under Review</h6>
                    <p>
                      Your assessment is currently under review to ensure
                      accuracy. We will provide updates within half an hour.
                      <br />
                      Thank you for your patience. Updates will be sent to your
                      email.
                    </p>
                  </div>
                </div>
                <div className="bavk_jobs">
                  <Link to={"/applicant-dashboard"}>Back to Jobs</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
